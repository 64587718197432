export const GET_PATIENTS = "GET_PATIENTS";
export const GET_PATIENT = "GET_PATIENT";
export const REFRESH_PATIENT = "REFRESH_PATIENT";
export const DELETE_APPOINTMENT = "DELETE_APPOINTMENT";
export const DELETE_PATIENT = "DELETE_PATIENT";
export const SELECT_PATIENT = "SELECT_PATIENT";
export const ADD_PATIENT_BY_NURSE = "ADD_PATIENT_BY_NURSE";
export const UPDATE_VITALS = "UPDATE_VITALS";

export const DEACTIVATE_ACCOUNT = "DEACTIVATE_ACCOUNT";

export const SET_PATIENT_PAGE_NUMBER = "SET_PATIENT_PAGE_NUMBER";
export const CLEAR_SEARCH_RESULTS = "CLEAR_SEARCH_RESULTS";
export const ADD_FAMILY_MEMBER = "ADD_FAMILY_MEMBER";
export const DELETE_FAMILY_MEMBER = "DELETE_FAMILY_MEMBER";
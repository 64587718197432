import React from 'react';
import moment from 'moment';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';

const DateFilter = ({ date, onDateChange }) => {

  const handlePrevDay = () => {
    const prevDay = moment(date).subtract(1, 'days').format('YYYY-MM-DD');
    onDateChange(prevDay);
  };

  const handleNextDay = () => {
    const nextDay = moment(date).add(1, 'days').format('YYYY-MM-DD');
    onDateChange(nextDay);
  };

  const handleDateChange = (e) => {
    onDateChange(e.target.value);
  };

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <button className='date-filter-btn' onClick={handlePrevDay}>
        <FaChevronLeft />
      </button>
      <input 
        type='date' 
        value={date} 
        onChange={handleDateChange} 
        className="form-control" 
        style={{ width: '10rem', textAlign: 'center' }} 
      />
      <button className='date-filter-btn' onClick={handleNextDay}>
        <FaChevronRight />
      </button>
    </div>
  );
};

export default DateFilter;

// Package Types
export const BUY_SOME_GET_SOME = "BUY_SOME_GET_SOME"
export const ON_PERCENTAGE = "ON_PERCENTAGE"


// Package Category
export const RESTAURANT = "RESTAURANT"
export const WELLNESS = "WELLNESS"
export const HOTEL = "HOTEL"
export const RETAIL = "RETAIL"

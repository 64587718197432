import { ADD_HOSPITAL_SERVICE, DELETE_HOSPITAL_SERVICE, GET_HOSPITAL_SERVICES, UPDATE_HOSPITAL_SERVICE } from "../types/hospitalServiceTypes";

const initialState = {
    hospitalServices: []
};

export const hospitalServicesReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_HOSPITAL_SERVICES:
            return {
                ...state,
                hospitalServices: action.payload,
            };
        case DELETE_HOSPITAL_SERVICE:
            return {
                ...state,
                hospitalServices: state.hospitalServices.filter(service => service._id !== action.payload)
            };
        case UPDATE_HOSPITAL_SERVICE:
            return {
                ...state,
                hospitalServices: state.hospitalServices.map(
                    (service) => service._id === action.payload.id ? { ...service, ...action.payload.data } : service
                )
            };
        case ADD_HOSPITAL_SERVICE:
            return {
                ...state,
                hospitalServices: [...state.hospitalServices, action.payload]
            };
        default:
            return state;
    }
};

import React, { createContext, useContext, useState } from 'react';

const ConfirmationDialogContext = createContext();

export const useConfirmationDialog = () => {
    const context = useContext(ConfirmationDialogContext);
    if (!context) {
        throw new Error('useConfirmationDialog must be used within a ConfirmationDialogProvider');
    }
    return context;
};

export const ConfirmationDialogProvider = ({ children }) => {
    const [isVisible, setIsVisible] = useState(false);
    const [message, setMessage] = useState('');
    const [onConfirm, setOnConfirm] = useState(() => () => {});
    const [onCancel, setOnCancel] = useState(() => () => {});

    const showConfirmation = (message, onConfirm, onCancel) => {
        setMessage(message);
        setOnConfirm(() => () => {
            onConfirm();
            setIsVisible(false);
        });
        setOnCancel(() => () => {
            if (onCancel) onCancel();
            setIsVisible(false);
        });
        setIsVisible(true);
    };

    return (
        <ConfirmationDialogContext.Provider value={{ showConfirmation }}>
            {children}
            {isVisible && (
                <div className="confirmation-dialog">
                    <div className="confirmation-dialog-content">
                        <p>{message}</p>
                        <button className='btn btn-danger' onClick={onConfirm}>Confirm</button>
                        <button className='btn' onClick={onCancel}>Cancel</button>
                    </div>
                </div>
            )}
        </ConfirmationDialogContext.Provider>
    );
};

import React, { useContext, useEffect, useState } from 'react'
import { href } from '../../../constants/extra'
import DashboardLayout from '../../../layout/DashboardLayout'
import PLACEHOLDER_DOCTOR_IMAGE from '../../../assets/images/doctor_placeholder.png'
import { useHistory } from 'react-router-dom'
import { RECEPTIONIST_DOCTOR_INFO_ROUTE } from '../../../constants/Redirects'
import { getDoctors, deleteDoctor, setPageNumber, searchDoctor } from '../../../store/actions/doctorActions'
import { connect } from 'react-redux'
import { getPagesArray } from '../../../Utills/functions'
import classNames from 'classnames'
import { FadeLoading } from '../../../loaders'
import { useConfirmationDialog } from '../../../contextApi/ConfirmationDialogContext'
import { RootContext } from "../../../contextApi/index";

function Doctors({ getDoctors, doctors, deleteDoctor, setPageNumber, searchDoctor }) {
    const history = useHistory()
    const { pageNumber, numberOfPages, doctors: allDoctors, searchedDoctors, searchedText } = doctors && doctors
    const [loading, setLoading] = useState(true);
	const { showConfirmation } = useConfirmationDialog()
    const { user } = useContext(RootContext)

    useEffect(() => {
        getDoctorList();
    }, [getDoctors, pageNumber, searchDoctor, searchedText])

    const getDoctorList = async () => {
        try {
            setLoading(true);
            if (searchedText !== "") {
                await searchDoctor(pageNumber, searchedText);
                setLoading(false);
            } else {
                await getDoctors(pageNumber || 0, "HandledOnBackend")
                setLoading(false);
            }
        } catch (err) {

        }
    }

    const onDoctorSelect = (id) => {
        history.push(RECEPTIONIST_DOCTOR_INFO_ROUTE + `/${id}`)
    }

    const pages = getPagesArray(numberOfPages)

    const doctorsList = searchedDoctors.length > 0 ? searchedDoctors : allDoctors

    return (
        <div>
            <DashboardLayout>
                <div className="row align-items-center add-list">
                <div className="col-6">
                    <h4>Doctors List</h4>
                </div>
                </div>
                <div className="row list-block">
                    {loading && doctorsList?.length === 0 && (
                        <div style={{ width: '100%', marginTop: '10%' }}>
                            <FadeLoading />
                        </div>
                    )}
                    {doctorsList?.map(doctor => (
                        <div className="col-sm-6 col-md-4 col-lg-4 col-xl-2 custom-col">
                            <div className="card">
                                <div className="card-body">
                                    <div className="media">
                                        <img className="pointer" src={doctor?.image ? doctor?.image : PLACEHOLDER_DOCTOR_IMAGE} onClick={() => { onDoctorSelect(doctor._id) }} alt="doctor" />
                                        <div className="media-body">
                                            <h5 className="mt-0">Dr. {doctor.firstName + " " + doctor.lastName}</h5>
                                            <p>{doctor.specialityId?.map((item, index) => index === doctor.specialityId.length - 1 ? item['name_en'] : item['name_en'] + ", ")}</p>
                                        </div>
                                    </div>
                                    <div className="contact-info">
                                        <a href={`mailto:${doctor.email}`}><span className="icon-email"></span></a>
                                        <a href={`tel:${doctor.mobile}`}><span className="icon-phone"></span></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                {/* Pagination */}
                {!loading && (
                                    <div className="row">
                                    <div className="col-md-12">
                                        {doctorsList?.length > 0 ? (
                                            <nav>
                                                <ul className="pagination justify-content-center align-items-center my-md-2">
                                                    <li className="page-item" style={{ pointerEvents: +pageNumber <= 0 && "none" }}><a href={href} onClick={(e) => { e.preventDefault(); setPageNumber(pageNumber - 1) }}>Prev</a></li>
                                                    {pages.map((pageIndex) => (
                                                        <li className={classNames("page-item", { "active": +pageIndex === pageNumber })} key={pageIndex} onClick={() => setPageNumber(pageIndex)}><a className="page-link" href={href} onClick={(e) => e.preventDefault()}>{pageIndex + 1}</a></li>
                                                    ))}
                                                    <li className="page-item" style={{ pointerEvents: +pageNumber === +numberOfPages - 1 && "none" }}><a href={href} onClick={(e) => { e.preventDefault(); setPageNumber(pageNumber + 1) }}>Next</a></li>
                                                </ul>
                                            </nav>
                                        ) : (
                                            <p>No doctors Found</p>
                                        )}
                
                                    </div>
                                </div>
                )}
            </DashboardLayout>
        </div>
    )
}

const mapStateToProps = (state) => ({
    doctors: state.doctors
})

const mapDispatchToProps = {
    getDoctors,
    deleteDoctor,
    setPageNumber,
    searchDoctor
}

export default connect(mapStateToProps, mapDispatchToProps)(Doctors)

import React, { useEffect, useState } from 'react'
import * as Yup from 'yup'
import { Formik, Form } from 'formik'
import { updateDoctor, selectDoctor } from '../../../../store/actions/doctorActions' // Assuming you have an action to update the doctor
import { connect } from 'react-redux'
import SelectInput from '../../../../components/forms/SelectInput'
import DoctorApi from '../../../../api/Doctors'
import TextInput from '../../../../components/forms/TextInput'
import LookupApi from '../../../../api/lookups'
import NumberFormatInput from '../../../../components/forms/NumberFormat'
import MultipleSelect from '../../../../components/forms/MultipleSelect'

function UpdateBasicDoctorInfo({ updateDoctor, selectedDoctor, selectDoctor }) {

    const [allSpecialities, setAllSpecialities] = useState([])
    const [countries, setCountries] = useState([])
    const [genders, setGenders] = useState([])
    const [languages, setLanguages] = useState([])
    const [selectedSpeciality, setSelectedSpeciality] = useState([])
    const [specialityError, setSpecialityError] = useState(false)

    useEffect(() => {
        DoctorApi.getAllSpecialities("undefined").then(res => {
            const data = []

            res.data.data.forEach(item => {
                data.push({
                    label: item.name_en,
                    value: item._id
                })
            })
            setAllSpecialities(data)
        })
        LookupApi.getCountries().then(res => {
            setCountries(res.data.data)
        })
        LookupApi.getGenders().then(res => {
            setGenders(res.data.data)
        })
        LookupApi.getLanguages().then(res => {
            setLanguages(res.data.data)
        })

        // Pre-select specialities
        const preSelectedSpecialities = selectedDoctor?.specialityId?.map(item => ({
            label: item.name_en,
            value: item._id
        }));
        setSelectedSpeciality(preSelectedSpecialities);

    }, [selectedDoctor])

    return (
        <Formik
            initialValues={{
                firstName: selectedDoctor?.firstName || '',
                lastName: selectedDoctor?.lastName || '',
                email: selectedDoctor?.email || '',
                mobile: selectedDoctor?.mobile || '',
                experience: selectedDoctor?.experience || '',
                gender: selectedDoctor?.gender?._id || selectedDoctor?.gender || '',
                country: selectedDoctor?.country?._id || selectedDoctor?.country || '',
                language: selectedDoctor?.language?._id || selectedDoctor?.language || ''
            }}
            validationSchema={Yup.object({
                firstName: Yup.string().required('Required'),
                lastName: Yup.string().required('Required'),
                email: Yup.string().required('Required').email(),
                mobile: Yup.string().required('Required'),
                experience: Yup.string().required('Required'),
                gender: Yup.string().required('Required'),
                country: Yup.string().required('Required'),
                language: Yup.string().required('Required')
            })}
            onSubmit={async (values, { resetForm }) => {
                if (selectedSpeciality.length === 0) {
                    setSpecialityError(true)
                } else {
                    setSpecialityError(false)

                    const specialityIds = []
                    selectedSpeciality.forEach(item => {
                        specialityIds.push(item.value)
                    })

                    const response = await updateDoctor(selectedDoctor._id, { ...values, specialityId: specialityIds });
                    selectDoctor(null, response.data.data.doctor);
                }

            }}
            enableReinitialize={true}
        >
            <div className="modal fade" id="editDoctorBasicInfo" tabIndex="-1" aria-labelledby="editDoctorBasicInfoLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-lg">
                    <div className="modal-content">
                        <div className="modal-body">
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span className="icon-close"></span>
                            </button>
                            <h4 className="text-center">Update Doctor</h4>
                            <Form>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <TextInput type="text" name="firstName" placeholder="First Name" />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <TextInput type="text" name="lastName" placeholder="Last Name" />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <TextInput type="text" disabled name="email" placeholder="Email" />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <NumberFormatInput
                                                format={"+971-## ### ####"}
                                                mask={"-"}
                                                name="mobile" placeholder="Mobile"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <TextInput type="text" name="experience" placeholder="Experience" />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <MultipleSelect
                                                options={allSpecialities}
                                                value={selectedSpeciality || []}
                                                changeHandler={(e) => { setSelectedSpeciality(e); e.length > 0 ? setSpecialityError(false) : setSpecialityError(true) }}
                                                hasError={specialityError}
                                                label={"Select Speciality"}
                                                errorMessage={"Speciality is required"}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <SelectInput name="gender" style={{ height: "50px" }}>
                                                <option value="">Select Gender</option>
                                                {genders?.map(gender => (
                                                    <option key={gender._id} value={gender._id}>{gender.name_en}</option>
                                                ))}
                                            </SelectInput>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <SelectInput name="country" style={{ height: "50px" }}>
                                                <option value="">Select Country</option>
                                                {countries?.map(country => (
                                                    <option key={country._id} value={country._id}>{country.name_en}</option>
                                                ))}
                                            </SelectInput>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <SelectInput name="language" style={{ height: "50px" }}>
                                                <option value="">Select Language</option>
                                                {languages?.map(lang => (
                                                    <option key={lang._id} value={lang._id}>{lang.name_en}</option>
                                                ))}
                                            </SelectInput>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group text-center mb-0">
                                    <button type="submit" className="btn btn-primary">Confirm</button>
                                </div>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
        </Formik>
    )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {
    updateDoctor,
    selectDoctor
}

export default connect(mapStateToProps, mapDispatchToProps)(UpdateBasicDoctorInfo)

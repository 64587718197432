import { toast } from "react-toastify";
import HospitalServiceApi from "../../api/Hospital/HospitalServiceApi";
import { ADD_HOSPITAL_SERVICE, DELETE_HOSPITAL_SERVICE, GET_HOSPITAL_SERVICES, UPDATE_HOSPITAL_SERVICE } from "../types/hospitalServiceTypes";

export const getHospitalServices = (id) => async (dispatch, getState) => {
    try {
        const response = await HospitalServiceApi.getHospitalServices(id);
        dispatch({
            type: GET_HOSPITAL_SERVICES,
            payload: response.data.data
        });
    } catch (err) {
        toast.error("Problem while getting services");
    }
};

export const deleteHospitalService = (id) => async (dispatch, getState) => {
    try {
        await HospitalServiceApi.deleteHospitalService(id);
        dispatch({
            type: DELETE_HOSPITAL_SERVICE,
            payload: id
        });
        toast.success("Service deleted successfully");
    } catch (err) {
        toast.error(err.response.data.message);
    }
};

export const createHospitalService = (data) => async (dispatch, getState) => {
    try {
        const response = await HospitalServiceApi.createHospitalService(data);
        dispatch({
            type: ADD_HOSPITAL_SERVICE,
            payload: response.data.data
        });
        toast.success("Service created successfully");
    } catch (err) {
        toast.error(err.response.data.message);
    }
};

export const updateHospitalService = (id, data) => async (dispatch, getState) => {
    try {
        const response = await HospitalServiceApi.updateHospitalService(id, data);
        dispatch({
            type: UPDATE_HOSPITAL_SERVICE,
            payload: {
                data: response.data.data,
                id
            }
        });
        toast.success("Service updated successfully");
    } catch (err) {
        toast.error(err.response.data.message);
    }
};

import { Form, Formik } from 'formik'
import React, { useContext, useEffect, useRef } from 'react'
import { toast } from 'react-toastify'
import DoctorApi from '../../../api/Doctors'
import PatientApi from '../../../api/Patients'
import { RootContext } from '../../../contextApi'
import * as Yup from 'yup'
import TextArea from '../../forms/TextArea'
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css"
import { useState } from 'react'
import { BeatLoader } from 'react-spinners'

function SickLeave({ patientId, appointmentId }) {
	const { user } = useContext(RootContext)
	const [loading, setLoading] = useState(true);
	const [previousSickLeave, setPreviousSickLeave] = useState(null);
	const closeBtnRef = useRef();

	useEffect(() => {
		if(appointmentId) {
			getPreviousSickLeaveData();
		}
	}, [appointmentId]);

	const getPreviousSickLeaveData = async () => {
		setLoading(true);
		const res = await PatientApi.getAppointmentSickLeave(appointmentId);
		setPreviousSickLeave(res.data.data);
		setLoading(false);
	};

	return (
		<Formik
			initialValues={{
				description:  previousSickLeave?.description ||  "",
				from: previousSickLeave?.from ? new Date(previousSickLeave?.from) : new Date(),
				to: previousSickLeave?.to ? new Date(previousSickLeave?.to) : new Date(),
			}}
			validationSchema={Yup.object({
				description: Yup.string().required('Required'),
				from: Yup.date().required('Required').nullable(),
				to: Yup.date()
					.required('Required')
					.min(Yup.ref('from'), 'To date cannot be before from date')
					.nullable()
			})}
			onSubmit={(values, { resetForm }) => {
				const leave = {
					doctorId: user.referenceId,
					patientId,
					to: values.to,
					from: values.from,
					description: values.description,
					appointmentId
				}
				setLoading(true);
				if(previousSickLeave && Object.keys(previousSickLeave).length > 0) {
					DoctorApi.updateSickLeave(previousSickLeave._id, leave).then(res => {
						toast.success("Sick Leave Approved");
						setPreviousSickLeave(res.data.data);
						closeBtnRef.current.click();
						resetForm();
					}).catch(err => {
						toast.error("Problem while creating sick leave")
					}).finally(() => {
						setLoading(false);
					})
				} else {
					DoctorApi.createSickLeave(leave).then(res => {
						closeBtnRef.current.click();
						setPreviousSickLeave(res.data.data);
						toast.success("Sick Leave Approved")
						resetForm()
					}).catch(err => {
						toast.error("Problem while creating sick leave")
					}).finally(() => {
						setLoading(false);
					})
				}
			}}
			enableReinitialize={true}
		>
			{({ values, setFieldValue, errors, touched }) => (
				<div className="modal fade" id="sickLeave" tabIndex="-1" aria-labelledby="sickLeaveLabel" aria-hidden="true">
					<div className="modal-dialog modal-dialog-centered modal-lg" style={{ maxWidth: '40%' }}>
						<div className="modal-content">
							<div className="modal-body">
								<button ref={closeBtnRef} type="button" className="close" data-dismiss="modal" aria-label="Close">
									<span className="icon-close"></span>
								</button>
								<h4 className="text-center">Approve Sick Leave</h4>
								<Form>
									<div className="row">
										<div className="col-md-6">
											<div className="form-group">
												<label><strong>From</strong></label>
												<DatePicker
													id="from"
													className="form-control"
													placeholderText="From Date"
													selected={values.from}
													onChange={(date) => setFieldValue('from', date)}
													minDate={new Date()}
													dateFormat="MMMM d, yyyy h:mm aa"
													style={{ width: '100%' }}
												/>
												{errors.from && touched.from ? (
													<div className="invalid-feedback text-right-aligned">{errors.from}</div>
												) : null}
											</div>
										</div>
										<div className="col-md-6">
											<div className="form-group">
												<label><strong>To</strong></label>
												<DatePicker
													id="to"
													className="form-control"
													placeholderText="To Date"
													selected={values.to}
													onChange={(date) => setFieldValue('to', date)}
													minDate={new Date()}
													dateFormat="MMMM d, yyyy h:mm aa"
												/>
												{errors.to && touched.to ? (
													<div className="invalid-feedback text-right-aligned">{errors.to}</div>
												) : null}
											</div>
										</div>
										<div className="col-md-12">
											<div className="form-group">
												<TextArea name="description" rows={8} placeholder="Comments" />
											</div>
										</div>
									</div>
									<div className="form-group text-center">
										<button type='submit' disabled={loading} className='btn btn-primary'>
                      {loading ? <BeatLoader size={8} /> : 'Approve'}
                    </button>
									</div>
								</Form>
							</div>
						</div>
					</div>
				</div>
			)}
		</Formik>
	)
}

export default SickLeave

import React from 'react'
import DashboardLayout from '../../../layout/DashboardLayout'

function AdminDashboard() {
    return (
        <DashboardLayout>
            Admin Dashboard
        </DashboardLayout>
    )
}

export default AdminDashboard

import React, { useEffect, useState } from 'react';
import AppLayout from '../../../../layout/AppLayout';
import APPOINTMENT_IMAGE from '../../../../assets/images/appoint-doctor.png';
import DoctorApi from '../../../../api/Doctors';
import { useParams } from 'react-router-dom';
import i18n from "i18next";
import { Link } from 'react-router-dom';
import EMPTY_IMAGE_PLACEHOLDER from '../../../../assets/images/doctor_placeholder.png';
import { useTranslation } from "react-i18next";

function PatientDoctorProfile() {
    const { t } = useTranslation();
    const { id } = useParams();
    const [doctor, setDoctor] = useState({});

    useEffect(() => {
        DoctorApi.getSingleDoctor(id).then(doctor => {
            setDoctor(doctor?.data?.data || {});
        });
    }, [id]);

    // Render the rating stars based on cumulativeRating
    const renderRatingStars = (rating) => {
        const fullStars = Math.floor(rating);  // Full stars
        const halfStar = rating % 1 >= 0.5 ? 1 : 0;  // Half star
        const emptyStars = 5 - fullStars - halfStar;  // Remaining empty stars

        const stars = [];

        // Add full stars
        for (let i = 0; i < fullStars; i++) {
            stars.push(<i key={`full-${i}`} className="text-warning fa fa-star"></i>);
        }

        // Add half star
        if (halfStar) {
            stars.push(<i key="half" className="text-warning fa fa-star-half-o"></i>);
        }

        // Add empty stars
        for (let i = 0; i < emptyStars; i++) {
            stars.push(<i key={`empty-${i}`} className="fa fa-star-o"></i>);
        }

        return stars;
    };

    const href = "";

    return (
        <AppLayout>
            <section className="search-block pt-4">
                <div className="container">
                    <div className="row"></div>
                    <div className="row">
                        <div className="col-md-12">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><a href={href}>{t('home')}</a></li>
                                    <li className="breadcrumb-item active" aria-current="page">{t('doctor')}</li>
                                    <li className="breadcrumb-item active" aria-current="page">{"Dr. " + (doctor?.firstName ? `${doctor.firstName} ${doctor.lastName}` : `${t('loading')}...`)}</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </section>

            <section className="user-dashboard pb-5">
                <div className="container">
                    <div className="row mt-4">
                        <div className="col-md-4">
                            <div className="profile-image">
                                <img className="img-fluid" src={doctor?.image || EMPTY_IMAGE_PLACEHOLDER} alt="doctor" />
                            </div>
                        </div>
                        <div className="col-md-8 mt-4 mt-md-0">
                            <h4 className="mb-0">
                                <strong>{"Dr. " + (doctor?.firstName ? `${doctor.firstName} ${doctor.lastName}` : `${t('loading')}...`)}</strong>
                                <Link to="/book-appointment" className="btn btn-primary float-right px-4" onClick={() => {
                                    localStorage.setItem("SELECTED_DOCTOR_OR_HOSPITAL", JSON.stringify(doctor));
                                    localStorage.setItem("hospitalDetailPage", false);
                                }}>
                                    {t('book_appointment')}
                                </Link>
                            </h4>
                            <small>{doctor?.specialityId?.map(item => item[`name_${i18n.language}`]).join(', ')}</small>

                            {/* Updated Rating Section */}
                            <p className="rating mb-1">
                                {doctor?.cumulativeRating ? renderRatingStars(doctor.cumulativeRating) : `${t('loading')}`}
                            </p>

                            <p className="mt-2 mb-2"><i className="icon-hospital"></i> {doctor?.hospitalId?.name || ''}</p>
                            <p>{doctor?.about || ''}</p>

                            <h5><strong>{t('speciality')}</strong></h5>
                            <p className="mb-1">{doctor?.specialityId?.map(item => item[`name_${i18n.language}`]).join(', ')}</p>

                            <h5><strong>{t('experience')}</strong></h5>
                            <p>{doctor?.experience || ''}</p>
                        </div>
                    </div>
                </div>
            </section>
        </AppLayout>
    );
}

export default PatientDoctorProfile;

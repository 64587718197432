import React, { useCallback, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { createPromo, setPageNumber } from '../../../../store/actions/promosActions';
import { useDropzone } from 'react-dropzone';
import PromoApi from '../../../../api/promos';
import axios from '../../../../axios';
import { PROMO_REQUEST_NAMESPACE } from '../../../../constants/namespaces';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { toast } from 'react-toastify';
import { BeatLoader } from 'react-spinners';

function AddPromo({ createPromo, setPageNumber }) {
    const [loading, setLoading] = useState(false);
    const [uploadedVideo, setUploadedVideo] = useState([]);
    const [progress, setProgress] = useState();
    const [videoDuration, setVideoDuration] = useState(null);
    const closeBtnRef = useRef();

    const onDrop = useCallback(acceptedFiles => {
        // Reset the video duration state
        setVideoDuration(null);

        if (acceptedFiles.length) {
            const videoFile = acceptedFiles[0];
            const videoElement = document.createElement("video");
            videoElement.preload = "metadata";

            videoElement.onloadedmetadata = () => {
                window.URL.revokeObjectURL(videoElement.src);
                const duration = videoElement.duration;

                const minutes = Math.floor(duration / 60);
                const seconds = Math.floor(duration % 60);

                if (minutes > 2 || (minutes === 2 && seconds > 0)) {
                    toast.error("Video is too long! Please upload a video with a maximum length of 2 minutes.");
                    setUploadedVideo([]); // Prevent file from being displayed
                    setVideoDuration(null); // Reset duration
                } else {
                    setVideoDuration(duration);
                    setUploadedVideo([videoFile]);
                }
            };

            videoElement.src = URL.createObjectURL(videoFile);
        }
    }, []);

    const { getRootProps, getInputProps } = useDropzone({ maxFiles: 1, accept: 'video/*', onDrop });

    const files = uploadedVideo.map(file => (
        <li key={file.path}>
            {file.path} - {file.size} bytes
        </li>
    ));

    const onUploadVideo = async () => {
        const formData = new FormData();

        if (uploadedVideo[0]) {
            if (uploadedVideo[0].size > 20000000) {
                toast.error("File too big, please select a file less than 20MB");
                return false;
            }

            formData.append("video", uploadedVideo[0]);

            try {
                setLoading(true);
                axios.post(`/${PROMO_REQUEST_NAMESPACE}`, formData, {
                    headers: {
                        "Content-Type": "multipart/form-data"
                    },
                    onUploadProgress: (data) => {
                        setProgress(Math.round((100 * data.loaded) / data.total));
                    }
                }).then(async res => {
                    setProgress(null);
                    const response = await PromoApi.getAllPromos(0);
                    await createPromo(response.data.data);
                    setPageNumber(0);
                    closeBtnRef.current.click();
                    setLoading(false);
                }).catch(err => {
                    setProgress(null);
                    toast.error(err.response.data.message);
                    setLoading(false);
                });
            } catch (e) {
                setLoading(false);
            }
        } else {
            toast.error('Please select a video');
        }
    };

    return (
        <div className="modal fade" id="addPromo" tabIndex="-1" aria-labelledby="addPromoLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-lg">
                <div className="modal-content">
                    <div className="modal-body">
                        <button ref={closeBtnRef} type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span className="icon-close"></span>
                        </button>
                        <h4 className="text-center">Add Promo Video</h4>
                        <section className="container">
                            <div {...getRootProps({ className: 'dropzone' })}>
                                <input {...getInputProps()} />
                                <p>Drag 'n' drop video here, or click to select video</p>
                                <em>(Only video files are accepted)</em>
                            </div>
                            <aside>
                                <h4>Files</h4>
                                <ul>{files}</ul>
                            </aside>
                        </section>
                        <div className="col-12 text-right">
                            <a
                                href="javascript:void(0)"
                                style={progress && { pointerEvents: "none" }}
                                className="btn btn-primary px-3"
                                onClick={onUploadVideo}
                            >
                                {(progress && loading) ? <BeatLoader size={8} /> : "UPLOAD VIDEO"}
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = state => ({});

const mapDispatchToProps = {
    createPromo,
    setPageNumber
};

export default connect(mapStateToProps, mapDispatchToProps)(AddPromo);

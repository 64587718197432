export const USERS_NAMESPACE = "users"
export const HOSPITAL_NAMESPACE = "hospitals"
export const DOCTOR_NAMESPACE = "doctors"
export const NURSE_NAMESPACE = 'nurse'
export const RECEPTIONIST_NAMESPACE = 'receptionist'
export const NURSE_REPORT_NAMESPACE = 'nurseReports'
export const LABORATORY_NAMESPACE = 'labortories'
export const CATEGORIES_NAMESPACE = 'categories'
export const PATIENT_NAMESPACE = 'patients'
export const FAMILY_NAMESPACE = 'family'
export const APPOINTMENT_NAMESPACE = 'appointments'
export const LAB_REQUEST_NAMESPACE = 'labRequests'
export const PROMO_REQUEST_NAMESPACE = 'promos'
export const SPECIALITY_REQUEST_NAMESPACE = 'speciality'
export const CATEGORY_REQUEST_NAMESPACE = 'categories'
export const INSURANCE_REQUEST_NAMESPACE = 'insurance'
export const HOSPITAL_ADDON_SERVICES_NAMESPACE = 'hospitals/services'
export const QR_PRESCRIPTION_NAMESPACE = 'qrprescription'
export const SLOT_NAMESPACE = "slots"
export const LOOKUPS_NAMESPACE = "lookups"
export const SICKLEAVES_NAMESPACE = "leaves"
export const VENDOR_NAMESPACE = "vendors"
export const PACKAGE_TYPE_NAMESPACE = "packageCategories"
export const PACKAGE_NAMESPACE = "packages"
export const REWARD_NAMESPACE = "rewards"
export const CODES_NAMESPACE = "codes"
export const DOCTOR_REPORT_NAMESPACE = "doctorReports"
export const HOSPITAL_SERVICE_NAMESPACE = "hospitalServices"
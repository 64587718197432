import React, { useState, useEffect } from 'react';
import ImageUpload from 'image-upload-react';
import 'image-upload-react/dist/index.css';
import { href } from '../../../../constants/extra';
import HospitalApi from '../../../../api/Hospital';
import { toast } from 'react-toastify';
import { BeatLoader } from 'react-spinners';
import REMOVE_ICON from '../../../../assets/images/remove.png';

function HospitalInfo({ hospital, imageDeleted, imageAdded }) {
    const [imageSrc, setImageSrc] = useState(null);
    const [image, setImage] = useState(null);
    const [hospitalImages, setHospitalImages] = useState([]);
    const [loading, setLoading] = useState(true);
    const [deleting , setDeleting] = useState(false);
    const [uploading, setUploading] = useState(false);

    useEffect(() => {
        if (hospital?.hospital) {
            setHospitalImages(hospital?.hospital?.images || []);
            setLoading(false); // Set loading to false when data is available
        }
    }, [hospital]);

    const handleImageSelect = (e) => {
        setImage(e.target.files[0]);
        setImageSrc(URL.createObjectURL(e.target.files[0]));
    };

    const uploadImage = () => {
        if (imageSrc && image) {
            let formData = new FormData();
            formData.append('image', image);
            setUploading(true);
            HospitalApi.uploadHospitalImage(hospital.hospital._id, formData).then(res => {
                console.log(res);
                toast.success("Image Uploaded Successfully");
                setHospitalImages([...hospitalImages, res.data.data.url]);
                imageAdded(res.data.data.url);
                setImage(null);
                setImageSrc(null);
            }).finally(() => {
                setUploading(false);
            });
        }
    };

    const deleteImage = (url) => {
        setDeleting(true);
        const imagePublicId = url.split('\\').pop().split('/').pop().split('.')[0];
        HospitalApi.deleteGalleryImage(hospital.hospital._id, imagePublicId).then(res => {
            console.log(res);
            toast.success("Hospital gallery image deleted");
            const updatedImages = hospitalImages.filter(item => item !== url);
            setHospitalImages(updatedImages);
            imageDeleted(url);
        }).catch(err => {
            toast.error("Failed to delete image");
            console.log(err);
        }).finally(() => {
            setDeleting(false);
        });
    };

    // Render the rating stars based on cumulativeRating
    const renderRatingStars = (rating) => {
        const fullStars = Math.floor(rating);  // Full stars
        const halfStar = rating % 1 >= 0.5 ? 1 : 0;  // Half star
        const emptyStars = 5 - fullStars - halfStar;  // Remaining empty stars

        const stars = [];

        // Add full stars
        for (let i = 0; i < fullStars; i++) {
            stars.push(<i key={`full-${i}`} className="text-warning fa fa-star"></i>);
        }

        // Add half star
        if (halfStar) {
            stars.push(<i key="half" className="text-warning fa fa-star-half-o"></i>);
        }

        // Add empty stars
        for (let i = 0; i < emptyStars; i++) {
            stars.push(<i key={`empty-${i}`} className="fa fa-star-o"></i>);
        }

        return stars;
    };

    if (loading) {
        // Show loading spinner while waiting for data
        return (
            <div className="d-flex justify-content-center align-items-center" style={{ height: "300px" }}>
                <BeatLoader size={12} color={"#123abc"} />
            </div>
        );
    }

    // If hospital.hospital is still undefined, avoid breaking the page
    if (!hospital?.hospital) {
        return (
            <div className="d-flex justify-content-center align-items-center" style={{ height: "300px" }}>
                <p>Unable to load hospital information.</p>
            </div>
        );
    }

    const { name, openingTime, closingTime, about, cumulativeRating } = hospital.hospital;

    return (
        <>
            <div className="row align-items-start add-list hospital-info">
                <div className="col-6">
                    <h4>{name}</h4>
                    <p className="rating mb-1">
                        {cumulativeRating ? renderRatingStars(cumulativeRating) : "Loading"}
                    </p>
                    <h6> {(openingTime && closingTime) ? (openingTime + " - " + closingTime) : "Open Now"}  </h6>
                </div>
                <div className="col-6 text-right">
                    <a href="javascript:void(0)" data-toggle="modal" data-target="#updateHospital" className="btn btn-primary px-4">Update</a>
                </div>
            </div>
            {deleting && (
                <div className="d-flex justify-content-center align-items-center mb-4">
                    <span style={{ fontSize: '1.2rem', fontWeight: 'bold', marginRight: '5px' }}>Deleting</span> <BeatLoader size={8} color={"#123abc"} />
                </div>
            )}
            {uploading && (
                <div className="d-flex justify-content-center align-items-center mb-4">
                    <span style={{ fontSize: '1.2rem', fontWeight: 'bold', marginRight: '5px' }}>Uploading</span> <BeatLoader size={8} color={"#123abc"} />
                </div>
            )}
            <div className="row mt-2">
                {hospitalImages?.length > 0 && hospitalImages?.map(img => (
                    <div className="col-md-3" key={img}>
                        <img alt='profile delete' className='profile-img-cross' onClick={() => deleteImage(img)} src={REMOVE_ICON} />
                        <img className="banner-picture" style={{ marginBottom: "20px", boxShadow: '0px 6px 15px 4px rgba(202,202,202,0.75)', }} src={img} alt="hospital" />
                    </div>
                ))}
                <div className="col-md-3">
                    <ImageUpload
                        handleImageSelect={handleImageSelect}
                        imageSrc={imageSrc}
                        setImageSrc={setImageSrc}
                        defaultDeleteIconColor={"#F44336"}
                        style={{
                            width: "250px",
                            height: "200px",
                            background: '#417EBF',
                            textAlign: 'center',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            flexDirection: 'column',
                            borderRadius: '10px',
                            boxShadow: '0px 6px 15px 4px rgba(202,202,202,0.75)',
                            marginTop: "0",
                            cursor: 'pointer',
                        }}
                    />
                    {imageSrc && image && (
                        <span onClick={(e) => { e.preventDefault(); uploadImage(); }}>
                            <a href={href} style={{ width: '250px', marginTop: '10px' }} className="btn btn-primary px-4">Upload</a>
                        </span>
                    )}
                </div>
            </div>
            <div className="row mt-4">
                <div className="col-md-8" style={{ marginTop: '20px' }} >
                    <h4>About the Hospital</h4>
                    <p>{about}</p>
                </div>
            </div>
        </>
    );
}

export default HospitalInfo;

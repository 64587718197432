import React, { useEffect, useState } from "react";
import DOCTOR_IMAGE from "../../../assets/images/doctor.png";
import PatientApi from "../../../api/Patients";
import { FadeLoading } from "../../../loaders";
import { href } from "../../../constants/extra";
import PreviousPrescriptions from "./PreviousPrescriptions";
import PreviousLabResults from "./PreviousLabResults";

function PatientInfo({ appointment, loading, getPatientPreviousData, previousData, previousDataLoading }) {
  return (
    <div className="diagnosis-flex-patient patient-card">
      {loading ? (
          <FadeLoading />
      ) : (
        <>
          <div className="patient-img">
            <img src={DOCTOR_IMAGE} alt="PATIENT" />
          </div>
          <div className="patient-details">
            <label>Patient Name</label>
            <input
              className="input"
              type="text"
              value={
                appointment?.patientId?.firstName +
                " " +
                appointment?.patientId?.lastName
              }
            />
          </div>
          <div className="patient-details">
            <label>Date Of Birth</label>
            <input
              className="input"
              type="text"
              value={appointment?.patientId?.birthday}
            />
          </div>
          <div className="patient-details">
            <label>Emirates ID</label>
            <input
              className="input"
              type="text"
              value={appointment?.patientId?.emiratesId}
            />
          </div>
          <div className="patient-details">
            <label>Disease</label>
            <input className="input" type="text" value={appointment?.patientId?.diseases} />
          </div>
          <div className="patient-details">
            <label>Allergies</label>
            <input className="input" type="text" value={appointment?.patientId?.allergies} />
          </div>
          <div className="patient-details">
            <label>Insurances</label>
            <input className="input" type="text" value={
                appointment?.patientId?.insurances?.map((insurance, i) =>  i === appointment?.patientId?.insurances?.length - 1 ? insurance.name_en : insurance.name_en + ", ") || ""
              } 
            />
          </div>
          <div className="patient-buttons">
            <a 
              href={href} 
              data-toggle="modal" 
              data-target="#previousPrescription" 
              className="btn btn-primary px-3"
              onClick={getPatientPreviousData}
            >Previous Prescriptions</a>
            <a 
              href={href} 
              data-toggle="modal" 
              data-target="#previousLabResults" 
              className="btn btn-primary px-3"
              onClick={getPatientPreviousData}
            >Previous Lab Results</a>
          </div>
        </>
      )}
      <PreviousPrescriptions data={previousData} previousDataLoading={previousDataLoading} />
      <PreviousLabResults data={previousData} previousDataLoading={previousDataLoading} />
    </div>
  );
}

export default PatientInfo;

import React from "react";
import { FadeLoading } from "../../../loaders";
import moment from "moment";

function PreviousLabResults({ data, previousDataLoading }) {
  const downloadReport = (file) => {
    const newWindow = window.open(file, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  return (
    <div
      className="modal fade"
      id="previousLabResults"
      tabindex="-1"
      aria-labelledby="previousLabResultsLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered modal-lg">
        <div className="modal-content">
          <div className="modal-body">
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span className="icon-close"></span>
            </button>
            <h4 className="text-center">Previous Lab Results</h4>

            {previousDataLoading && (
              <div style={{ width: "100%", marginTop: "5%" }}>
                <FadeLoading />
              </div>
            )}

            {!previousDataLoading && data?.length === 0 && (
              <p className="text-center">No previous lab results found.</p>
            )}

            {data?.filter(app => app.status === 'TAKEN')?.map((appointment, index) => (
              <div key={appointment?._id || index} className="mb-4">
                <div className="appointment-header">
                  <h5 className="mb-1">
                    {appointment?.from
                      ? moment(appointment.from).format("MMMM Do YYYY, h:mm a")
                      : "N/A"}{" "}
                    - {appointment?.status || "N/A"}
                  </h5>
                  <p className="mb-1">
                    <strong>Description:</strong>{" "}
                    {appointment?.description || "N/A"}
                  </p>
                </div>

                  <div>
                    {appointment?.labRequest ?
                      Object.keys(appointment?.labRequest).length > 0 && (
                        <table
                          style={{ borderCollapse: "collapse", width: "100%" }}
                        >
                          <thead>
                            <tr>
                              <th
                                style={{
                                  border: "1px solid #dddddd",
                                  textAlign: "left",
                                  padding: "8px",
                                }}
                              >
                                Test
                              </th>
                              <th
                                style={{
                                  border: "1px solid #dddddd",
                                  textAlign: "left",
                                  padding: "8px",
                                }}
                              >
                                Result
                              </th>
                              <th
                                style={{
                                  border: "1px solid #dddddd",
                                  textAlign: "left",
                                  padding: "8px",
                                }}
                              >
                                Reference Range
                              </th>
                              <th
                                style={{
                                  border: "1px solid #dddddd",
                                  textAlign: "left",
                                  padding: "8px",
                                }}
                              >
                                Report
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {appointment?.labRequest.tests.map((item) => (
                              <tr>
                                <td
                                  style={{
                                    border: "1px solid #dddddd",
                                    textAlign: "left",
                                    padding: "8px",
                                  }}
                                >
                                  {item.test}
                                </td>
                                <td
                                  style={{
                                    border: "1px solid #dddddd",
                                    textAlign: "left",
                                    padding: "8px",
                                  }}
                                >
                                  {item.result}
                                </td>
                                <td
                                  style={{
                                    border: "1px solid #dddddd",
                                    textAlign: "left",
                                    padding: "8px",
                                  }}
                                >
                                  {item.range}
                                </td>
                                <td
                                  style={{
                                    border: "1px solid #dddddd",
                                    textAlign: "left",
                                    padding: "8px",
                                    alignContent: "center",
                                  }}
                                >
                                  {item.file !== null ? (
                                    <a
                                      href="javascript:void(0)"
                                      class="btn btn-primary px-3 py-2  text-center cursor-pointer"
                                      onClick={downloadReport.bind(
                                        this,
                                        item.file
                                      )}
                                    >
                                      Download Report
                                    </a>
                                  ) : (
                                    "Not Available"
                                  )}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      ) : (<>
                        <p className="mt-2 text-danger">No Lab Results for this appointment</p>
                      </>)}
                  </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default PreviousLabResults;

import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import TextInput from "../../forms/TextInput";
import TextArea from "../../forms/TextArea";
import NurseReportApi from "../../../api/NurseReport";
import { BeatLoader } from "react-spinners";
import { FadeLoading } from "../../../loaders";
import { toast } from 'react-toastify';
import { useConfirmationDialog } from "../../../contextApi/ConfirmationDialogContext";

function NurseDiagnosis({ isDoctor, appointment, nurseId }) {
  const [existingReport, setExistingReport] = useState({});
  const [loading, setLoading] = useState(false);
  const [loadingData, setLoadingData] = useState(true);
	const { showConfirmation } = useConfirmationDialog()

  useEffect(() => {
    getExistingDiagnosisReport();
  }, [nurseId, appointment?._id]);
  
  const getExistingDiagnosisReport = async () => {
    if(appointment?._id) {
      try {
        setLoadingData(true);
        const nurseReport = await NurseReportApi.getAppointmentNurseReport(appointment._id);
        setExistingReport(nurseReport?.data?.data?.[0]);
        setLoadingData(false);
      } catch (err) {
        setExistingReport([]);
        setLoadingData(false);
      }
    }
  };

  return (
    <Formik
      initialValues={{
        height: existingReport?.height || "",
        weight: existingReport?.weight || "",
        bp: existingReport?.bp || "",
        bt: existingReport?.bt || "",
        complain: existingReport?.complain || "",
        heartRate: existingReport?.heartRate || "",
        glucose: existingReport?.glucose || "",
      }}
      validationSchema={Yup.object({
        height: Yup.number().positive("Height must be a positive number").required("Required"),
        weight: Yup.number().positive("Height must be a positive number").required("Required"),
        bp: Yup.string().required("Required"),
        bt: Yup.number().positive("Height must be a positive number").required("Required"),
        complain: Yup.string().required("Required"),
        heartRate: Yup.number().positive("Height must be a positive number").required("Required"),
        glucose: Yup.number().positive("Height must be a positive number").required("Required"),
      })}
      onSubmit={(values, { resetForm }) => {
        showConfirmation('Are you sure you want to save?', async () => {
          if(existingReport && Object.keys(existingReport).length > 0) {
            try {
              setLoading(true);
              await NurseReportApi.updateNurseReport(existingReport?._id, {
                ...values,
                appointmentId: appointment._id,
                nurseId,
                patientId: appointment.patientId?._id,
                doctorId: appointment.doctorId?._id,
                hospitalId: appointment.hospitalId
              });
              toast.success("Report Updated Successfully")
              setLoading(false);
            } catch (e) {
              setLoading(false);
            }
          } else {
            try {
              setLoading(true);
              const response = await NurseReportApi.createNurseReport({
                ...values,
                appointmentId: appointment._id,
                nurseId,
                patientId: appointment.patientId?._id,
                doctorId: appointment.doctorId?._id,
                hospitalId: appointment.hospitalId
              });
              setExistingReport(response.data.data);
              toast.success("Report Generated Successfully")
              setLoading(false);
            } catch (e) {
              setLoading(false);
            }
          }
        });
      }}
      enableReinitialize={true}
    >
      <div
        className="other-top-section-1"
        style={(isDoctor || loading) ? { pointerEvents: "none", opacity: "0.5" } : {}}
      >
        <p>
          <strong>Nurse Diagnosis { loadingData && "( Loading... )" }</strong>
        </p>
        {loadingData ? (
          <FadeLoading />
        ) : (
          <Form>
          <div className="diagnosis-row">
            <div>
              <label>Height</label>
              <TextInput
                className="input"
                type="number"
                name="height"
                placeholder="Height"
              />
            </div>
            <div>
              <label>Weight</label>
              <TextInput
                className="input"
                type="number"
                name="weight"
                placeholder="Weight"
              />
            </div>
            <div>
              <label>BP</label>
              <TextInput
                className="input"
                type="text"
                name="bp"
                placeholder="BP"
              />
            </div>
            <div>
              <label>HR</label>
              <TextInput
                className="input"
                type="number"
                name="heartRate"
                placeholder="Heart Rate"
              />
            </div>
            <div>
              <label>Glucose</label>
              <TextInput
                className="input"
                type="number"
                name="glucose"
                placeholder="Glucose"
              />
            </div>
            <div>
              <label>BT</label>
              <TextInput
                className="input"
                type="number"
                name="bt"
                placeholder="bt"
              />
            </div>
          </div>
          <label>Complaining From</label>
          <TextArea
            type="complain"
            name="complain"
            placeholder="Complain"
            rows={4}
            className="textarea"
          />
          <div style={{ textAlign: "center" }}>
            <button disabled={loading} type="submit" className="btn btn-primary mt-2">
              {loading ? (
                <BeatLoader size={8} />
              ) : (
                <>
                  Save
                </>
              )}
            </button>
          </div>
        </Form>
        )}
      </div>
    </Formik>
  );
}

export default NurseDiagnosis;

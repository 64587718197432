import React, { useContext, useEffect } from 'react'
import { href } from '../../constants/extra'
import AppLayout from '../../layout/AppLayout'
import { isMobileOnly } from "react-device-detect";
import { RootContext } from '../../contextApi';

function TermsOfUse() {
    const { user } = useContext(RootContext);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            {isMobileOnly || !user ? (
                <>
                    <section class="user-dashboard pb-5" style={{ marginTop: "3rem" }}>
    <div class="container">
        <h4 style={{ fontWeight: 'bold' }}>MEDICAPP L.L.C. Terms and Conditions</h4>
        <p style={{ marginTop: '-5px' }}>Last Updated: 24/09/2024</p>
        <hr />
        <p style={{ marginTop: '3px', fontSize: '0.9rem' }}>
            Welcome to MEDICAPP, a platform that connects patients with healthcare providers, including clinics, doctors, and technicians. By accessing or using MEDICAPP, you agree to abide by the following Terms and Conditions, which govern your use of the mobile application (the “App”) and its services. If you disagree with any part of these Terms and Conditions, you must discontinue the use of MEDICAPP immediately.
        </p>

        <span style={{ color: 'grey' }}>DEFINITIONS</span>
        <p style={{ marginTop: '3px', fontSize: '0.9rem' }}>
            For the purpose of these Terms and Conditions:
        </p>
        <ul style={{ fontSize: '0.9rem', marginTop: '3px' }}>
            <li><strong>“App”:</strong> Refers to the mobile application known as MEDICAPP, operated by MEDICAPP LLC, accessible via mobile devices.</li>
            <li><strong>“We,” “Us,” “Our”:</strong> Refers to MEDICAPP LLC, the legal entity responsible for developing, managing, and maintaining the App and its services.</li>
            <li><strong>“You,” “Your,” “User”:</strong> Refers to any individual or entity using the App, including but not limited to patients, healthcare providers, clinics, doctors, technicians, or other service providers.</li>
            <li><strong>“Service” or “Services”:</strong> Refers to the functionalities provided by the App, such as appointment booking, accessing medical records, viewing promotional videos, interacting with clinics, and participating in rewards programs.</li>
            <li><strong>“Healthcare Provider”:</strong> Refers to any clinic, doctor, nurse, technician, or medical institution offering medical services via MEDICAPP.</li>
            <li><strong>“Terms”:</strong> Refers to these Terms and Conditions.</li>
            <li><strong>“Content”:</strong> Refers to all text, images, audio, video, graphics, promotional materials, or other forms of media uploaded to the App by MEDICAPP LLC or its Users.</li>
            <li><strong>“Account”:</strong> Refers to your personal or organizational registration on the App.</li>
        </ul>

        <span style={{ color: 'grey' }}>AGREEMENT TO TERMS</span>
        <p style={{ marginTop: '3px', fontSize: '0.9rem' }}>
            By downloading, installing, or using the App, you agree to comply with and be bound by these Terms and any additional terms and conditions that may apply to specific services or features of the App. These Terms constitute a legally binding agreement between you and MEDICAPP LLC.
        </p>
        <p style={{ marginTop: '3px', fontSize: '0.9rem' }}>
            If you are using the App on behalf of a company or organization, you represent that you have the authority to bind that entity to these Terms.
        </p>

        <span style={{ color: 'grey' }}>MODIFICATIONS TO TERMS</span>
        <p style={{ marginTop: '3px', fontSize: '0.9rem' }}>
            We reserve the right to modify these Terms and Conditions at any time without prior notice. We will notify you of material changes either through the App, via email, or by other reasonable means. Any modifications will take effect immediately upon posting unless stated otherwise. Your continued use of the App after modifications are made constitutes your acceptance of the updated Terms. It is your responsibility to review these Terms periodically.
        </p>

        <span style={{ color: 'grey' }}>ELIGIBILITY TO USE</span>
        <p style={{ marginTop: '3px', fontSize: '0.9rem' }}>
            To use the App and access its features, you must meet the following eligibility criteria:
        </p>
        <ul style={{ fontSize: '0.9rem', marginTop: '3px' }}>
            <li><strong>Age Requirement:</strong> You must be at least 18 years old to use the App. Users under 18 must have explicit permission from a parent or legal guardian.</li>
            <li><strong>Legal Capacity:</strong> You must possess the legal capacity to enter into a binding contract under applicable law.</li>
            <li><strong>Geographical Availability:</strong> The App is accessible in various regions, but availability may vary depending on your geographical location and applicable healthcare laws. It is your responsibility to comply with local laws and regulations regarding the use of such services.</li>
        </ul>

        <p style={{ marginTop: '3px', fontSize: '0.9rem' }}>
            By using the App, you warrant that you meet all eligibility requirements.
        </p>

        <span style={{ color: 'grey' }}>ACCOUNT REGISTRATION AND SECURITY</span>
        <p style={{ marginTop: '3px', fontSize: '0.9rem' }}>5.1 Account Creation</p>
        <p style={{ marginTop: '3px', fontSize: '0.9rem' }}>
            In order to use certain features of the App, including booking appointments, accessing medical records, and redeeming rewards, you must register for an account. You agree to:
        </p>
        <ul style={{ fontSize: '0.9rem', marginTop: '3px' }}>
            <li>Provide accurate and up-to-date information during the registration process.</li>
            <li>Maintain the security of your login credentials and not share them with others.</li>
            <li>Notify us immediately of any unauthorized use or security breach of your account.</li>
            <li>Ensure that all information associated with your account, including your medical history and contact details, remains accurate and current.</li>
        </ul>
        <p style={{ marginTop: '3px', fontSize: '0.9rem' }}>
            We reserve the right to suspend or terminate your account at any time, especially if it is found that any information provided is false or misleading or if you violate these Terms.
        </p>

        <p style={{ marginTop: '3px', fontSize: '0.9rem' }}>5.2 Account Types</p>
        <p style={{ marginTop: '3px', fontSize: '0.9rem' }}>
            There are multiple account types on the App, including but not limited to:
        </p>
        <ul style={{ fontSize: '0.9rem', marginTop: '3px' }}>
            <li><strong>Patient Accounts:</strong> For individuals seeking to book medical appointments, view medical records, and access promotional content.</li>
            <li><strong>Clinic and Provider Accounts:</strong> For healthcare providers who wish to offer services through the platform, upload promotional content, and manage patient appointments.</li>
        </ul>
        <p style={{ marginTop: '3px', fontSize: '0.9rem' }}>
            Each type of account may have specific privileges and responsibilities based on the nature of services offered or consumed.
        </p>

        <p style={{ marginTop: '3px', fontSize: '0.9rem' }}>5.3 Account Termination</p>
        <p style={{ marginTop: '3px', fontSize: '0.9rem' }}>
            You may delete your account at any time by accessing the settings on the App. Upon account deletion:
        </p>
        <ul style={{ fontSize: '0.9rem', marginTop: '3px' }}>
            <li>All user-specific data, including appointment history, will be permanently deleted from our servers in accordance with our data retention policies.</li>
            <li>MEDICAPP LLC may retain anonymized data for analytical purposes, even after account termination.</li>
            <li>Any pending rewards or points will be forfeited.</li>
        </ul>
        <p style={{ marginTop: '3px', fontSize: '0.9rem' }}>
            We reserve the right to suspend or terminate your account if you fail to comply with any provision of these Terms, misuse the platform, or engage in fraudulent activities.
        </p>

        <span style={{ color: 'grey' }}>MEDICAPP SERVICES</span>
        <p style={{ marginTop: '3px', fontSize: '0.9rem' }}>6.1 Appointment Booking</p>
        <p style={{ marginTop: '3px', fontSize: '0.9rem' }}>
            MEDICAPP provides a platform for Users to book appointments with healthcare providers. By using the appointment booking service, you acknowledge that:
        </p>
        <ul style={{ fontSize: '0.9rem', marginTop: '3px' }}>
            <li>Appointments are subject to the availability of the selected healthcare provider and clinic.</li>
            <li>After an appointment is confirmed, a notification will be sent to your registered email or phone number. You are responsible for checking appointment confirmations and reminders.</li>
            <li>You must attend your booked appointments or cancel them in advance if you are unable to attend. Failure to cancel appointments in a timely manner may result in penalties, including account suspension or limitations.</li>
        </ul>
        <p style={{ marginTop: '3px', fontSize: '0.9rem' }}>
            MEDICAPP is a facilitator and is not responsible for the quality of medical services provided by healthcare providers.
        </p>

        <p style={{ marginTop: '3px', fontSize: '0.9rem' }}>6.2 Medical Record Access</p>
        <p style={{ marginTop: '3px', fontSize: '0.9rem' }}>
            Through the App, you may have access to your personal medical records, including but not limited to:
        </p>
        <ul style={{ fontSize: '0.9rem', marginTop: '3px' }}>
            <li>Sick Leave Certificates: Provided by healthcare providers if applicable.</li>
            <li>Prescriptions: Details of any prescriptions issued to you during your appointment.</li>
            <li>Test Results: Laboratory test results uploaded by the clinic or healthcare provider.</li>
            <li>Appointment Summaries: Summaries of your medical visits, including diagnostic details and treatment plans.</li>
        </ul>
        <p style={{ marginTop: '3px', fontSize: '0.9rem' }}>
            MEDICAPP does not guarantee the accuracy of these records and assumes no liability for errors or discrepancies. It is your responsibility to review your medical information and notify your healthcare provider of any errors.
        </p>

        <p style={{ marginTop: '3px', fontSize: '0.9rem' }}>6.3 Search Functionality</p>
        <p style={{ marginTop: '3px', fontSize: '0.9rem' }}>
            You may use the App’s search feature to:
        </p>
        <ul style={{ fontSize: '0.9rem', marginTop: '3px' }}>
            <li>Search by clinic, doctor, or service.</li>
            <li>View clinic pages, which include lists of healthcare providers and services offered.</li>
            <li>Book appointments directly with clinics or specific healthcare providers.</li>
        </ul>
        <p style={{ marginTop: '3px', fontSize: '0.9rem' }}>
            You agree that search results are influenced by availability, location, and other factors. MEDICAPP does not endorse any specific healthcare provider or service.
        </p>

        <p style={{ marginTop: '3px', fontSize: '0.9rem' }}>6.4 Promotional Clips and Videos</p>
        <p style={{ marginTop: '3px', fontSize: '0.9rem' }}>
            Clinics may upload promotional content, such as videos showcasing services or special offers, which you can view and interact with. By engaging with such content, you agree to:
        </p>
        <ul style={{ fontSize: '0.9rem', marginTop: '3px' }}>
            <li>Not misuse or manipulate the ratings or feedback system.</li>
            <li>Engage in a respectful manner, adhering to guidelines set forth by MEDICAPP regarding content interaction.</li>
        </ul>
        <p style={{ marginTop: '3px', fontSize: '0.9rem' }}>6.5 Family Account Management</p>
        <p style={{ marginTop: '3px', fontSize: '0.9rem' }}>
            The App allows you to manage bookings and medical profiles for family members under your account, subject to their consent. You are responsible for ensuring the accuracy of information entered on behalf of family members and for obtaining necessary legal consent where applicable.
        </p>

        <span style={{ color: 'grey' }}>REWARDS AND LOYALTY PROGRAM</span>
        <p style={{ marginTop: '3px', fontSize: '0.9rem' }}>
            MEDICAPP offers a points-based rewards program to incentivize engagement with the platform. By participating in the rewards program, you agree to the following:
        </p>
        <ul style={{ fontSize: '0.9rem', marginTop: '3px' }}>
            <li><strong>Point Accrual:</strong> You earn points by completing certain actions, such as attending medical appointments, interacting with promotional content, or referring new users to the platform.</li>
            <li><strong>Point Redemption:</strong> Points can be redeemed for rewards at participating entertainment venues, as displayed on the App. Each reward is subject to availability, and we reserve the right to modify or discontinue any reward without notice.</li>
            <li><strong>Expiration:</strong> Points may expire if unused within a specific time period, as detailed in the App.</li>
            <li><strong>Non-Transferability:</strong> Points are non-transferable and may not be sold, exchanged, or gifted to another account or individual.</li>
            <li><strong>Program Modifications:</strong> We reserve the right to modify or terminate the rewards program at any time, with or without notice. Changes to the program will be communicated through the App.</li>
        </ul>
        <p style={{ marginTop: '3px', fontSize: '0.9rem' }}>
            MEDICAPP LLC assumes no liability for disputes arising between users and third-party entertainment venues regarding the redemption of points.
        </p>

        <span style={{ color: 'grey' }}>RESPONSIBILITIES AND CONDUCT</span>
        <p style={{ marginTop: '3px', fontSize: '0.9rem' }}>8.1 User Conduct</p>
        <p style={{ marginTop: '3px', fontSize: '0.9rem' }}>
            By using the App, you agree not to engage in any of the following prohibited activities:
        </p>
        <ul style={{ fontSize: '0.9rem', marginTop: '3px' }}>
            <li><strong>Misrepresentation:</strong> Providing false or misleading information in any part of the App, including during account registration or appointment bookings.</li>
            <li><strong>Malicious Activities:</strong> Attempting to harm the App, its infrastructure, or other users through hacking, phishing, or the introduction of malicious code.</li>
            <li><strong>Harassment:</strong> Harassing, threatening, or abusing other users, healthcare providers, or staff members of MEDICAPP LLC.</li>
            <li><strong>Inappropriate Content:</strong> Uploading, distributing, or sharing any inappropriate, illegal, or offensive content.</li>
            <li><strong>Violating Third-Party Rights:</strong> Infringing on the intellectual property, privacy, or other legal rights of any third party.</li>
        </ul>
        <p style={{ marginTop: '3px', fontSize: '0.9rem' }}>
            MEDICAPP LLC reserves the right to monitor and review user activities and suspend or terminate accounts that violate these rules.
        </p>

        <p style={{ marginTop: '3px', fontSize: '0.9rem' }}>8.2 Responsibilities for Appointment Management</p>
        <ul style={{ fontSize: '0.9rem', marginTop: '3px' }}>
            <li><strong>Timely Attendance:</strong> You are responsible for attending your booked appointments at the scheduled time. Failing to show up for an appointment or canceling at the last minute may result in penalties, including suspension of booking privileges.</li>
            <li><strong>Cancellations:</strong> Cancellations must be made according to the healthcare provider’s policy. Any fees incurred due to late cancellations are the responsibility of the User.</li>
        </ul>

        <p style={{ marginTop: '3px', fontSize: '0.9rem' }}>8.3 Reviews and Feedback</p>
        <p style={{ marginTop: '3px', fontSize: '0.9rem' }}>
            You may provide reviews and feedback on healthcare providers following appointments. Reviews must:
        </p>
        <ul style={{ fontSize: '0.9rem', marginTop: '3px' }}>
            <li>Be based on your genuine experience.</li>
            <li>Not contain offensive language, false claims, or malicious content.</li>
            <li>Be respectful of the provider’s reputation.</li>
        </ul>
        <p style={{ marginTop: '3px', fontSize: '0.9rem' }}>
            MEDICAPP reserves the right to remove or moderate reviews that violate these guidelines.
        </p>

        <span style={{ color: 'grey' }}>HEALTHCARE PROVIDERS AND CLINICS</span>
        <p style={{ marginTop: '3px', fontSize: '0.9rem' }}>9.1 Independent Contractors</p>
        <p style={{ marginTop: '3px', fontSize: '0.9rem' }}>
            Healthcare providers available through MEDICAPP are independent contractors and not employees or affiliates of MEDICAPP LLC. The App acts solely as an intermediary between patients and healthcare providers.
        </p>

        <p style={{ marginTop: '3px', fontSize: '0.9rem' }}>9.2 Medical Services Disclaimer</p>
        <p style={{ marginTop: '3px', fontSize: '0.9rem' }}>
            MEDICAPP LLC does not provide medical services and is not responsible for the quality, accuracy, or outcome of any medical care or treatment provided by healthcare professionals through the App. You acknowledge that healthcare providers are responsible for their actions, including diagnosing conditions and offering treatments.
        </p>

        <p style={{ marginTop: '3px', fontSize: '0.9rem' }}>9.3 Provider Responsibilities</p>
        <ul style={{ fontSize: '0.9rem', marginTop: '3px' }}>
            <li>Ensuring that their profiles and information are accurate and up-to-date.</li>
            <li>Providing quality care to patients and adhering to relevant laws and regulations.</li>
            <li>Managing their appointment availability and ensuring that they honor booked appointments.</li>
        </ul>

        <span style={{ color: 'grey' }}>FEES AND PAYMENTS</span>
        <p style={{ marginTop: '3px', fontSize: '0.9rem' }}>10.1 Service Fees</p>
        <p style={{ marginTop: '3px', fontSize: '0.9rem' }}>
            MEDICAPP LLC may charge service fees for premium features or other services offered through the App. Any applicable fees will be clearly communicated at the point of purchase.
        </p>

        <p style={{ marginTop: '3px', fontSize: '0.9rem' }}>10.2 Payment Processing</p>
        <p style={{ marginTop: '3px', fontSize: '0.9rem' }}>
            Payments for appointments and services are processed through third-party payment providers. MEDICAPP LLC is not responsible for any issues arising from payment processing, including delays, errors, or refunds. You agree to abide by the payment terms set forth by the respective third-party payment processors.
        </p>

        <p style={{ marginTop: '3px', fontSize: '0.9rem' }}>10.3 Refunds</p>
        <p style={{ marginTop: '3px', fontSize: '0.9rem' }}>
            Refunds for appointments or services are subject to the policies of the respective healthcare provider. MEDICAPP LLC does not handle refunds directly and is not responsible for resolving disputes related to payments.
        </p>
        <span style={{ color: 'grey' }}>PRIVACY AND DATA SECURITY</span>
        <p style={{ marginTop: '3px', fontSize: '0.9rem' }}>
            Your privacy is of paramount importance to us. Please refer to our <a href="/privacy-policy">Privacy Policy</a> for detailed information on how we collect, use, and protect your personal data.
        </p>

        <p style={{ marginTop: '3px', fontSize: '0.9rem' }}>11.1 Data Collection</p>
        <p style={{ marginTop: '3px', fontSize: '0.9rem' }}>
            We collect personal data, such as your name, contact information, medical history, and appointment details, to provide our services. This information is stored securely and is only accessible by authorized personnel.
        </p>

        <p style={{ marginTop: '3px', fontSize: '0.9rem' }}>11.2 Data Use</p>
        <ul style={{ fontSize: '0.9rem', marginTop: '3px' }}>
            <li>Facilitating appointments and interactions with healthcare providers.</li>
            <li>Improving the App’s functionality and services.</li>
            <li>Marketing and promotional purposes, with your consent.</li>
        </ul>

        <p style={{ marginTop: '3px', fontSize: '0.9rem' }}>11.3 Data Sharing</p>
        <p style={{ marginTop: '3px', fontSize: '0.9rem' }}>
            We may share your data with healthcare providers, third-party partners, or as required by law. We ensure that all third parties comply with relevant data protection regulations.
        </p>

        <span style={{ color: 'grey' }}>INTELLECTUAL PROPERTY RIGHTS</span>
        <p style={{ marginTop: '3px', fontSize: '0.9rem' }}>
            All intellectual property rights, including trademarks, copyrights, patents, and proprietary rights, in and to the App are owned by MEDICAPP LLC or its licensors. You may not use, copy, modify, distribute, or reverse-engineer any part of the App without prior written consent from MEDICAPP LLC.
        </p>

        <p style={{ marginTop: '3px', fontSize: '0.9rem' }}>12.1 License to Use</p>
        <p style={{ marginTop: '3px', fontSize: '0.9rem' }}>
            We grant you a limited, non-exclusive, non-transferable, and revocable license to use the App solely for personal, non-commercial purposes, subject to these Terms and Conditions.
        </p>

        <span style={{ color: 'grey' }}>WARRANTIES AND DISCLAIMERS</span>
        <p style={{ marginTop: '3px', fontSize: '0.9rem' }}>13.1 No Warranty</p>
        <p style={{ marginTop: '3px', fontSize: '0.9rem' }}>
            MEDICAPP LLC provides the App on an “as-is” and “as-available” basis without any warranties of any kind, either express or implied. We make no representations or warranties regarding:
        </p>
        <ul style={{ fontSize: '0.9rem', marginTop: '3px' }}>
            <li>The availability, accuracy, or reliability of the App.</li>
            <li>The qualifications or quality of healthcare providers listed on the App.</li>
            <li>The outcomes or results of any medical treatments obtained through the App.</li>
        </ul>

        <p style={{ marginTop: '3px', fontSize: '0.9rem' }}>13.2 Limitation of Liability</p>
        <p style={{ marginTop: '3px', fontSize: '0.9rem' }}>
            To the fullest extent permitted by law, MEDICAPP LLC shall not be liable for any direct, indirect, incidental, special, or consequential damages arising from your use of the App or any services provided by healthcare providers. This includes, but is not limited to, loss of data, profits, goodwill, or reputation.
        </p>

        <span style={{ color: 'grey' }}>INDEMNIFICATION</span>
        <p style={{ marginTop: '3px', fontSize: '0.9rem' }}>
            You agree to indemnify, defend, and hold harmless MEDICAPP LLC and its officers, directors, employees, agents, and affiliates from any claims, damages, liabilities, costs, and expenses (including legal fees) arising from:
        </p>
        <ul style={{ fontSize: '0.9rem', marginTop: '3px' }}>
            <li>Your use or misuse of the App.</li>
            <li>Your violation of these Terms and Conditions.</li>
            <li>Any disputes or legal actions initiated by third parties due to your actions on the App.</li>
        </ul>
        <span style={{ color: 'grey' }}>GOVERNING LAW AND DISPUTE RESOLUTION</span>
        <p style={{ marginTop: '3px', fontSize: '0.9rem' }}>15.1 Governing Law</p>
        <p style={{ marginTop: '3px', fontSize: '0.9rem' }}>
            These Terms and Conditions are governed by the laws of the United Arab Emirates, without regard to its conflict of laws principles. Any disputes arising from or related to these Terms will be subject to the exclusive jurisdiction of the (Dubai International Arbitration Center).
        </p>

        <p style={{ marginTop: '3px', fontSize: '0.9rem' }}>15.2 Arbitration</p>
        <p style={{ marginTop: '3px', fontSize: '0.9rem' }}>
            Any dispute, claim, or controversy arising out of or relating to these Terms and Conditions or your use of the App shall be resolved through binding arbitration, in accordance with the rules of (Dubai International Arbitration Center).
        </p>

        <span style={{ color: 'grey' }}>CHANGES TO THE APP</span>
        <p style={{ marginTop: '3px', fontSize: '0.9rem' }}>
            We reserve the right to modify, suspend, or discontinue any aspect of the App, including features, pricing, or content, at any time without prior notice. We are not liable for any interruptions or downtimes due to system maintenance, updates, or technical issues.
        </p>

        <span style={{ color: 'grey' }}>MISCELLANEOUS PROVISIONS</span>
        <p style={{ marginTop: '3px', fontSize: '0.9rem' }}>17.1 Severability</p>
        <p style={{ marginTop: '3px', fontSize: '0.9rem' }}>
            If any provision of these Terms and Conditions is found to be invalid or unenforceable, the remaining provisions will remain in full force and effect.
        </p>

        <p style={{ marginTop: '3px', fontSize: '0.9rem' }}>17.2 Waiver</p>
        <p style={{ marginTop: '3px', fontSize: '0.9rem' }}>
            No waiver of any term or condition shall be deemed a further or continuing waiver of that term or any other term. Any failure to enforce a provision of these Terms shall not constitute a waiver of our right to do so.
        </p>

        <p style={{ marginTop: '3px', fontSize: '0.9rem' }}>17.3 Entire Agreement</p>
        <p style={{ marginTop: '3px', fontSize: '0.9rem' }}>
            These Terms and Conditions, together with our Privacy Policy, constitute the entire agreement between you and MEDICAPP LLC regarding your use of the App and supersede any prior agreements or understandings.
        </p>

        <span style={{ color: 'grey' }}>CONTACT INFORMATION</span>
        <p style={{ marginTop: '3px', fontSize: '0.9rem' }}>
            If you have any questions or concerns regarding these Terms and Conditions, please contact us at:
        </p>
        <p style={{ marginTop: '3px', fontSize: '0.9rem' }}>
            MEDICAPP LLC<br />
            Email: <a href="mailto:support@medicappae.com">support@medicappae.com</a>
        </p>
        <p style={{ marginTop: '3px', fontSize: '0.9rem' }}>
            By using MEDICAPP, you acknowledge that you have read, understood, and agreed to these Terms and Conditions.
        </p>
    </div>
</section>
                </>
            ) : (
                <AppLayout>
                    <section class="search-block pt-4">
                        <div class="container">
                            <div class="row">
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <nav aria-label="breadcrumb">
                                        <ol class="breadcrumb">
                                            <li class="breadcrumb-item"><a href={href}>Home</a></li>
                                            <li class="breadcrumb-item active" aria-current="page"> Terms Of Use</li>
                                        </ol>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section class="user-dashboard pb-5">
    <div class="container">
        <h4 style={{ fontWeight: 'bold' }}>MEDICAPP L.L.C. Terms and Conditions</h4>
        <p style={{ marginTop: '-5px' }}>Last Updated: 24/09/2024</p>
        <hr />
        <p style={{ marginTop: '3px', fontSize: '0.9rem' }}>
            Welcome to MEDICAPP, a platform that connects patients with healthcare providers, including clinics, doctors, and technicians. By accessing or using MEDICAPP, you agree to abide by the following Terms and Conditions, which govern your use of the mobile application (the “App”) and its services. If you disagree with any part of these Terms and Conditions, you must discontinue the use of MEDICAPP immediately.
        </p>

        <span style={{ color: 'grey' }}>DEFINITIONS</span>
        <p style={{ marginTop: '3px', fontSize: '0.9rem' }}>
            For the purpose of these Terms and Conditions:
        </p>
        <ul style={{ fontSize: '0.9rem', marginTop: '3px' }}>
            <li><strong>“App”:</strong> Refers to the mobile application known as MEDICAPP, operated by MEDICAPP LLC, accessible via mobile devices.</li>
            <li><strong>“We,” “Us,” “Our”:</strong> Refers to MEDICAPP LLC, the legal entity responsible for developing, managing, and maintaining the App and its services.</li>
            <li><strong>“You,” “Your,” “User”:</strong> Refers to any individual or entity using the App, including but not limited to patients, healthcare providers, clinics, doctors, technicians, or other service providers.</li>
            <li><strong>“Service” or “Services”:</strong> Refers to the functionalities provided by the App, such as appointment booking, accessing medical records, viewing promotional videos, interacting with clinics, and participating in rewards programs.</li>
            <li><strong>“Healthcare Provider”:</strong> Refers to any clinic, doctor, nurse, technician, or medical institution offering medical services via MEDICAPP.</li>
            <li><strong>“Terms”:</strong> Refers to these Terms and Conditions.</li>
            <li><strong>“Content”:</strong> Refers to all text, images, audio, video, graphics, promotional materials, or other forms of media uploaded to the App by MEDICAPP LLC or its Users.</li>
            <li><strong>“Account”:</strong> Refers to your personal or organizational registration on the App.</li>
        </ul>

        <span style={{ color: 'grey' }}>AGREEMENT TO TERMS</span>
        <p style={{ marginTop: '3px', fontSize: '0.9rem' }}>
            By downloading, installing, or using the App, you agree to comply with and be bound by these Terms and any additional terms and conditions that may apply to specific services or features of the App. These Terms constitute a legally binding agreement between you and MEDICAPP LLC.
        </p>
        <p style={{ marginTop: '3px', fontSize: '0.9rem' }}>
            If you are using the App on behalf of a company or organization, you represent that you have the authority to bind that entity to these Terms.
        </p>

        <span style={{ color: 'grey' }}>MODIFICATIONS TO TERMS</span>
        <p style={{ marginTop: '3px', fontSize: '0.9rem' }}>
            We reserve the right to modify these Terms and Conditions at any time without prior notice. We will notify you of material changes either through the App, via email, or by other reasonable means. Any modifications will take effect immediately upon posting unless stated otherwise. Your continued use of the App after modifications are made constitutes your acceptance of the updated Terms. It is your responsibility to review these Terms periodically.
        </p>

        <span style={{ color: 'grey' }}>ELIGIBILITY TO USE</span>
        <p style={{ marginTop: '3px', fontSize: '0.9rem' }}>
            To use the App and access its features, you must meet the following eligibility criteria:
        </p>
        <ul style={{ fontSize: '0.9rem', marginTop: '3px' }}>
            <li><strong>Age Requirement:</strong> You must be at least 18 years old to use the App. Users under 18 must have explicit permission from a parent or legal guardian.</li>
            <li><strong>Legal Capacity:</strong> You must possess the legal capacity to enter into a binding contract under applicable law.</li>
            <li><strong>Geographical Availability:</strong> The App is accessible in various regions, but availability may vary depending on your geographical location and applicable healthcare laws. It is your responsibility to comply with local laws and regulations regarding the use of such services.</li>
        </ul>

        <p style={{ marginTop: '3px', fontSize: '0.9rem' }}>
            By using the App, you warrant that you meet all eligibility requirements.
        </p>

        <span style={{ color: 'grey' }}>ACCOUNT REGISTRATION AND SECURITY</span>
        <p style={{ marginTop: '3px', fontSize: '0.9rem' }}>5.1 Account Creation</p>
        <p style={{ marginTop: '3px', fontSize: '0.9rem' }}>
            In order to use certain features of the App, including booking appointments, accessing medical records, and redeeming rewards, you must register for an account. You agree to:
        </p>
        <ul style={{ fontSize: '0.9rem', marginTop: '3px' }}>
            <li>Provide accurate and up-to-date information during the registration process.</li>
            <li>Maintain the security of your login credentials and not share them with others.</li>
            <li>Notify us immediately of any unauthorized use or security breach of your account.</li>
            <li>Ensure that all information associated with your account, including your medical history and contact details, remains accurate and current.</li>
        </ul>
        <p style={{ marginTop: '3px', fontSize: '0.9rem' }}>
            We reserve the right to suspend or terminate your account at any time, especially if it is found that any information provided is false or misleading or if you violate these Terms.
        </p>

        <p style={{ marginTop: '3px', fontSize: '0.9rem' }}>5.2 Account Types</p>
        <p style={{ marginTop: '3px', fontSize: '0.9rem' }}>
            There are multiple account types on the App, including but not limited to:
        </p>
        <ul style={{ fontSize: '0.9rem', marginTop: '3px' }}>
            <li><strong>Patient Accounts:</strong> For individuals seeking to book medical appointments, view medical records, and access promotional content.</li>
            <li><strong>Clinic and Provider Accounts:</strong> For healthcare providers who wish to offer services through the platform, upload promotional content, and manage patient appointments.</li>
        </ul>
        <p style={{ marginTop: '3px', fontSize: '0.9rem' }}>
            Each type of account may have specific privileges and responsibilities based on the nature of services offered or consumed.
        </p>

        <p style={{ marginTop: '3px', fontSize: '0.9rem' }}>5.3 Account Termination</p>
        <p style={{ marginTop: '3px', fontSize: '0.9rem' }}>
            You may delete your account at any time by accessing the settings on the App. Upon account deletion:
        </p>
        <ul style={{ fontSize: '0.9rem', marginTop: '3px' }}>
            <li>All user-specific data, including appointment history, will be permanently deleted from our servers in accordance with our data retention policies.</li>
            <li>MEDICAPP LLC may retain anonymized data for analytical purposes, even after account termination.</li>
            <li>Any pending rewards or points will be forfeited.</li>
        </ul>
        <p style={{ marginTop: '3px', fontSize: '0.9rem' }}>
            We reserve the right to suspend or terminate your account if you fail to comply with any provision of these Terms, misuse the platform, or engage in fraudulent activities.
        </p>

        <span style={{ color: 'grey' }}>MEDICAPP SERVICES</span>
        <p style={{ marginTop: '3px', fontSize: '0.9rem' }}>6.1 Appointment Booking</p>
        <p style={{ marginTop: '3px', fontSize: '0.9rem' }}>
            MEDICAPP provides a platform for Users to book appointments with healthcare providers. By using the appointment booking service, you acknowledge that:
        </p>
        <ul style={{ fontSize: '0.9rem', marginTop: '3px' }}>
            <li>Appointments are subject to the availability of the selected healthcare provider and clinic.</li>
            <li>After an appointment is confirmed, a notification will be sent to your registered email or phone number. You are responsible for checking appointment confirmations and reminders.</li>
            <li>You must attend your booked appointments or cancel them in advance if you are unable to attend. Failure to cancel appointments in a timely manner may result in penalties, including account suspension or limitations.</li>
        </ul>
        <p style={{ marginTop: '3px', fontSize: '0.9rem' }}>
            MEDICAPP is a facilitator and is not responsible for the quality of medical services provided by healthcare providers.
        </p>

        <p style={{ marginTop: '3px', fontSize: '0.9rem' }}>6.2 Medical Record Access</p>
        <p style={{ marginTop: '3px', fontSize: '0.9rem' }}>
            Through the App, you may have access to your personal medical records, including but not limited to:
        </p>
        <ul style={{ fontSize: '0.9rem', marginTop: '3px' }}>
            <li>Sick Leave Certificates: Provided by healthcare providers if applicable.</li>
            <li>Prescriptions: Details of any prescriptions issued to you during your appointment.</li>
            <li>Test Results: Laboratory test results uploaded by the clinic or healthcare provider.</li>
            <li>Appointment Summaries: Summaries of your medical visits, including diagnostic details and treatment plans.</li>
        </ul>
        <p style={{ marginTop: '3px', fontSize: '0.9rem' }}>
            MEDICAPP does not guarantee the accuracy of these records and assumes no liability for errors or discrepancies. It is your responsibility to review your medical information and notify your healthcare provider of any errors.
        </p>

        <p style={{ marginTop: '3px', fontSize: '0.9rem' }}>6.3 Search Functionality</p>
        <p style={{ marginTop: '3px', fontSize: '0.9rem' }}>
            You may use the App’s search feature to:
        </p>
        <ul style={{ fontSize: '0.9rem', marginTop: '3px' }}>
            <li>Search by clinic, doctor, or service.</li>
            <li>View clinic pages, which include lists of healthcare providers and services offered.</li>
            <li>Book appointments directly with clinics or specific healthcare providers.</li>
        </ul>
        <p style={{ marginTop: '3px', fontSize: '0.9rem' }}>
            You agree that search results are influenced by availability, location, and other factors. MEDICAPP does not endorse any specific healthcare provider or service.
        </p>

        <p style={{ marginTop: '3px', fontSize: '0.9rem' }}>6.4 Promotional Clips and Videos</p>
        <p style={{ marginTop: '3px', fontSize: '0.9rem' }}>
            Clinics may upload promotional content, such as videos showcasing services or special offers, which you can view and interact with. By engaging with such content, you agree to:
        </p>
        <ul style={{ fontSize: '0.9rem', marginTop: '3px' }}>
            <li>Not misuse or manipulate the ratings or feedback system.</li>
            <li>Engage in a respectful manner, adhering to guidelines set forth by MEDICAPP regarding content interaction.</li>
        </ul>
        <p style={{ marginTop: '3px', fontSize: '0.9rem' }}>6.5 Family Account Management</p>
        <p style={{ marginTop: '3px', fontSize: '0.9rem' }}>
            The App allows you to manage bookings and medical profiles for family members under your account, subject to their consent. You are responsible for ensuring the accuracy of information entered on behalf of family members and for obtaining necessary legal consent where applicable.
        </p>

        <span style={{ color: 'grey' }}>REWARDS AND LOYALTY PROGRAM</span>
        <p style={{ marginTop: '3px', fontSize: '0.9rem' }}>
            MEDICAPP offers a points-based rewards program to incentivize engagement with the platform. By participating in the rewards program, you agree to the following:
        </p>
        <ul style={{ fontSize: '0.9rem', marginTop: '3px' }}>
            <li><strong>Point Accrual:</strong> You earn points by completing certain actions, such as attending medical appointments, interacting with promotional content, or referring new users to the platform.</li>
            <li><strong>Point Redemption:</strong> Points can be redeemed for rewards at participating entertainment venues, as displayed on the App. Each reward is subject to availability, and we reserve the right to modify or discontinue any reward without notice.</li>
            <li><strong>Expiration:</strong> Points may expire if unused within a specific time period, as detailed in the App.</li>
            <li><strong>Non-Transferability:</strong> Points are non-transferable and may not be sold, exchanged, or gifted to another account or individual.</li>
            <li><strong>Program Modifications:</strong> We reserve the right to modify or terminate the rewards program at any time, with or without notice. Changes to the program will be communicated through the App.</li>
        </ul>
        <p style={{ marginTop: '3px', fontSize: '0.9rem' }}>
            MEDICAPP LLC assumes no liability for disputes arising between users and third-party entertainment venues regarding the redemption of points.
        </p>

        <span style={{ color: 'grey' }}>RESPONSIBILITIES AND CONDUCT</span>
        <p style={{ marginTop: '3px', fontSize: '0.9rem' }}>8.1 User Conduct</p>
        <p style={{ marginTop: '3px', fontSize: '0.9rem' }}>
            By using the App, you agree not to engage in any of the following prohibited activities:
        </p>
        <ul style={{ fontSize: '0.9rem', marginTop: '3px' }}>
            <li><strong>Misrepresentation:</strong> Providing false or misleading information in any part of the App, including during account registration or appointment bookings.</li>
            <li><strong>Malicious Activities:</strong> Attempting to harm the App, its infrastructure, or other users through hacking, phishing, or the introduction of malicious code.</li>
            <li><strong>Harassment:</strong> Harassing, threatening, or abusing other users, healthcare providers, or staff members of MEDICAPP LLC.</li>
            <li><strong>Inappropriate Content:</strong> Uploading, distributing, or sharing any inappropriate, illegal, or offensive content.</li>
            <li><strong>Violating Third-Party Rights:</strong> Infringing on the intellectual property, privacy, or other legal rights of any third party.</li>
        </ul>
        <p style={{ marginTop: '3px', fontSize: '0.9rem' }}>
            MEDICAPP LLC reserves the right to monitor and review user activities and suspend or terminate accounts that violate these rules.
        </p>

        <p style={{ marginTop: '3px', fontSize: '0.9rem' }}>8.2 Responsibilities for Appointment Management</p>
        <ul style={{ fontSize: '0.9rem', marginTop: '3px' }}>
            <li><strong>Timely Attendance:</strong> You are responsible for attending your booked appointments at the scheduled time. Failing to show up for an appointment or canceling at the last minute may result in penalties, including suspension of booking privileges.</li>
            <li><strong>Cancellations:</strong> Cancellations must be made according to the healthcare provider’s policy. Any fees incurred due to late cancellations are the responsibility of the User.</li>
        </ul>

        <p style={{ marginTop: '3px', fontSize: '0.9rem' }}>8.3 Reviews and Feedback</p>
        <p style={{ marginTop: '3px', fontSize: '0.9rem' }}>
            You may provide reviews and feedback on healthcare providers following appointments. Reviews must:
        </p>
        <ul style={{ fontSize: '0.9rem', marginTop: '3px' }}>
            <li>Be based on your genuine experience.</li>
            <li>Not contain offensive language, false claims, or malicious content.</li>
            <li>Be respectful of the provider’s reputation.</li>
        </ul>
        <p style={{ marginTop: '3px', fontSize: '0.9rem' }}>
            MEDICAPP reserves the right to remove or moderate reviews that violate these guidelines.
        </p>

        <span style={{ color: 'grey' }}>HEALTHCARE PROVIDERS AND CLINICS</span>
        <p style={{ marginTop: '3px', fontSize: '0.9rem' }}>9.1 Independent Contractors</p>
        <p style={{ marginTop: '3px', fontSize: '0.9rem' }}>
            Healthcare providers available through MEDICAPP are independent contractors and not employees or affiliates of MEDICAPP LLC. The App acts solely as an intermediary between patients and healthcare providers.
        </p>

        <p style={{ marginTop: '3px', fontSize: '0.9rem' }}>9.2 Medical Services Disclaimer</p>
        <p style={{ marginTop: '3px', fontSize: '0.9rem' }}>
            MEDICAPP LLC does not provide medical services and is not responsible for the quality, accuracy, or outcome of any medical care or treatment provided by healthcare professionals through the App. You acknowledge that healthcare providers are responsible for their actions, including diagnosing conditions and offering treatments.
        </p>

        <p style={{ marginTop: '3px', fontSize: '0.9rem' }}>9.3 Provider Responsibilities</p>
        <ul style={{ fontSize: '0.9rem', marginTop: '3px' }}>
            <li>Ensuring that their profiles and information are accurate and up-to-date.</li>
            <li>Providing quality care to patients and adhering to relevant laws and regulations.</li>
            <li>Managing their appointment availability and ensuring that they honor booked appointments.</li>
        </ul>

        <span style={{ color: 'grey' }}>FEES AND PAYMENTS</span>
        <p style={{ marginTop: '3px', fontSize: '0.9rem' }}>10.1 Service Fees</p>
        <p style={{ marginTop: '3px', fontSize: '0.9rem' }}>
            MEDICAPP LLC may charge service fees for premium features or other services offered through the App. Any applicable fees will be clearly communicated at the point of purchase.
        </p>

        <p style={{ marginTop: '3px', fontSize: '0.9rem' }}>10.2 Payment Processing</p>
        <p style={{ marginTop: '3px', fontSize: '0.9rem' }}>
            Payments for appointments and services are processed through third-party payment providers. MEDICAPP LLC is not responsible for any issues arising from payment processing, including delays, errors, or refunds. You agree to abide by the payment terms set forth by the respective third-party payment processors.
        </p>

        <p style={{ marginTop: '3px', fontSize: '0.9rem' }}>10.3 Refunds</p>
        <p style={{ marginTop: '3px', fontSize: '0.9rem' }}>
            Refunds for appointments or services are subject to the policies of the respective healthcare provider. MEDICAPP LLC does not handle refunds directly and is not responsible for resolving disputes related to payments.
        </p>
        <span style={{ color: 'grey' }}>PRIVACY AND DATA SECURITY</span>
        <p style={{ marginTop: '3px', fontSize: '0.9rem' }}>
            Your privacy is of paramount importance to us. Please refer to our <a href="/privacy-policy">Privacy Policy</a> for detailed information on how we collect, use, and protect your personal data.
        </p>

        <p style={{ marginTop: '3px', fontSize: '0.9rem' }}>11.1 Data Collection</p>
        <p style={{ marginTop: '3px', fontSize: '0.9rem' }}>
            We collect personal data, such as your name, contact information, medical history, and appointment details, to provide our services. This information is stored securely and is only accessible by authorized personnel.
        </p>

        <p style={{ marginTop: '3px', fontSize: '0.9rem' }}>11.2 Data Use</p>
        <ul style={{ fontSize: '0.9rem', marginTop: '3px' }}>
            <li>Facilitating appointments and interactions with healthcare providers.</li>
            <li>Improving the App’s functionality and services.</li>
            <li>Marketing and promotional purposes, with your consent.</li>
        </ul>

        <p style={{ marginTop: '3px', fontSize: '0.9rem' }}>11.3 Data Sharing</p>
        <p style={{ marginTop: '3px', fontSize: '0.9rem' }}>
            We may share your data with healthcare providers, third-party partners, or as required by law. We ensure that all third parties comply with relevant data protection regulations.
        </p>

        <span style={{ color: 'grey' }}>INTELLECTUAL PROPERTY RIGHTS</span>
        <p style={{ marginTop: '3px', fontSize: '0.9rem' }}>
            All intellectual property rights, including trademarks, copyrights, patents, and proprietary rights, in and to the App are owned by MEDICAPP LLC or its licensors. You may not use, copy, modify, distribute, or reverse-engineer any part of the App without prior written consent from MEDICAPP LLC.
        </p>

        <p style={{ marginTop: '3px', fontSize: '0.9rem' }}>12.1 License to Use</p>
        <p style={{ marginTop: '3px', fontSize: '0.9rem' }}>
            We grant you a limited, non-exclusive, non-transferable, and revocable license to use the App solely for personal, non-commercial purposes, subject to these Terms and Conditions.
        </p>

        <span style={{ color: 'grey' }}>WARRANTIES AND DISCLAIMERS</span>
        <p style={{ marginTop: '3px', fontSize: '0.9rem' }}>13.1 No Warranty</p>
        <p style={{ marginTop: '3px', fontSize: '0.9rem' }}>
            MEDICAPP LLC provides the App on an “as-is” and “as-available” basis without any warranties of any kind, either express or implied. We make no representations or warranties regarding:
        </p>
        <ul style={{ fontSize: '0.9rem', marginTop: '3px' }}>
            <li>The availability, accuracy, or reliability of the App.</li>
            <li>The qualifications or quality of healthcare providers listed on the App.</li>
            <li>The outcomes or results of any medical treatments obtained through the App.</li>
        </ul>

        <p style={{ marginTop: '3px', fontSize: '0.9rem' }}>13.2 Limitation of Liability</p>
        <p style={{ marginTop: '3px', fontSize: '0.9rem' }}>
            To the fullest extent permitted by law, MEDICAPP LLC shall not be liable for any direct, indirect, incidental, special, or consequential damages arising from your use of the App or any services provided by healthcare providers. This includes, but is not limited to, loss of data, profits, goodwill, or reputation.
        </p>

        <span style={{ color: 'grey' }}>INDEMNIFICATION</span>
        <p style={{ marginTop: '3px', fontSize: '0.9rem' }}>
            You agree to indemnify, defend, and hold harmless MEDICAPP LLC and its officers, directors, employees, agents, and affiliates from any claims, damages, liabilities, costs, and expenses (including legal fees) arising from:
        </p>
        <ul style={{ fontSize: '0.9rem', marginTop: '3px' }}>
            <li>Your use or misuse of the App.</li>
            <li>Your violation of these Terms and Conditions.</li>
            <li>Any disputes or legal actions initiated by third parties due to your actions on the App.</li>
        </ul>
        <span style={{ color: 'grey' }}>GOVERNING LAW AND DISPUTE RESOLUTION</span>
        <p style={{ marginTop: '3px', fontSize: '0.9rem' }}>15.1 Governing Law</p>
        <p style={{ marginTop: '3px', fontSize: '0.9rem' }}>
            These Terms and Conditions are governed by the laws of the United Arab Emirates, without regard to its conflict of laws principles. Any disputes arising from or related to these Terms will be subject to the exclusive jurisdiction of the (Dubai International Arbitration Center).
        </p>

        <p style={{ marginTop: '3px', fontSize: '0.9rem' }}>15.2 Arbitration</p>
        <p style={{ marginTop: '3px', fontSize: '0.9rem' }}>
            Any dispute, claim, or controversy arising out of or relating to these Terms and Conditions or your use of the App shall be resolved through binding arbitration, in accordance with the rules of (Dubai International Arbitration Center).
        </p>

        <span style={{ color: 'grey' }}>CHANGES TO THE APP</span>
        <p style={{ marginTop: '3px', fontSize: '0.9rem' }}>
            We reserve the right to modify, suspend, or discontinue any aspect of the App, including features, pricing, or content, at any time without prior notice. We are not liable for any interruptions or downtimes due to system maintenance, updates, or technical issues.
        </p>

        <span style={{ color: 'grey' }}>MISCELLANEOUS PROVISIONS</span>
        <p style={{ marginTop: '3px', fontSize: '0.9rem' }}>17.1 Severability</p>
        <p style={{ marginTop: '3px', fontSize: '0.9rem' }}>
            If any provision of these Terms and Conditions is found to be invalid or unenforceable, the remaining provisions will remain in full force and effect.
        </p>

        <p style={{ marginTop: '3px', fontSize: '0.9rem' }}>17.2 Waiver</p>
        <p style={{ marginTop: '3px', fontSize: '0.9rem' }}>
            No waiver of any term or condition shall be deemed a further or continuing waiver of that term or any other term. Any failure to enforce a provision of these Terms shall not constitute a waiver of our right to do so.
        </p>

        <p style={{ marginTop: '3px', fontSize: '0.9rem' }}>17.3 Entire Agreement</p>
        <p style={{ marginTop: '3px', fontSize: '0.9rem' }}>
            These Terms and Conditions, together with our Privacy Policy, constitute the entire agreement between you and MEDICAPP LLC regarding your use of the App and supersede any prior agreements or understandings.
        </p>

        <span style={{ color: 'grey' }}>CONTACT INFORMATION</span>
        <p style={{ marginTop: '3px', fontSize: '0.9rem' }}>
            If you have any questions or concerns regarding these Terms and Conditions, please contact us at:
        </p>
        <p style={{ marginTop: '3px', fontSize: '0.9rem' }}>
            MEDICAPP LLC<br />
            Email: <a href="mailto:support@medicappae.com">support@medicappae.com</a>
        </p>
        <p style={{ marginTop: '3px', fontSize: '0.9rem' }}>
        By using MEDICAPP, you acknowledge that you have read, understood, and agreed to these Terms and Conditions.
        </p>
    </div>
</section>
                </AppLayout>
            )}
        </>
    )
}

export default TermsOfUse

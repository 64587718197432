import axios from '../../axios';
import { NURSE_REPORT_NAMESPACE } from '../../constants/namespaces';
import { trackPromise} from 'react-promise-tracker';

const NurseReportApi = {
    getAllNursesReports(date, nurseId) {
        return trackPromise(axios.post(`/${NURSE_REPORT_NAMESPACE}/getAll`, { date, nurseId }));
    },
    createNurseReport(data) {
        return trackPromise(axios.post(`${NURSE_REPORT_NAMESPACE}`, data));
    },
    deleteNurseReport(id){
        return trackPromise(axios.delete(`${NURSE_REPORT_NAMESPACE}/${id}`));
    },
    getSingleNurseReport(id) {
        return trackPromise(axios.get(`${NURSE_REPORT_NAMESPACE}/${id}`));
    },
    getAppointmentNurseReport(appointmentId) {
        return trackPromise(axios.get(`${NURSE_REPORT_NAMESPACE}/diagnosis/${appointmentId}`));
    },
    updateNurseReport(report_id, data) {
        return trackPromise(axios.put(`${NURSE_REPORT_NAMESPACE}/${report_id}`, data));
    },
}

export default NurseReportApi;
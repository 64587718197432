import React, { useRef } from 'react';
import './index.css';
import { useTranslation } from "react-i18next";
import EMAIL_IMG from "../../../../assets/images/email.png";
import { useHistory } from 'react-router-dom';
import { LOGIN_ROUTE } from '../../../../constants/Redirects';
import AuthApi from '../../../../api/Auth';
import { toast } from "react-toastify";

function ConfirmationModal({ email }) {
    const { t } = useTranslation();
    const history = useHistory();
    const btnCloseRef = useRef();

    const resendVerificationEmail = () => {
        AuthApi.resendVerificationEmail(email);
        toast.success("Email sent to your account, please open your inbox to get confirmed");
    }

    return (
        <div className="modal fade" id="confirmation-modal" tabIndex="-1" aria-labelledby="confirmation-modalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" style={{ minWidth: '50rem' }}>
                <div className="modal-content">
                    <button type="button" style={{ visibility: 'hidden' }} ref={btnCloseRef} onClick={() => history.push(LOGIN_ROUTE)} className="close" data-dismiss="modal" aria-label="Close">
                        <span className="icon-close"></span>
                    </button>
                    <div className="modal-body text-center">
                        <img src={EMAIL_IMG} alt="Email Confirmation" className="confirmation-image" />
                        <h4 className="confirm-text-center mt-4">{t("Email Confirmation")}</h4>
                        <p className="confirm-text-center mt-2">
                            {t("We have sent an email to")} <strong>{email && email}</strong> {t("to confirm the validity of your email address.")} <br />
                            {t("After receiving the email, follow the link provided to complete your registration.")}
                        </p>
                        <p className="confirm-text-center mt-3">
                            {t("If you did not receive the email")} <a href="#" onClick={resendVerificationEmail} className="resend-link">{t("Resend confirmation Email")}</a>
                        </p>
                        <p className="confirm-text-center mt-3">
                            <a href="#" onClick={() => btnCloseRef.current.click()} className="resend-link">{t("Login Now")}</a>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ConfirmationModal;

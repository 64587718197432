import moment from 'moment';
import React from 'react';
import { href } from '../../../../constants/extra';
import CreateSickLeave from './CreateSickLeave';

function AppointmentDetails({ selectedSlot }) {

    console.log("selected Slot => ", selectedSlot);

    return (
        <>
            <div className="modal fade" id="appointmentDetails" tabIndex="-1" aria-labelledby="appointmentDetailsLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-lg" style={{ maxWidth: '90%' }}>
                    <div className="modal-content">
                        <div className="modal-body">
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span className="icon-close"></span>
                            </button>
                            <h4 className="text-center">Appointment Details</h4>
                            <div style={{ textAlign: 'center' }}>
                                <table style={{ border: '1px solid gray', padding: '7px', width: '100%' }}>
                                    <thead style={{ border: '1px solid gray', padding: '7px' }}>
                                        <tr style={{ border: '1px solid gray', padding: '7px' }}>
                                            <td style={{ border: '1px solid gray', padding: '7px', fontWeight: 'bold' }}>Patient Name</td>
                                            <td style={{ border: '1px solid gray', padding: '7px', fontWeight: 'bold' }}>Date</td>
                                            <td style={{ border: '1px solid gray', padding: '7px', fontWeight: 'bold' }}>Time</td>
                                            <td style={{ border: '1px solid gray', padding: '7px', fontWeight: 'bold' }}>Family Member</td>
                                            <td style={{ border: '1px solid gray', padding: '7px', fontWeight: 'bold' }}>Service</td>
                                            <td style={{ border: '1px solid gray', padding: '7px', fontWeight: 'bold' }}>Description</td>
                                        </tr>
                                    </thead>
                                    <tbody style={{ border: '1px solid gray', padding: '7px' }}>
                                        <tr style={{ border: '1px solid gray', padding: '7px' }}>
                                            <td style={{ border: '1px solid gray', padding: '7px' }}>
                                                {selectedSlot?.patientId?.firstName + " " + selectedSlot?.patientId?.lastName}
                                            </td>
                                            <td style={{ border: '1px solid gray', padding: '7px' }}>
                                                {moment(selectedSlot?.from).format("DD-MM-YY")}
                                            </td>
                                            <td style={{ border: '1px solid gray', padding: '7px' }}>
                                                {moment(selectedSlot?.from).format('hh:mm a')} - {moment(selectedSlot?.to).format('hh:mm a')}
                                            </td>
                                            <td style={{ border: '1px solid gray', padding: '7px' }}>
                                                {selectedSlot?.familyMembers
                                                    ? selectedSlot?.familyMembers.firstName + " " + selectedSlot?.familyMembers.lastName + " ( " + selectedSlot?.familyMembers.relation + " ) "
                                                    : "No ( Patient's Own )"}
                                            </td>
                                            <td style={{ border: '1px solid gray', padding: '7px' }}>
                                                {selectedSlot?.serviceId?.name || "N/A"}
                                            </td>
                                            <td style={{ border: '1px solid gray', padding: '7px' }}>
                                                {selectedSlot?.description}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        {/* <div className="form-group text-center mb-0">
                            <a href={href} data-toggle="modal" data-target="#sickLeave" class="btn btn-primary px-3 mb-4">Approve Sick Leave</a>
                        </div> */}
                    </div>
                </div>
            </div>
            {/* <CreateSickLeave patientId={selectedSlot?.patientId?._id} /> */}
        </>
    );
}

export default AppointmentDetails;

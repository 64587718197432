import { Switch, withRouter } from "react-router-dom";
import ReceptionistRoute from "../../ProtectedRoutes/ReceptionistRoute";
import Doctors from "./Doctors";
import DoctorInfo from "./Doctors/components/DoctorInfo";
import Nurse from "./Nurse";
import ReceptionistProfile from "./Profile";
import Promos from "./Promos";
import NurseInfo from "./Nurse/components/NurseInfo";
import Appointments from "./Appointments";
import Packages from "./Offers";
import PatientPromoCode from "./PatientPromoCode";
import ClaimedOffers from "./ClaimedOffers";

const ReceptionistRouter = withRouter(({ match, ...props }) => {
    return (
        <Switch {...props}>
            <ReceptionistRoute exact path={`${match.path}`} >
                <Appointments />
            </ReceptionistRoute>
            <ReceptionistRoute exact path={`${match.path}/doctors`}>
                <Doctors />
            </ReceptionistRoute>
            <ReceptionistRoute exact path={`${match.path}/nurse`}>
                <Nurse />
            </ReceptionistRoute>
            <ReceptionistRoute exact path={`${match.path}/promos`}>
                <Promos />
            </ReceptionistRoute>
            <ReceptionistRoute exact path={`${match.path}/profile`} >
                <ReceptionistProfile />
            </ReceptionistRoute>
            <ReceptionistRoute exact path={`${match.path}/patient-promo-codes`} >
                <PatientPromoCode />
            </ReceptionistRoute>
            <ReceptionistRoute exact path={`${match.path}/doctor-info/:id`} >
                <DoctorInfo />
            </ReceptionistRoute>
            <ReceptionistRoute exact path={`${match.path}/nurse-info/:id`} >
                <NurseInfo />
            </ReceptionistRoute>
            <ReceptionistRoute exact path={`${match.path}/offers`} >
                <Packages />
            </ReceptionistRoute>
            <ReceptionistRoute exact path={`${match.path}/claimed-offers`} >
                <ClaimedOffers />
            </ReceptionistRoute>
        </Switch>
    )
});

export default ReceptionistRouter;

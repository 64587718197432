import React, { useEffect, useState } from 'react'
import DOCTOR_IMAGE from '../../../../assets/images/doctor.png'
import { connect } from 'react-redux'
import { getAllNurses } from '../../../../store/actions/nurseActions'
import Card from '../../../../components/Card'
import { FadeLoading } from '../../../../loaders'
import { useHistory } from 'react-router-dom'
import { NURSE_INFO_ROUTE } from '../../../../constants/Redirects'

function DoctorsList({ nurses, getAllNurses }) {
    const { totalNurses: allNurses } = nurses && nurses
    const [loading, setLoading] = useState(true);
    const history = useHistory();

    useEffect(() => {
        getNursesList(0, true)
    }, [getAllNurses]);

    const getNursesList = async () => {
        try {
            await getAllNurses(0, true);
            setLoading(false);
        } catch (err) {
            setLoading(false);
        }
    }

    return (
        <div className="horizontal-scroll-container">
                {loading && allNurses?.length === 0 && <FadeLoading />} 
                {allNurses?.map(doc => (
                    <Card 
                        picture={doc?.image ? doc?.image : DOCTOR_IMAGE}
                        heading={doc.firstName + "  " + doc.lastName}
                        text={doc.mobile}
                        onClick={() => history.push(NURSE_INFO_ROUTE + `/${doc?._id}`)}
                    />
                ))}
                {!loading && allNurses?.length === 0 && (
                    <div class="col-md-6">
                        <p>No doctors found</p>
                    </div>
                )}
        </div>
    )
}

const mapStateToProps = (state) => ({
    nurses: state.nurses
})

const mapDispatchToProps = {
    getAllNurses
}

export default connect(mapStateToProps, mapDispatchToProps)(DoctorsList)
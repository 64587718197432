import React, { useState } from 'react';
import DOCTOR_IMAGE from '../../../../assets/images/doctor_placeholder.png';
import moment from 'moment';
import CancelAppointment from './CancelAppointment';
import { href } from '../../../../constants/extra';
import SlotApi from '../../../../api/Slots';
import { saveAs } from 'file-saver';
import { useTranslation } from "react-i18next";
import VerifyCode from './VerifyCode';
import { BeatLoader } from 'react-spinners';

function Appointments({ upcommingAppointments, prevAppointments }) {

    const [loading, setLoading] = useState(false);
    const [loadingId, setLoadingId] = useState(false);
    const [selectedAppointment, setSelectedAppointment] = useState(null);
    const { t } = useTranslation();

    const generateAppointmentSlip = (id) => {
        setLoading(true);
        setLoadingId(id);
        SlotApi.getAppointmentSlip(id).then(res => {
            const pdfBlob = new Blob([res.data], { type: 'application/pdf' });
            saveAs(pdfBlob, 'Appointment Slip.pdf');
        }).finally(() => {
            setLoading(false);
        });
    };

    return (
        <>
            <section className="user-dashboard">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-12 col-xl-12 pb-5">
                            <h4 className="mb-4">{t("upcoming")}</h4>
                            {upcommingAppointments?.map((item, index) => (
                                <div key={index} className="card lab-result" style={{ position: 'relative', marginBottom: '1.5rem' }}>
                                    {item?.serviceId && (
                                        <div
                                            style={{
                                                position: "absolute",
                                                top: "-20px",
                                                left: "50%",
                                                transform: "translateX(-50%)",
                                                backgroundColor: "#5a9bd4",
                                                color: "white",
                                                padding: "5px 10px",
                                                borderRadius: "5px",
                                                fontSize: "0.8rem",
                                                fontWeight: "bold",
                                                textAlign: "center",
                                            }}
                                        >
                                            {t("Service Booked")}
                                        </div>
                                    )}
                                    <div className="card-body py-2">
                                        <div className="row align-items-center">
                                            <div className="col-md-12 col-lg-12">
                                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                    <div>
                                                        <small className="d-block">{t("date")} & {t("time")}</small>
                                                        {`${moment(item.from).format("DD-MM-YY")} - ( ${moment(item.from).format('hh:mm a')} - ${moment(item.to).format('hh:mm a')} )`}
                                                    </div>
                                                    <div>
                                                        <li className="media">
                                                            {item.doctorId ? (
                                                                <>
                                                                    <img className="avatar-sm" src={item?.doctorId?.image || DOCTOR_IMAGE} alt="doctor" />
                                                                    <div className="media-body ml-2">
                                                                        <small className="d-block">Dr. {`${item.doctorId.firstName} ${item.doctorId.lastName}`}</small>
                                                                        <p>{item.doctorId?.specialityId?.name}</p>
                                                                    </div>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <div className="media-body ml-2">
                                                                        <small className="d-block">{t("appointment_type")}</small>
                                                                        <p>{item?.type.replace(/_/g, ' ')}</p>
                                                                    </div>
                                                                </>
                                                            )}
                                                        </li>
                                                    </div>
                                                    <div>
                                                        <small className="d-block">{t("hospital")}</small>
                                                        {item?.doctorId ? (
                                                            item?.doctorId?.hospitalId?.name
                                                        ) : (
                                                            item?.hospitalId?.name
                                                        )}
                                                    </div>
                                                    <div>
                                                        <small className="d-block">{t("family")}</small>
                                                        {item?.familyMemberId ? `${item?.familyMemberId?.firstName} ${item?.familyMemberId.lastName}` : t("mine")}
                                                    </div>
                                                    <div>
                                                        <a style={{ marginRight: '1rem' }} href={href} onClick={(e) => { e.preventDefault(); generateAppointmentSlip(item._id) }} className="btn btn-secondary px-3">
                                                            { loading && loadingId === item?._id ? (
                                                                <>
                                                                    <BeatLoader size={8} />
                                                                </>
                                                            ) : (
                                                                <>{t("slip")}</>
                                                            )}
                                                        </a>
                                                        <a style={{ marginRight: '1rem' }} href="javascript:void(0)" onClick={(e) => { e.preventDefault(); setSelectedAppointment(item) }} data-toggle="modal" data-target="#verifyCode" className="btn btn-primary px-3">{t("GET_POINTS")}</a>
                                                        <a href="javascript:void(0)" onClick={(e) => { e.preventDefault(); setSelectedAppointment(item) }} data-toggle="modal" data-target="#cancel" className="btn btn-danger px-3">{t("CANCEL")}</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                            {upcommingAppointments.length === 0 && (
                                <div className="card lab-result">
                                    <div className="card-body py-2">
                                        <div className="row align-items-center">
                                            <div className="col-md-12 col-lg-8">
                                                <ul>
                                                    <li>{t("no_upcoming_appointments")}</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                            <h4 className="mb-4 mt-5">{t("history")}</h4>
                            {prevAppointments.map((item, index) => (
                                <div key={index} className="card lab-result" style={{ position: 'relative', marginBottom: '1rem' }}>
                                    {item?.serviceId && (
                                        <div
                                            style={{
                                                position: "absolute",
                                                top: "-10px",
                                                left: "50%",
                                                transform: "translateX(-50%)",
                                                backgroundColor: "#5a9bd4",
                                                color: "white",
                                                padding: "5px 10px",
                                                borderRadius: "5px",
                                                fontSize: "0.8rem",
                                                fontWeight: "bold",
                                                textAlign: "center",
                                            }}
                                        >
                                            {t("Service Booked")}
                                        </div>
                                    )}
                                    <div className="card-body py-2">
                                        <div className="row align-items-center">
                                            <div className="col-md-12 col-lg-12">
                                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                    <div>
                                                        <small className="d-block">{t("date")} & {t("time")}</small>
                                                        {`${moment(item.from).format("DD-MM-YY")} - ( ${moment(item.from).format('hh:mm a')} - ${moment(item.to).format('hh:mm a')} )`}
                                                    </div>
                                                    <div>
                                                        <li className="media">
                                                            {item.doctorId ? (
                                                                <>
                                                                    <img className="avatar-sm" src={item?.doctorId?.image || DOCTOR_IMAGE} alt="doctor" />
                                                                    <div className="media-body ml-2">
                                                                        <small className="d-block">Dr. {`${item.doctorId.firstName} ${item.doctorId.lastName}`}</small>
                                                                        <p>{item.doctorId?.specialityId?.name}</p>
                                                                    </div>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <div className="media-body ml-2">
                                                                        <small className="d-block">{t("appointment_type")}</small>
                                                                        <p>{item?.type.replace(/_/g, ' ')}</p>
                                                                    </div>
                                                                </>
                                                            )}
                                                        </li>
                                                    </div>
                                                    <div>
                                                        <small className="d-block">{t("hospital")}</small>
                                                        {item?.doctorId ? (
                                                            item?.doctorId?.hospitalId?.name
                                                        ) : (
                                                            item?.hospitalId?.name
                                                        )}
                                                    </div>
                                                    <div>
                                                        <small className="d-block">{t("family")}</small>
                                                        {item?.familyMemberId ? `${item?.familyMemberId?.firstName} ${item?.familyMemberId.lastName}` : t("mine")}
                                                    </div>
                                                    <div>
                                                        <a style={{ marginRight: '0.5rem' }} href={href} onClick={(e) => { e.preventDefault(); generateAppointmentSlip(item._id) }} className="btn btn-secondary px-3">{t("slip")}</a>
                                                        <a style={{ marginRight: '0.5rem' }} href="javascript:void(0)" onClick={(e) => { e.preventDefault(); setSelectedAppointment(item) }} data-toggle="modal" data-target="#verifyCode" className="btn btn-primary px-3">{t("GET_POINTS")}</a>
                                                        <a href="javascript:void(0)" onClick={(e) => { e.preventDefault(); setSelectedAppointment(item) }} data-toggle="modal" data-target="#cancel" className="btn btn-danger px-3">{t("DELETE")}</a>  
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                            {prevAppointments.length === 0 && (
                                <div className="card lab-result">
                                    <div className="card-body py-2">
                                        <div className="row align-items-center">
                                            <div className="col-md-12 col-lg-8">
                                                <ul>
                                                    <li>{t("no_history_present")}</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <CancelAppointment selectedAppointment={selectedAppointment} />
                <VerifyCode selectedAppointment={selectedAppointment} />
            </section>
        </>
    );
}

export default Appointments;

export const DOCTOR = "DOCTOR";
export const HOSPITAL = "HOSPITAL";
export const NURSE = "NURSE";
export const LABORTORY = "LABORTORY";
export const PHARMACY = "PHARMACY";
export const ADMIN = "ADMIN";
export const VENDOR = "VENDOR";
export const PATIENT = "PATIENT";
export const RECEPTIONIST = "RECEPTIONIST";

// Hospital Types
export const CLINIC = "CLINIC";

// GENDER CONSTANTS
export const MALE = "MALE";
export const FEMALE = "FEMALE";
export const OTHER = "OTHER";

import axios from '../../../axios';
import { DOCTOR_REPORT_NAMESPACE } from '../../../constants/namespaces';  // Ensure you have this constant defined
import { trackPromise } from 'react-promise-tracker';

const DoctorReportApi = {
    getAllDoctorReports(date, doctorId) {
        return trackPromise(axios.post(`/${DOCTOR_REPORT_NAMESPACE}/getAll`, { date, doctorId }));
    },
    createDoctorReport(data) {
        return trackPromise(axios.post(`/${DOCTOR_REPORT_NAMESPACE}`, data));
    },
    deleteDoctorReport(id) {
        return trackPromise(axios.delete(`${DOCTOR_REPORT_NAMESPACE}/${id}`));
    },
    getSingleDoctorReport(id) {
        return trackPromise(axios.get(`${DOCTOR_REPORT_NAMESPACE}/${id}`));
    },
    getAppointmentDoctorReport(appointmentId) {
        return trackPromise(axios.get(`${DOCTOR_REPORT_NAMESPACE}/appointment/${appointmentId}`));
    },
    getDoctorReportPDF(appointmentId) {
        return trackPromise(axios.get(`${DOCTOR_REPORT_NAMESPACE}/report/${appointmentId}`));
    },
    updateDoctorReport(report_id, data) {
        return trackPromise(axios.put(`${DOCTOR_REPORT_NAMESPACE}/${report_id}`, data));
    },
    getPatientPreviousReports(patientId) {
        return trackPromise(axios.get(`${DOCTOR_REPORT_NAMESPACE}/patient/${patientId}`));
    }
}

export default DoctorReportApi;

import React, { useEffect, useState } from "react";
import { Formik, Form, FieldArray } from "formik";
import * as Yup from "yup";
import DoctorReportApi from "../../../api/Doctors/DoctorReports"; // Use the correct path for your API file
import { BeatLoader } from "react-spinners";
import { FadeLoading } from "../../../loaders";
import { toast } from "react-toastify";
import TextArea from "../../forms/TextArea";
import TextInput from "../../forms/TextInput";
import "../index.css";
import { formatDateForInput } from "../../../Utills/functions";
import { href } from "../../../constants/extra";
import SickLeave from "./SickLeave";
import RequestToLab from "./RequestToLab";
import AppointmentApi from "../../../api/Appointment";
import { SlotStatus } from "../../../constants/Slots";
import { useHistory } from 'react-router-dom';
import { useConfirmationDialog } from "../../../contextApi/ConfirmationDialogContext";

function DoctorDiagnosis({ isNurse, appointment, doctorId }) {
  const [existingReport, setExistingReport] = useState({});
  const [loading, setLoading] = useState(false);
  const [loadingData, setLoadingData] = useState(true);
  const [loadingEndTreatment, setLoadingEndTreatment] = useState(false);
	const { showConfirmation } = useConfirmationDialog();

  const history = useHistory();

  useEffect(() => {
    getExistingDiagnosisReport();
  }, [doctorId, appointment?._id]);

  const endTreatmentHandler = () => {
    showConfirmation('Are you sure you want to end the Treatment?', async () => {
      setLoadingEndTreatment(true);
      try {
        await AppointmentApi.updateAppointment(appointment?._id, { status: SlotStatus.TAKEN });
        toast.success("Appointment Ended !");
        history.push('/doctors/appointment');
      } catch (error) {} finally {
        setLoadingEndTreatment(false);
      }
    });
  }

  const getExistingDiagnosisReport = async () => {
    if (appointment?._id) {
      try {
        setLoadingData(true);
        const doctorReport = await DoctorReportApi.getAppointmentDoctorReport(
          appointment?._id
        );
        setExistingReport(doctorReport?.data?.data?.[0]);
        setLoadingData(false);
      } catch (err) {
        setExistingReport({});
        setLoadingData(false);
      }
    }
  };

  return (
    <>
        <Formik
        initialValues={{
          treatmentType: existingReport?.treatmentType || "",
          description: existingReport?.description || "",
          medicines: existingReport?.medicines || [
            { medicine: "", dosage: "", days: "" },
          ],
          requiresSurgery: existingReport?.requiresSurgery ?? false, // Default to No (false)
          dateOfSurgery: formatDateForInput(existingReport?.dateOfSurgery) || "",
        }}
        validationSchema={Yup.object({
          treatmentType: Yup.string().required("Treatment Type is required"),
          description: Yup.string().required("Description is required"),
          medicines: Yup.array().of(
            Yup.object().shape({
              medicine: Yup.string().required("Medicine is required"),
              dosage: Yup.number()
                .positive("Dosage must be a positive number")
                .required("Dosage is required"),
              days: Yup.number()
                .positive("Number of days must be a positive number")
                .required("Number of days is required"),
            })
          ),
          requiresSurgery: Yup.boolean().required(),
          dateOfSurgery: Yup.date().when("requiresSurgery", {
            is: true,
            then: Yup.date().required("Date of Surgery is required"),
          }),
        })}
        onSubmit={(values, { resetForm }) => {
          showConfirmation('Are you sure you want to save?', async () => {
            try {
              setLoading(true);
              if (existingReport && Object.keys(existingReport).length > 0) {
                await DoctorReportApi.updateDoctorReport(existingReport?._id, {
                  ...values,
                  appointmentId: appointment._id,
                  patientId: appointment.patientId?._id,
                  doctorId: appointment.doctorId?._id,
                  hospitalId: appointment.hospitalId,
                });
                toast.success("Report Updated Successfully");
              } else {
                const response = await DoctorReportApi.createDoctorReport({
                  ...values,
                  appointmentId: appointment._id,
                  patientId: appointment.patientId?._id,
                  doctorId: appointment.doctorId?._id,
                  hospitalId: appointment.hospitalId,
                });
                setExistingReport(response.data.data);
                toast.success("Report Generated Successfully");
              }
              setLoading(false);
            } catch (e) {
              setLoading(false);
              toast.error("An error occurred. Please try again.");
            }
          });
        }}
        enableReinitialize={true}
      >
        {({ values, setFieldValue }) => (
          <Form>
            <div
              className="diagnosis-section"
              style={isNurse ? { pointerEvents: "none", opacity: "0.5" } : {}}
            >
              <label>
                <strong>
                  Doctor Diagnosis {loadingData && "( Loading... )"}
                </strong>
              </label>
              {loadingData ? (
                <FadeLoading />
              ) : (
                <>
                  <div className="other-top-section-2">
                    <a href={href} data-toggle="modal" data-target="#sickLeave" className="btn btn-primary px-3 mx-2">Sick Leave</a>
                    <a href={href} data-toggle="modal" data-target="#requestLaboratory" className="btn btn-primary px-3 mx-2">Request Laboratory</a>
                  </div>
                  <label>
                    <strong>Prescription</strong>
                  </label>
                  <div>
                    <label>Treatment Type</label>
                    <TextInput
                      className="input"
                      type="text"
                      name="treatmentType"
                      placeholder="Treatment Type"
                    />
                  </div>
                  <FieldArray
                    name="medicines"
                    render={(arrayHelpers) => (
                      <div>
                        <label>Medications</label>
                        {values.medicines.map((medicine, index) => (
                          <div className="medicine-fields" key={index}>
                            <div className="medicine-input">
                              <TextInput
                                className="input"
                                type="text"
                                name={`medicines[${index}].medicine`}
                                placeholder="Medicine"
                              />
                            </div>
                            <div className="medicine-input">
                              <TextInput
                                className="input"
                                type="number"
                                name={`medicines[${index}].dosage`}
                                placeholder="Dosage a day"
                              />
                            </div>
                            <div className="medicine-input">
                              <TextInput
                                className="input"
                                type="number"
                                name={`medicines[${index}].days`}
                                placeholder="Number of days"
                              />
                            </div>
                            <div className="plus-icon">
                              {index !== values.medicines.length - 1 &&
                                values.medicines.length > 1 && (
                                  <span
                                    className="fa fa-minus add-field"
                                    onClick={() => arrayHelpers.remove(index)}
                                  ></span>
                                )}
                              {index === values.medicines.length - 1 && (
                                <span
                                  className="fa fa-plus add-field"
                                  onClick={() =>
                                    arrayHelpers.push({
                                      medicine: "",
                                      dosage: "",
                                      days: "",
                                    })
                                  }
                                ></span>
                              )}
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  />
                  <div className="form-group">
                    <label>Does the issue require surgery?</label>
                    <div className="new-custom-checkbox-container">
                      <label className="new-custom-checkbox">
                        <input
                          type="radio"
                          name="requiresSurgery"
                          value="false"
                          checked={!values.requiresSurgery}
                          onChange={() => setFieldValue("requiresSurgery", false)}
                        />
                        <span className="new-custom-checkbox-mark"></span>
                        No
                      </label>
                      <label className="new-custom-checkbox">
                        <input
                          type="radio"
                          name="requiresSurgery"
                          value="true"
                          checked={values.requiresSurgery}
                          onChange={() => setFieldValue("requiresSurgery", true)}
                        />
                        <span className="new-custom-checkbox-mark"></span>
                        Yes
                      </label>
                    </div>
                    {values.requiresSurgery && (
                      <>
                        <label>Date of Surgery</label>
                        <TextInput
                          className="input"
                          type="date"
                          name="dateOfSurgery"
                          placeholder="Surgery date"
                        />
                      </>
                    )}
                  </div>
                  <label>Key Notes</label>
                  <TextArea
                    type="description"
                    name="description"
                    placeholder="Description"
                    rows={8}
                    className="textarea"
                  />
                  <div style={{ textAlign: 'center' }}>
                    <button disabled={loading} type="submit" className="btn btn-primary">
                      {loading ? (
                        <BeatLoader size={8} />
                      ) : (
                        <>
                          Save
                        </>
                      )}
                    </button>
                    <button type="button" style={{ float: 'right' }} className="btn btn-danger px-3 ml-2" onClick={endTreatmentHandler}>
                      {loadingEndTreatment ? <BeatLoader size={8} color="#fff" /> : 'End Treatment'}
                    </button>
                  </div>
                </>
              )}
            </div>
          </Form>
        )}
      </Formik>
      <SickLeave patientId={appointment?.patientId?._id} appointmentId={appointment?._id} />
      <RequestToLab appointment={appointment} />
    </>
  );
}

export default DoctorDiagnosis;

import { useHistory } from 'react-router-dom';
import PLACEHOLDER_IMAGE from '../../../../assets/images/medeor_logo.png';
import { useTranslation } from 'react-i18next';
import { useRef } from 'react';

function ServiceProvidersModal({ service }) {
    const { t } = useTranslation();
    const history = useHistory();
    const closeBtnRef = useRef();

    const allProviders = [
        ...service.doctors.map(doctor => ({ ...doctor, role: 'Doctor' })),
        ...service.nurses.map(nurse => ({ ...nurse, role: 'Nurse' })),
        ...service.technicians.map(technician => ({ ...technician, role: 'Technician' })),
    ];

    return (
        <div className="modal fade" id="serviceProvidersModal" tabIndex="-1" aria-labelledby="serviceProvidersModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-xl">
                <div className="modal-content">
                    <div className="modal-body">
                        <button ref={closeBtnRef} type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span className="icon-close"></span>
                        </button>
                        <h4 className="text-center">{t("Select a Provider for")} {service.name}</h4>
                        <div className="row mt-4">
                            {allProviders.length > 0 ? allProviders.map((provider, index) => (
                                <div className="col-md-4 mb-3" key={index}>
                                    <div className="card provider-card">
                                        <img src={provider.image || PLACEHOLDER_IMAGE} className="card-img-top" alt={provider.role} />
                                        <div className="card-body text-center">
                                            <span className={`badge badge-${provider.role === 'Doctor' ? 'primary' : provider.role === 'Nurse' ? 'info' : 'success'} mb-2`}>
                                                {provider.role}
                                            </span>
                                            <h5 className="card-title">{provider.firstName} {provider.lastName}</h5>
                                            {provider.role === 'Doctor' ? (
                                                <div className="specialities-chips">
                                                    {provider.specialityId?.slice(0, 2).map(speciality => (
                                                        <span key={speciality._id} className="badge badge-light speciality-chip">
                                                            {speciality.name_en}
                                                        </span>
                                                    ))}
                                                    {provider.specialityId?.length > 2 && (
                                                        <span className="badge badge-secondary speciality-chip">+{provider.specialityId.length - 2}</span>
                                                    )}
                                                </div>
                                            ) : (
                                                <div className="specialities-chips">
                                                    <span className="badge badge-light speciality-chip">{provider.speciality}</span>
                                                </div>
                                            )}
                                            
                                            <button
                                                className="btn btn-primary btn-block"
                                                onClick={() => {
                                                    localStorage.setItem("SELECTED_SERVICE_AND_PROVIDER", JSON.stringify({ ...provider, serviceId: service._id, role: provider.role }));
                                                    history.push("/book-service-appointment");
                                                    closeBtnRef.current.click();
                                                }}
                                            >
                                                {t("Select")}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            )) : (
                                <p className="text-center w-100">{t("No Providers Available")}</p>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ServiceProvidersModal;

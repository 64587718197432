import React from "react";
import moment from "moment";
import { FadeLoading } from "../../../loaders";

function PreviousPrescriptions({ data, previousDataLoading }) {
  return (
    <div
      className="modal fade"
      id="previousPrescription"
      tabIndex="-1"
      aria-labelledby="previousPrescriptionLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered modal-lg">
        <div className="modal-content">
          <div className="modal-body">
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span className="icon-close"></span>
            </button>
            <h4 className="text-center mb-4">Previous Prescriptions</h4>

            {previousDataLoading && (
              <div style={{ width: "100%", marginTop: "5%" }}>
                <FadeLoading />
              </div>
            )}

            {!previousDataLoading && data?.length === 0 && (
              <p className="text-center">No previous prescriptions found.</p>
            )}

            {data?.filter(app => app.status === 'TAKEN')?.map((appointment, index) => (
              <div key={appointment?._id || index} className="mb-4">
                <div className="appointment-header">
                  <h5 className="mb-1">
                    {appointment?.from
                      ? moment(appointment.from).format("MMMM Do YYYY, h:mm a")
                      : "N/A"}{" "}
                    - {appointment?.status || "N/A"}
                  </h5>
                  <p className="mb-1">
                    <strong>Description:</strong>{" "}
                    {appointment?.description || "N/A"}
                  </p>
                </div>

                {/* Doctor Report */}
                {appointment?.doctorReport ? (
                  <div className="section-content mt-3">
                    <h6 className="section-title">Doctor Report</h6>
                    <table className="doctor-report-table">
                      <tbody>
                        <tr>
                          <th>Treatment Type:</th>
                          <td>
                            {appointment.doctorReport?.treatmentType || "N/A"}
                          </td>
                          <th>Requires Surgery:</th>
                          <td>
                            {appointment.doctorReport?.requiresSurgery
                              ? "Yes"
                              : "No"}
                          </td>
                          <th>Date of Surgery:</th>
                          <td>
                            {appointment.doctorReport?.dateOfSurgery
                              ? moment(
                                  appointment.doctorReport?.dateOfSurgery
                                ).format("YYYY-MM-DD")
                              : "N/A"}
                          </td>
                        </tr>
                        <tr>
                          <th>Medicines:</th>
                          <td colSpan="5">
                            {appointment.doctorReport?.medicines?.length > 0 ? (
                              <table className="inner-table">
                                <thead>
                                  <tr>
                                    <th>Medicine</th>
                                    <th>Dosage</th>
                                    <th>Days</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {appointment.doctorReport?.medicines?.map(
                                    (med, index) => (
                                      <tr key={index}>
                                        <td>{med?.medicine || "N/A"}</td>
                                        <td>{med?.dosage || "N/A"}</td>
                                        <td>{med?.days || "N/A"}</td>
                                      </tr>
                                    )
                                  )}
                                </tbody>
                              </table>
                            ) : (
                              "N/A"
                            )}
                          </td>
                        </tr>
                        <tr>
                          <th>Description:</th>
                          <td colSpan="5">
                            {appointment.doctorReport?.description || "N/A"}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                ): (
                  <p className="text-danger mt-2">Doctor Report not available</p>
                )}

                {/* Nurse Report */}
                {appointment?.nurseReport ? (
                  <div className="section-content mt-3">
                    <h6 className="section-title">Nurse Report</h6>
                    <table className="doctor-report-table">
                      <tbody>
                        <tr>
                          <th>Height:</th>
                          <td>{appointment.nurseReport?.height || "N/A"}</td>
                          <th>Weight:</th>
                          <td>{appointment.nurseReport?.weight || "N/A"} kg</td>
                          <th>BP:</th>
                          <td>{appointment.nurseReport?.bp || "N/A"}</td>
                        </tr>
                        <tr>
                          <th>Heart Rate:</th>
                          <td>{appointment.nurseReport?.heartRate || "N/A"}</td>
                          <th>Glucose:</th>
                          <td colSpan="3">
                            {appointment.nurseReport?.glucose || "N/A"}
                          </td>
                        </tr>
                        <tr>
                          <th>Initial Diagnosis:</th>
                          <td colSpan="5">
                            {appointment.nurseReport?.complain || "N/A"}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <p className="text-danger mt-2">Nurse Report not available</p>
                )}
                <hr />
              </div>
            ))}
          
          </div>
        </div>
      </div>
    </div>
  );
}

export default PreviousPrescriptions;

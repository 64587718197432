import React, { useState } from 'react'
import moment from 'moment'
import EMPTY_IMAGE_PLACEHOLDER from '../../../../assets/images/doctor_placeholder.png'
import GenerateQrCode from './GenerateQrCode'
import QrPrescriptionApi from '../../../../api/QrPrescription'
import { saveAs } from 'file-saver'
import { useTranslation } from "react-i18next"
import QRCode from 'qrcode.react'

function QRPrescription({ prescriptions }) {
   const { t } = useTranslation()

   const [selectedQrPrescription, setSelectedQrPrescription] = useState({})

   console.log("prescriptions ===> ", prescriptions);

   const viewReport = (qr) => {
      QrPrescriptionApi.downloadQRPrescription(qr._id).then(res => {
         const pdfBlob = new Blob([res.data], { type: 'application/pdf' })
         saveAs(pdfBlob, 'Prescription.pdf')
      })
   }

   return (
      <section class="user-dashboard">
         <div class="container">
            <div class="row justify-content-center">
               <div class="col-md-12 col-xl-10 pb-5">
                  {prescriptions?.filter(app => app.serviceId !== null)?.map((qr, index) => (
                     <div key={index} class="card lab-result">
                        <div class="card-body py-md-2">
                           <div class="row align-items-center">
                              <div class="col-md-12 col-lg-12">
                                 <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <div>
                                       <small class="d-block">{t("date")}</small>
                                       {moment(qr.to).format('LL')}
                                    </div>
                                    <div>
                                       <li class="media">
                                          <img class="avatar-sm" src={qr?.doctorId?.image ? qr?.doctorId?.image : EMPTY_IMAGE_PLACEHOLDER} alt="doctor" />
                                          <div class="media-body ml-2">
                                             <h5 class="mt-0 mb-1">Dr. {qr?.doctorId?.firstName + " " + qr?.doctorId?.lastName}</h5>
                                             <p>{qr?.doctorId?.specialityId?.name}</p>
                                          </div>
                                       </li>
                                    </div>
                                    <div>
                                    <li class="media">
                                          {/* <QRCode style={{ width: '3rem', height: '3rem' }} value={`${window.location.origin}/patient-report/${qr._id}`} /> */}
                                          <a href="javascript:void(0)" data-toggle="modal" data-target="#qrCode" class="btn btn-primary px-3 py-2 mr-3" onClick={() => setSelectedQrPrescription(`${window.location.origin}/patient-report/${qr._id}`)}>{t("VIEW_QR")}</a>
                                          <div class="media-body ml-2">
                                             <a href={`/patient-report/${qr._id}`} target='_blank' class="px-3 py-2 mr-3">{t("view_report")}</a>
                                          </div>
                                       </li>
                                       
                                    </div>
                                 </div>
                              </div>
                              {/* <div class="col-md-12 col-lg-5 text-center text-md-right mt-3 mt-md-0"> */}
                                 
                                 {/* <a href="javascript:void(0)" data-toggle="modal" data-target="#qrCode" class="btn btn-primary px-3 py-2 mr-3" onClick={viewQrPrescription.bind(this, qr)}>{t("VIEW_QR")}</a> */}
                              {/* </div> */}
                              <GenerateQrCode selectedResult={selectedQrPrescription} setSelectedResult={setSelectedQrPrescription} />
                           </div>
                        </div>
                     </div>
                  ))}
                  {prescriptions === undefined || prescriptions?.length === 0 && (
                     <div style={{ backgroundColor: "lightgray", padding: '1rem', borderRadius: '5px' }}>
                        {t("no_prescriptions_available_yet")} !
                     </div>
                  )}
               </div>
            </div>
         </div>
      </section>
   )
}

export default QRPrescription
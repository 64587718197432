import React, { useEffect, useState } from 'react'
import DOCTOR_IMAGE from '../../../../assets/images/doctor.png'
import { getDoctors } from '../../../../store/actions/doctorActions'
import { connect } from 'react-redux'
import classNames from 'classnames'
import { href } from '../../../../constants/extra'
import DoctorsList from './DoctorsList'
import NurseList from './NurseList'
import LabList from './LabList'

function AllDetails() {
    const [tabSelected, setTabSelected] = useState("Doctors");

    let returnedComponent = null

    if (tabSelected === "Doctors") {
        returnedComponent = <DoctorsList />
    } else if (tabSelected === "Nurse") {
        returnedComponent = <NurseList />
    } else if (tabSelected === "Labs") {
        returnedComponent = <LabList />;
    }

    return (
        <>
        <div className="row nav-tab-link">
                    <div className="col-md-12">
                        <ul className="nav justify-content-center">
                            <li className="nav-item">
                                <a className={classNames('nav-link', { 'active': tabSelected === "Doctors" })} href={href} onClick={(e) => { e.preventDefault(); setTabSelected("Doctors") }}>My Doctors</a>
                            </li>
                            <li className="nav-item">
                                <a className={classNames('nav-link', { 'active': tabSelected === "Nurse" })} href={href} onClick={(e) => { e.preventDefault(); setTabSelected("Nurse") }}>My Nurses/Technicians</a>
                            </li>
                            <li className="nav-item">
                                <a className={classNames('nav-link', { 'active': tabSelected === "Labs" })} href={href} onClick={(e) => { e.preventDefault(); setTabSelected("Labs") }}>My Labs</a>
                            </li>
                        </ul>
                    </div>
                </div>
            {returnedComponent}
        </>
    )
}

export default AllDetails
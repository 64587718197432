import { Switch, withRouter } from "react-router-dom";
import NurseRoute from "../../ProtectedRoutes/NurseRoute";
import Patient from "../shared/Patient";
import NursePatientInfo from "../shared/Patient/components/NursePatientInfo";
import NurseProfile from "./Profile";
import NurseAppointments from "./Appointments";
import Diagnose from "../../components/Diagnose";
import SlotsCalendar from "./SlotsCalendar";
import DoctorSchedule from "./Doctors";
import NurseSlotsCalendar from "./NurseSlotsCalendar";

const NurseRouter = withRouter(({ match, ...props }) => {
    return (
        <Switch {...props}>
            <NurseRoute exact path={`${match.path}`}>
                <NurseAppointments />
            </NurseRoute>
            <NurseRoute exact path={`${match.path}/schedule`}>
                <NurseSlotsCalendar />
            </NurseRoute>
            <NurseRoute exact path={`${match.path}/patient-info/:id`} >
                <NursePatientInfo />
            </NurseRoute>
            <NurseRoute exact path={`${match.path}/profile`} >
                <NurseProfile />
            </NurseRoute>
            <NurseRoute exact path={`${match.path}/doctor-schedule`} >
                <DoctorSchedule />
            </NurseRoute>
            <NurseRoute exact path={`${match.path}/schedule/:doctorId`} >
                <SlotsCalendar />
            </NurseRoute>
            <NurseRoute exact path={`${match.path}/patient-info/:id/:appointmentId`} >
                {/* <PatientAppointmentDetail /> */}
                <Diagnose />
            </NurseRoute>
        </Switch>
    )
});

export default NurseRouter;

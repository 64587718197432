import React, { useEffect, useState } from 'react';
import AppLayout from '../../../../layout/AppLayout';
import PLACEHOLDER_LARGE from '../../../../assets/images/medeor_logo.png';
import PLACEHOLDER_SMALL from '../../../../assets/images/medeor_logo.png';
import HospitalApi from '../../../../api/Hospital';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import HospitalDoctors from './HospitalDoctors';
import HospitalServices from './HospitalServices';
import { withScriptjs } from "react-google-maps";
import DirectionsMap from './directions/DirectionsMap';
import LocateHospital from './directions/LocateHospital';
import { useTranslation } from "react-i18next";
import OtherHospitalServices from './OtherHospitalServices';
import { FadeLoading } from '../../../../loaders';

function HospitalDetails() {
    const [hospital, setHospital] = useState({});
    const [doctors, setDoctors] = useState([]);
    const [otherServices, setOtherServices] = useState([]);
    const [loading, setLoading] = useState(true);
    const { id } = useParams();
    const { t } = useTranslation();

    const MapLoader = withScriptjs(DirectionsMap);

    useEffect(() => {
        getHospitalData();
    }, []);

    const getHospitalData = async () => {
        HospitalApi.getSingleHospital(id).then(hospital => {
            setHospital(hospital?.data?.data?.hospital || {});
            setDoctors(hospital?.data?.data?.doctors || []);
            setOtherServices(hospital?.data?.data?.otherServices || []);
        }).catch(err => {
            toast.error("Problem while getting the hospital");
        }).finally(() => {
            setLoading(false);
        });
    };

    // Render the rating stars based on cumulativeRating
    const renderRatingStars = (rating) => {
        const fullStars = Math.floor(rating);  // Full stars
        const halfStar = rating % 1 >= 0.5 ? 1 : 0;  // Half star
        const emptyStars = 5 - fullStars - halfStar;  // Remaining empty stars

        const stars = [];

        // Add full stars
        for (let i = 0; i < fullStars; i++) {
            stars.push(<i key={`full-${i}`} className="text-warning fa fa-star"></i>);
        }

        // Add half star
        if (halfStar) {
            stars.push(<i key="half" className="text-warning fa fa-star-half-o"></i>);
        }

        // Add empty stars
        for (let i = 0; i < emptyStars; i++) {
            stars.push(<i key={`empty-${i}`} className="fa fa-star-o"></i>);
        }

        return stars;
    };

    return (
        <AppLayout>
            <section className="search-block pt-4">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><a href="javascript:void(0)">{t("home")}</a></li>
                                    <li className="breadcrumb-item active" aria-current="page">{t("hospital")}</li>
                                    <li className="breadcrumb-item active" aria-current="page">{hospital?.name || `${t("loading")}...`}</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </section>
            <section className="user-dashboard">
                <div className="container">
                    <div className="row align-items-start add-list hospital-info">
                        <div className="col-12">
                            <h4>{hospital?.name}</h4>

                            {/* Updated Rating Section */}
                            <p className="rating mb-1">
                                {hospital?.cumulativeRating ? renderRatingStars(hospital.cumulativeRating) : `${t('loading')}`}
                            </p>

                            <p><span className="icon-map"></span> {hospital?.address}
                                <a href="javascript:void(0)" data-toggle="modal" data-target="#getDirections" style={{ marginLeft: '5px' }}> {t("get_direction")}</a>
                            </p>
                            <h6> {t("open_now")} </h6>

                            {hospital.location?.coordinates?.length > 0 && (
                                <LocateHospital lat={hospital?.location?.coordinates[0]} lng={hospital?.location?.coordinates[1]} />
                            )}
                            <div className="modal fade" id="getDirections" tabindex="-1" aria-labelledby="getDirectionsLabel" aria-hidden="true">
                                <div className="modal-dialog modal-dialog-centered modal-lg">
                                    <div className="modal-content">
                                        <div className="modal-body">
                                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                <span className="icon-close"></span>
                                            </button>
                                            <h4 className="text-center">{t("get_direction")}</h4>
                                            {/* Directions Map Here */}
                                            {hospital.location?.coordinates?.length > 0 && (
                                                <MapLoader
                                                    hospitalLocation={hospital?.location}
                                                    googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAP_API_KEY}`}
                                                    loadingElement={<div style={{ height: `100%` }} />}
                                                />
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-2">
                        <div className="col-md-8 mb-3">
                            <img className="img-fluid h-70" style={{ width: '100%' }} src={hospital?.images?.length > 0 ? hospital.images[0] : PLACEHOLDER_LARGE} alt="placeholder" />
                        </div>
                        <div className="col-md-4">
                            <img className="img-fluid w-70 mb-3" src={hospital?.images?.length > 1 ? hospital.images[1] : PLACEHOLDER_SMALL} alt="placeholder" />
                            <img className="img-fluid w-70 mb-3" src={hospital?.images?.length > 2 ? hospital.images[2] : PLACEHOLDER_SMALL} alt="placeholder" />
                        </div>
                    </div>
                    <div className="row mt-4 pb-5">
                        <div className="col-md-12 hospital-tabs" style={loading ? { pointerEvents: 'none' } : {}}>
                            <ul className="nav nav-pills mb-4" id="pills-tab" role="tablist">
                                <li className="nav-item" role="presentation">
                                    <a className="nav-link active" id="pills-overview-tab" data-toggle="pill" href="#pills-overview" role="tab" aria-controls="pills-overview" aria-selected="true">Overview</a>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <a className="nav-link" id="pills-doctors-tab" data-toggle="pill" href="#pills-doctors" role="tab" aria-controls="pills-doctors" aria-selected="false">Doctors</a>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <a className="nav-link" id="pills-addons-tab" data-toggle="pill" href="#pills-addons" role="tab" aria-controls="pills-addons" aria-selected="false">Addons</a>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <a className="nav-link" id="pills-services-tab" data-toggle="pill" href="#pills-services" role="tab" aria-controls="pills-services" aria-selected="false">Services</a>
                                </li>
                            </ul>
                            <div className="tab-content" id="pills-tabContent">
                                <div className="tab-pane fade show active" id="pills-overview" role="tabpanel" aria-labelledby="pills-overview-tab">
                                    <h4>About the Hospital</h4>
                                    <p>{hospital?.about}</p>
                                </div>

                                {loading ? (
                                    <div style={{ width: '100%', marginTop: '1%' }}>
                                        <FadeLoading />
                                    </div>
                                ) : (
                                    <>
                                        <HospitalDoctors doctors={doctors} />
                                        <HospitalServices services={hospital?.services} />
                                        <OtherHospitalServices services={otherServices} />
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </AppLayout>
    );
}

export default HospitalDetails;

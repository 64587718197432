import React, { useState } from 'react';
import PATIENT_IMAGE from '../../../../assets/images/patient.png';
import DOCTOR_IMAGE from '../../../../assets/images/doctor.png';
import moment from 'moment';
import { FadeLoading } from '../../../../loaders';
import { href } from '../../../../constants/extra';

function DoctorsAppointments({ appointmentLoading, appointments, onServiceClick }) {
    const now = moment(); // Get the current time
    const [selectedService, setSelectedService] = useState(null);

    const handleServiceClick = (service) => {
        setSelectedService(service);
        document.getElementById('serviceDetailsModal').click(); // Trigger modal
    };

    return (
        <>
            <div className="scrollbar">
                <div className="table-responsive">
                    {appointmentLoading && (
                        <FadeLoading style={{ marginTop: '5rem' }} />
                    )}
                    <table className="table mb-md-0">
                        <tbody>
                            {!appointmentLoading && appointments.filter(app => app.patientId !== null)?.map(appointment => {
                                const isPastAppointment = moment(appointment.to).isBefore(now); // Check if the appointment time is past

                                return (
                                    <tr key={appointment._id} className={isPastAppointment ? 'gray-out' : ''}>
                                        <td>
                                            <span>Time</span>
                                            <p>
                                                <strong>
                                                    {`${moment(appointment.from).format("DD-MM-YY")} - ( ${moment(appointment.from).format('HH.mm')} - ${moment(appointment.to).format('HH.mm')} )`}
                                                </strong>
                                            </p>
                                        </td>
                                        <td>
                                            <div className="media">
                                                {appointment.nurseId ? (
                                                    // Nurse Information
                                                    <>
                                                        <img src={appointment?.nurseId?.image || DOCTOR_IMAGE} alt="nurse" />
                                                        <div className="media-body ml-2">
                                                            <h5 className="mt-0">{`${appointment?.nurseId?.firstName} ${appointment?.nurseId?.lastName}`}</h5>
                                                            <p>Nurse</p>
                                                        </div>
                                                    </>
                                                ) : (
                                                    // Doctor Information
                                                    <>
                                                        <img src={appointment?.doctorId?.image || DOCTOR_IMAGE} alt="doctor" />
                                                        <div className="media-body ml-2">
                                                            <h5 className="mt-0">Dr. {`${appointment?.doctorId?.firstName} ${appointment?.doctorId?.lastName}`}</h5>
                                                            <p>{appointment?.doctorId?.specialityId?.map((item, index) => index === appointment?.doctorId?.specialityId.length - 1 ? item['name_en'] : item['name_en'] + ", ")}</p>
                                                        </div>
                                                    </>
                                                )}
                                            </div>
                                        </td>
                                        <td>
                                            <div className="media">
                                                <img src={appointment?.patientId?.image || PATIENT_IMAGE} alt="patient" />
                                                <div className="media-body ml-2">
                                                    <h5 className="mt-0">{`${appointment?.patientId.firstName} ${appointment?.patientId.lastName}`}</h5>
                                                    <p>Patient</p>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            {appointment.serviceId ? (
                                                <a
                                                    href={href}
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        handleServiceClick(appointment.serviceId); // Show service details in a modal
                                                    }}
                                                    className="text-primary"
                                                    style={{ cursor: 'pointer', textDecoration: 'underline' }}
                                                >
                                                    {appointment.serviceId.name}
                                                </a>
                                            ) : (
                                                <span>Regular Appointment</span>
                                            )}
                                        </td>
                                    </tr>
                                );
                            })}
                            {!appointmentLoading && appointments.length === 0 && (
                                <p>No appointment found</p>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>

            {/* Trigger Modal */}
            <a
                href={href}
                id="serviceDetailsModal"
                data-toggle="modal"
                data-target="#serviceModal"
                style={{ display: 'none' }}
            >
                Open Modal
            </a>

            {/* Service Details Modal */}
            <div className="modal fade" id="serviceModal" tabIndex="-1" aria-labelledby="serviceModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-lg">
                    <div className="modal-content">
                        <div className="modal-body">
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span className="icon-close"></span>
                            </button>
                            <h4 className="text-center">Service Details</h4>
                            <div style={{ textAlign: 'center' }}>
                                <table style={{ border: '1px solid gray', padding: '7px', width: '100%' }}>
                                    <thead style={{ border: '1px solid gray', padding: '7px' }}>
                                        <tr style={{ border: '1px solid gray', padding: '7px' }}>
                                            <td style={{ border: '1px solid gray', padding: '7px', fontWeight: 'bold' }}>Service Name</td>
                                            <td style={{ border: '1px solid gray', padding: '7px', fontWeight: 'bold' }}>Price</td>
                                            <td style={{ border: '1px solid gray', padding: '7px', fontWeight: 'bold' }}>Covered by Insurance</td>
                                        </tr>
                                    </thead>
                                    <tbody style={{ border: '1px solid gray', padding: '7px' }}>
                                        <tr style={{ border: '1px solid gray', padding: '7px' }}>
                                            <td style={{ border: '1px solid gray', padding: '7px' }}>{selectedService?.name}</td>
                                            <td style={{ border: '1px solid gray', padding: '7px' }}>{selectedService?.isFree ? "Free" : `${selectedService?.price} AED`}</td>
                                            <td style={{ border: '1px solid gray', padding: '7px' }}>{selectedService?.coveredByInsurance ? 'Yes' : 'No'}</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div className="mt-4">
                                    <h5>Description</h5>
                                    <p>{selectedService?.description}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default DoctorsAppointments;

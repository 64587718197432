import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import LaboratoryApi from '../../../../api/Laboratory';
import { FaChevronDown, FaChevronRight } from 'react-icons/fa';

function SendLabResults({ selectedRequest }) {
    const [requests, setRequests] = useState([]);
    const [files, setFiles] = useState([]);
    const [filesError, setFilesError] = useState([]);
    const [activeIndex, setActiveIndex] = useState(0); // For accordion toggle

    useEffect(() => {
        setRequests(selectedRequest?.tests?.map((obj) => ({ ...obj, result: "", range: "" })));
    }, [selectedRequest?.tests]);

    const onFileUpload = (index, e) => {
        var upld = e.target.files[0].type;
        const size = (e.target.files[0].size / (1024 * 1024)).toFixed(2);
        if (upld === "application/pdf") {
            if (size <= 20) {
                const newSelectedArray = files;
                newSelectedArray[index] = e.target.files[0];
                setFiles([...newSelectedArray]);
                const errors = filesError;
                errors[index] = "";
                setFilesError([...errors]);
                const tempResults = [...requests];
                tempResults[index].file = e.target.files[0].name;
                setRequests(tempResults);
            } else {
                const errors = filesError;
                errors[index] = "Max size should be 20 MB";
                setFilesError([...errors]);
            }
        } else {
            const errors = filesError;
            errors[index] = "Only pdf files are allowed";
            setFilesError([...errors]);
        }
    };

    const onFormSubmit = (e) => {
        e.preventDefault();
        if (requests.length === 0) {
            toast.error("Test results not completed");
            return false;
        }

        const shouldUpload = requests.every((item) => {
            if ((item.result === "" || item.range === "") && !item.file) {
                toast.error("Please add result and range or select file for test " + item.test);
                return false;
            } else {
                return true;
            }
        });

        if (shouldUpload) {
            let formData = new FormData();
            formData.append("id", selectedRequest._id);
            formData.append("data", JSON.stringify(requests));
            files.forEach((file) => {
                if (file !== undefined) {
                    formData.append("files", file);
                }
            });
            LaboratoryApi.updateLabRequest(formData)
                .then((res) => {
                    toast.success("Result sent successfully");
                })
                .catch((err) => {
                    toast.error("Problem while sending the result");
                });
        }
    };

    const onResultChange = (index, e) => {
        const tempResults = [...requests];
        tempResults[index].result = e.target.value;
        setRequests(tempResults);
    };

    const onRangeChange = (index, e) => {
        const tempResults = [...requests];
        tempResults[index].range = e.target.value;
        setRequests(tempResults);
    };

    return (
        <div className="modal fade" id="sendResults" tabIndex="-1" aria-labelledby="sendResultsLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered send-lab-request">
                <div className="modal-content">
                    <div className="modal-body">
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span className="icon-close"></span>
                        </button>
                        <h4 className="text-center">Send Result</h4>

                        <form onSubmit={onFormSubmit}>
                            <div className="accordion" id="labResultsAccordion">
                                {requests?.map((request, index) => (
                                    <div key={index} className="card">
                                        <div className="card-header" onClick={() => setActiveIndex(activeIndex === index ? -1 : index)}>
                                            <h5 className="mb-0 d-flex justify-content-between align-items-center">
                                                {request.test}
                                                <span>{activeIndex === index ? <FaChevronDown /> : <FaChevronRight />}</span>
                                            </h5>
                                        </div>
                                        {activeIndex === index && (
                                            <div className="lab-card-body">
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <input type="text" className="form-control" value={request.result} onChange={(e) => onResultChange(index, e)} placeholder="Result" />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <input type="text" className="form-control" value={request.range} onChange={(e) => onRangeChange(index, e)} placeholder="Reference range" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="or-separator">OR</div>
                                                <div className="file-upload-area">
                                                    <input type="file" className="file-input" id={`file-upload-${index}`} onChange={(e) => onFileUpload(index, e)} />
                                                    <label className="file-drag-drop" htmlFor={`file-upload-${index}`}>
                                                        {files[index] ? `${files[index].name} - ${(files[index].size / (1024 * 1024)).toFixed(2)} MB` : "Upload Lab Result File"}
                                                    </label>
                                                    {filesError[index] && (
                                                        <div style={{ color: "red", fontSize: "0.9rem" }}>{filesError[index]}</div>
                                                    )}
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                ))}
                            </div>
                            <div className="form-group text-center mb-0">
                                <button type="submit" className="btn btn-primary">Confirm</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SendLabResults;

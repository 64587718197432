import React, { useEffect, useState } from 'react'
import DOCTOR_IMAGE from '../../../../assets/images/doctor.png'
import { getAllDoctors } from '../../../../store/actions/doctorActions'
import { connect } from 'react-redux'
import Card from '../../../../components/Card'
import { FadeLoading } from '../../../../loaders'
import { useHistory } from 'react-router-dom'
import { DOCTOR_INFO_ROUTE } from '../../../../constants/Redirects'

function DoctorsList({ doctors, getAllDoctors }) {
    const { totalDoctors: allDoctors } = doctors && doctors
    const [loading, setLoading] = useState(true);
    const history = useHistory();

    useEffect(() => {
        getDoctorsList();
    }, [getAllDoctors])

    const getDoctorsList = async () => {
        try {
            await getAllDoctors(0, "HandledOnBackend", true)
            setLoading(false);
        } catch (err) {
            setLoading(false);
        }
    }

    return (
        <div className="horizontal-scroll-container">
            {loading && allDoctors.length === 0 && <FadeLoading />} 
            {allDoctors?.map((doc, index) => (
                <Card 
                    picture={doc?.image ? doc?.image : DOCTOR_IMAGE} 
                    heading={`Dr. ${doc.firstName + " " + doc.lastName}`}
                    text={
                        doc.specialityId?.map((item, index) => 
                        index === doc.specialityId.length - 1 ? item['name_en'] : item['name_en'] + ", "
                    )}
                    onClick={() => history.push(DOCTOR_INFO_ROUTE + `/${doc._id}`)}
                />
            ))}
            {!loading && allDoctors?.length === 0 && (
                <div className="no-doctors">
                    <p>No doctors found</p>
                </div>
            )}
        </div>
    )
}

const mapStateToProps = (state) => ({
    doctors: state.doctors
})

const mapDispatchToProps = {
    getAllDoctors
}

export default connect(mapStateToProps, mapDispatchToProps)(DoctorsList)
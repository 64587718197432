import React from 'react'
import { FadeLoader } from 'react-spinners'
import "./index.css"

function FadeLoading({ style }) {
  return (
    <div className='fade-loading-container' style={{...style}}>
      <FadeLoader color="#417EBF" size={12} />
    </div>
  )
}

export {
  FadeLoading
}
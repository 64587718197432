import axios from '../../axios';
import { RECEPTIONIST_NAMESPACE } from '../../constants/namespaces';
import { trackPromise} from 'react-promise-tracker';

const ReceptionistApi = {
    getAllReceptionists(pageNo, getAll) {
        return trackPromise(axios.get(`/${RECEPTIONIST_NAMESPACE}?page=${pageNo}&getAll=${getAll}`));
    },
    createReceptionist(data) {
        return trackPromise(axios.post(`${RECEPTIONIST_NAMESPACE}`, data));
    },
    deleteReceptionist(id){
        return trackPromise(axios.delete(`${RECEPTIONIST_NAMESPACE}/${id}`));
    },
    getSingleReceptionist(id) {
        return trackPromise(axios.get(`${RECEPTIONIST_NAMESPACE}/${id}`));
    },
    updateReceptionist(id, values) {
        return trackPromise(axios.put(`${RECEPTIONIST_NAMESPACE}/${id}`, values));
    },
    searchReceptionists(pageNo, searchedText) {
        return trackPromise(axios.get(`${RECEPTIONIST_NAMESPACE}/search/${searchedText}?page=${pageNo}`));
    },
    removeProfilePicture(receptionistId) {
        return trackPromise(axios.delete(`/${RECEPTIONIST_NAMESPACE}/deleteProfileImage/${receptionistId}`));
    },
    uploadProfilePic(id, data) {
        return trackPromise(axios.put(`/${RECEPTIONIST_NAMESPACE}/uploadProfilePicture/${id}`, data));
    },
}

export default ReceptionistApi;
export const ReceptionistRoutes = [
  {
    name: "Appointments",
    icon: "icon-patient",
    route: "/receptionist"
  },
  {
      name: "Doctors",
      icon: "icon-stethoscope",
      route: "/receptionist/doctors"
  },
  {
      name: "Nurse",
      icon: "icon-nurse",
      route: "/receptionist/nurse"
  },
  {
      name: "Promos",
      icon: "icon-patient",
      route: "/receptionist/promos"
  },
  {
      name: "Offers",
      icon: "icon-patient",
      route: "/receptionist/offers"
  },
  {
    name: "Patient Promo Code",
    icon: "icon-patient",
    route: "/receptionist/patient-promo-codes"
  },
  {
    name: "Claimed Offers",
    icon: "icon-patient",
    route: "/receptionist/claimed-offers"
  },
]
import React, { useEffect, useRef, useState } from 'react';
import LaboratoryApi from '../../../api/Laboratory';
import { toast } from 'react-toastify';
import { BeatLoader } from 'react-spinners';

function RequestToLab({ appointment }) {
   const [tests, setTests] = useState([{ test: "" }]);
   const [selectedLab, setSelectedLab] = useState("");
   const [previousLabResult, setPreviousLabResult] = useState(null);
   const [labs, setLabs] = useState([]);
   const [loading, setLoading] = useState(false);
   const closeBtnRef = useRef();

   useEffect(() => {
      if (appointment?.hospitalId) {
         LaboratoryApi.getAllLaboratories(0, true, appointment.hospitalId).then(res => {
            setLabs(res.data.data.labs);
         });
         getPreviousLabRequest();
      }
   }, [appointment]);

   const getPreviousLabRequest = async () => {
      setLoading(true);
      const res = await LaboratoryApi.getAppointmentLabRequest(appointment?._id);
      if (res.data.data) {
         setPreviousLabResult(res.data.data);
         setSelectedLab(res.data.data.laboratoryId);
         setTests(res.data.data.tests.map(test => ({ test: test.test })));
      }
      setLoading(false);
   };

   const onInputChange = (index, e) => {
      const allTests = [...tests];
      allTests[index].test = e.target.value;
      setTests(allTests);
   };

   const onSend = (e) => {
      e.preventDefault();

      if (tests.length === 1 && tests[0].test === "") {
         toast.error("No tests entered");
         return false;
      }

      setLoading(true);
      if(previousLabResult && Object.keys(previousLabResult).length > 0) {
         LaboratoryApi.updateAppointmentLabRequest(previousLabResult._id, {
            laboratoryId: selectedLab,
            tests: tests.filter(item => item.test !== "")
         }).then(res => {
            toast.success("Laboratory Request Generated");
            closeBtnRef.current.click();

            setPreviousLabResult(res.data.data);
            setSelectedLab(res.data.data.laboratoryId);
            setTests(res.data.data.tests.map(test => ({ test: test.test })));

         }).catch(err => {
            toast.error("Problem while generating request");
         }).finally(() => {
            setLoading(false)
         });
      } else {
         LaboratoryApi.createLabRequest({
            slotId: appointment._id,
            doctorId: appointment.doctorId._id,
            patientId: appointment.patientId._id,
            laboratoryId: selectedLab,
            tests: tests.filter(item => item.test !== "")
         }).then(res => {
            
            setPreviousLabResult(res.data.data);
            setSelectedLab(res.data.data.laboratoryId);
            setTests(res.data.data.tests.map(test => ({ test: test.test })));

            toast.success("Laboratory Request Generated");
            closeBtnRef.current.click();
         }).catch(err => {
            toast.error("Problem while generating request");
         }).finally(() => {
            setLoading(false)
         });
      }
   };

   const onAddMore = () => {
      setTests([...tests, { test: "" }]);
   };

   return (
      <div className="modal fade" id="requestLaboratory" tabIndex="-1" aria-labelledby="requestLaboratoryLabel" aria-hidden="true">
         <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
               <div className="modal-body">
                  <button ref={closeBtnRef} type="button" className="close" data-dismiss="modal" aria-label="Close">
                     <span className="icon-close"></span>
                  </button>
                  <h4 className="text-center">Request for Laboratory</h4>
                  <form>
                     <div className="row align-items-end">
                        <div className="col-md-12">
                           <div className="form-group">
                              <select className="form-control" placeholder="Choose Laboratory" style={{ height: "50px" }} value={selectedLab} onChange={(e) => setSelectedLab(e.target.value)}>
                                 <option value="">Choose Laboratory</option>
                                 {labs?.map(item => (
                                    <option key={item._id} value={item._id}>{item.firstName + " " + item.lastName}</option>
                                 ))}
                              </select>
                           </div>
                        </div>
                        {tests?.map((test, index) => (
                           <div key={index} className="col-md-12">
                              <div className="form-group">
                                 <input className="form-control" placeholder="Test Name" value={test.test} onChange={(e) => onInputChange(index, e)} />
                              </div>
                           </div>
                        ))}
                     </div>
                     <div style={{ display: 'flex', justifyContent: 'center', marginTop: '-0.5rem', marginBottom: '0.5rem' }}>
                        <span className="fa fa-plus add-field" onClick={onAddMore}></span>
                     </div>
                     <div className="form-group text-center mb-0">
                        <button disabled={loading} type="submit" className="btn btn-primary" onClick={onSend}>
                           {loading ? <BeatLoader size={8} /> : 'Send'}
                        </button>
                     </div>
                  </form>
               </div>
            </div>
         </div>
      </div>
   )
}

export default RequestToLab;

export const DOCTOR_SLOT = "DOCTOR"
export const PCR_TEST_SLOT = "PCR_TEST"
export const PCR_VACCINATION_SLOT = "PCR_VACCINATION"
export const SELECTED_DOCTOR_OR_HOSPITAL = "SELECTED_DOCTOR_OR_HOSPITAL"
export const SELECTED_SERVICE_AND_PROVIDER = "SELECTED_SERVICE_AND_PROVIDER"

// SLOT STATUS

export const SlotStatus = {
  AVAILABLE: "AVAILABLE",
  BOOKED: "BOOKED",
  APPROVED: "APPROVED",
  TAKEN: "TAKEN"
}
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import moment from 'moment';
import DashboardLayout from "../../../layout/DashboardLayout";
import AppointmentApi from "../../../api/Appointment";
import Table from "../../../components/Table";
import { FadeLoading } from "../../../loaders";
import DateFilter from "../../../components/forms/DateFilter";
import { href } from "../../../constants/extra";

function Appointments() {
  const { id } = useParams();
  const [appointments, setAppointments] = useState(null);
  const [date, setDate] = useState(moment().format('YYYY-MM-DD'));
  const [emiratesId, setEmiratesId] = useState('');
  const [loading, setLoading] = useState(true);
  const [selectedService, setSelectedService] = useState(null);

  useEffect(() => {
    getAppointments();
  }, [id, date, emiratesId]);

  const getAppointments = async () => {
    try {
      setLoading(true);
      const response = await AppointmentApi.getHospitalAppointment("HandledOnBackend", date, emiratesId);
      setAppointments(response.data.data);
      setLoading(false);
    } catch (e) {
      setAppointments([]);
      setLoading(false);
    }
  }

  const handleDateChange = (e) => {
    setDate(e.target.value);
  };

  const handleEmiratesIdChange = (e) => {
    setEmiratesId(e.target.value);
  };

  const getStatusBadge = (status) => {
    const badgeClasses = {
      BOOKED: 'badge badge-primary',
      APPROVED: 'badge badge-warning',
      TAKEN: 'badge badge-success',
    };

    return (
      <span style={{ fontSize: '1rem' }} className={badgeClasses[status] || 'badge badge-secondary'}>
        {status}
      </span>
    );
  };

  return (
    <DashboardLayout>
      <div className="row doctor-profile">
        <div className="col-md-12">
          <div className="card">
            <div className="card-body">
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <h4>Appointments</h4>
                <div style={{ display: "flex", gap: "10px" }}>
                  <DateFilter date={date} onDateChange={setDate} />
                  <input
                    type="text"
                    name="emiratesIdFilter"
                    className={"form-control"}
                    style={{ width: "12rem" }}
                    placeholder="Emirate ID"
                    value={emiratesId}
                    onChange={handleEmiratesIdChange}
                  />
                </div>
              </div>
              <div className="scrollbar" style={{ height: "80vh", marginTop: '2rem' }}>
                <div className="table-responsive">
                  <Table headers={["Provider", "PATIENT NAME", "EMIRATES ID", "SERVICE", "DATE", "TIME", "STATUS"]}>
                    <tbody className='new-table-body'>
                      {!loading && appointments?.filter((app) => app.patientId !== null)?.map(appointment => (
                        <tr key={appointment._id} className='new-table-row'>
                          <td className='new-table-cell'>
                            {appointment.doctorId ? (
                              `Dr. ${appointment?.doctorId?.firstName} ${appointment?.doctorId?.lastName}`
                            ) : (
                              `${appointment?.nurseId?.firstName} ${appointment?.nurseId?.lastName}`
                            )}
                          </td>
                          <td className='new-table-cell'>{appointment?.patientId?.firstName + " " + appointment?.patientId?.lastName}</td>
                          <td className='new-table-cell'>{appointment?.patientId?.emiratesId}</td>
                          <td className='new-table-cell'>
                            {appointment.serviceId ? (
                              <a
                                href={href}
                                onClick={(e) => {
                                  e.preventDefault();
                                  setSelectedService(appointment.serviceId);
                                  document.getElementById('serviceDetailsModal').click();
                                }}
                                style={{ textDecoration: 'underline', cursor: 'pointer', color: 'blue' }}
                              >
                                {appointment.serviceId.name}
                              </a>
                            ) : "N/A"}
                          </td>
                          <td className='new-table-cell'>{`${moment(appointment.from).format("DD-MM-YY")}`}</td>
                          <td className='new-table-cell'>{moment(appointment.from).format('hh:mm a')} - {moment(appointment.to).format('hh:mm a')}</td>
                          <td className='new-table-cell'>{getStatusBadge(appointment.status)}</td>
                        </tr>
                      ))}
                      {!loading && appointments?.length === 0 && (
                        <tr className='new-table-row'>
                          No appointment found
                        </tr>
                      )}
                    </tbody>
                  </Table>
                  {loading && (
                    <div style={{ width: '100%', marginTop: '7%' }}>
                      <FadeLoading />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Trigger Modal */}
      <a
        href={href}
        id="serviceDetailsModal"
        data-toggle="modal"
        data-target="#serviceModal"
        style={{ display: 'none' }}
      >
        Open Modal
      </a>

      <div className="modal fade" id="serviceModal" tabIndex="-1" aria-labelledby="serviceModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered modal-lg">
            <div className="modal-content">
              <div className="modal-body">
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span className="icon-close"></span>
                </button>
                <h4 className="text-center">Service Details</h4>
                <div style={{ textAlign: 'center' }}>
                  <table style={{ border: '1px solid gray', padding: '7px', width: '100%' }}>
                    <thead style={{ border: '1px solid gray', padding: '7px' }}>
                      <tr style={{ border: '1px solid gray', padding: '7px' }}>
                        <td style={{ border: '1px solid gray', padding: '7px', fontWeight: 'bold' }}>Service Name</td>
                        <td style={{ border: '1px solid gray', padding: '7px', fontWeight: 'bold' }}>Price</td>
                        <td style={{ border: '1px solid gray', padding: '7px', fontWeight: 'bold' }}>Covered by Insurance</td>
                      </tr>
                    </thead>
                    <tbody style={{ border: '1px solid gray', padding: '7px' }}>
                      <tr style={{ border: '1px solid gray', padding: '7px' }}>
                        <td style={{ border: '1px solid gray', padding: '7px' }}>{selectedService?.name}</td>
                        <td style={{ border: '1px solid gray', padding: '7px' }}>{selectedService?.isFree ? "Free" : selectedService?.price + " AED"} </td>
                        <td style={{ border: '1px solid gray', padding: '7px' }}>
                          {selectedService?.coveredByInsurance ? 'Yes' : 'No'}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div className="mt-4">
                    <h5>Description</h5>
                    <p>{selectedService?.description}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    </DashboardLayout>
  );
}

export default Appointments;

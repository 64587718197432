import { useEffect, useState } from 'react'
import { toast } from 'react-toastify';
import HospitalApi from '../../../api/Hospital';
import DashboardLayout from '../../../layout/DashboardLayout'
import { BeatLoader } from "react-spinners";
import { FadeLoading } from '../../../loaders';

function ApprovalHospitals() {
	const [hospitals, setHospitals] = useState([]);
	const [loading, setLoading] = useState(false);
	const [loadingId, setLoadingId] = useState(null);
	const [loadingData, setLoadingData] = useState(true);

	useEffect(() => {
		getPageData();
	}, []);

	const getPageData = async () => {
		!loadingData && setLoadingData(true);
		HospitalApi.getPendingHospitals().then(res => {
			setHospitals(res.data.data);
		}).finally(() => {
			setLoadingData(false);
		})
	}

	const approveHospital = (id) => {
		setLoading(true);
		setLoadingId(id);
		HospitalApi.approveHospital(id).then(res => {
			toast.success("Hospital Approved Successfully");
			const tempHospital = JSON.parse(JSON.stringify(hospitals));
			setHospitals(tempHospital.filter(hos => hos._id !== id));
		}).finally(() => {
			setLoading(false);
			setLoadingId(null);
		});
	}

	const downloadFile = async (id, e) => {
		e.preventDefault();
		const result = await HospitalApi.getTradeLicenseFile(id)
		console.log("result => ", result.data.data);
		if (result.data.data.tradeLicenseFile) {
			const newWindow = window.open(result.data.data.tradeLicenseFile, '_blank', 'noopener,noreferrer')
			if (newWindow) newWindow.opener = null
		} else {
			toast.error("Don't have Trade License File")
		}
	}

	return (
		<DashboardLayout>
			<div className="row align-items-center add-list">
				<div className="col-12">
					<h4>Hospital Approvals</h4>
					<br />
					<div style={{ textAlign: 'center' }}>
						{loadingData && (
          	  <div style={{ width: '100%', marginTop: '7%' }}>
        	      <FadeLoading />
      	      </div>
    	      )}
						{!loadingData && hospitals?.length > 0 && (
							<table style={{ border: '1px solid gray', padding: '7px', width: '100%' }}>
								<thead style={{ border: '1px solid gray', padding: '7px' }}>
									<tr style={{ border: '1px solid gray', padding: '7px' }}>
										<td style={{ border: '1px solid gray', padding: '7px', fontWeight: 'bold' }}>Hospital Name</td>
										<td style={{ border: '1px solid gray', padding: '7px', fontWeight: 'bold' }}>Email</td>
										<td style={{ border: '1px solid gray', padding: '7px', fontWeight: 'bold' }}>Status</td>
										<td style={{ border: '1px solid gray', padding: '7px', fontWeight: 'bold' }}>Trade License File</td>
										<td style={{ border: '1px solid gray', padding: '7px', fontWeight: 'bold' }}>Actions</td>
									</tr>
								</thead>
								<tbody style={{ border: '1px solid gray', padding: '7px' }}>
									{!loadingData && hospitals?.map(hospital => (
										<tr key={hospital._id} style={{ border: '1px solid gray', padding: '7px' }}>
											<td style={{ border: '1px solid gray', padding: '7px' }}>{hospital?.firstName + " " + hospital?.lastName}</td>
											<td style={{ border: '1px solid gray', padding: '7px' }}>{hospital?.email}</td>
											<td style={{ border: '1px solid gray', padding: '7px' }}>{hospital?.status}</td>
											<td style={{ border: '1px solid gray', padding: '7px' }}><a href={""} onClick={downloadFile.bind(this, hospital.referenceId)}>See Attachment</a></td>
											<td style={{ border: '1px solid gray', padding: '7px' }}>
												<button disabled={loading} className="btn btn-success" onClick={approveHospital.bind(this, hospital._id)}>
													{(loading && loadingId === hospital._id) ? (
														<BeatLoader size={8} />
													) : (
														<>APPROVE</>
													)}
												</button>
											</td>
										</tr>
									))}
								</tbody>
							</table>
						)}
					</div>
					{!loadingData && hospitals?.length === 0 && (
							<p>No pending hospital approvals</p>
						)}
				</div>
			</div>
		</DashboardLayout>
	)
}

export default ApprovalHospitals
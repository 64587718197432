export const VendorRoutes = [
	{
		name: "Offers",
		icon: "icon-dashboard",
		route: "/vendor"
	},
	{
		name: "Promo Codes",
		icon: "icon-dashboard",
		route: "/vendor/promo-codes"
	},
]
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import PLACEHOLDER_IMAGE from '../../../../assets/images/medeor_logo.png';
import { useTranslation } from 'react-i18next';
import ServiceProvidersModal from './ServiceProvidersModal'; // Import the modal component

function OtherHospitalServices({ services }) {
    const { t } = useTranslation();
    const [selectedService, setSelectedService] = useState(null);

    const handleBookNowClick = (service) => {
        setSelectedService(service); // Set the selected service to show in the modal
    };

    return (
        <div className="tab-pane fade" id="pills-services" role="tabpanel" aria-labelledby="pills-services-tab">
            <div className="row">
                {services?.length > 0 && services.map(service => (
                    <div className="col-md-4 mb-4" key={service._id}>
                        <div className="card service-card">
                            <img
                                src={service.image || PLACEHOLDER_IMAGE}
                                className="card-img-top"
                                alt={service.name}
                            />
                            <div className="card-body">
                                <h5 className="card-title">{service.name}</h5>
                                <p className="card-text description" title={service.description}>{service.description}</p>
                                <div className="badge-container">
                                    {service.isFree ? (
                                        <span className="badge badge-success">{t("Free Service")}</span>
                                    ) : (
                                        <span className="badge badge-primary">
                                            {t("Price")}: {service.price ? `${service.price} AED` : t("Contact for pricing")}
                                        </span>
                                    )}
                                    {service.coveredByInsurance && (
                                        <span className="badge badge-info">{t("Covered by Insurance")}</span>
                                    )}
                                </div>
                                <button
                                    className="btn btn-primary mt-2"
                                    data-toggle="modal"
                                    data-target="#serviceProvidersModal"
                                    onClick={() => handleBookNowClick(service)}
                                >
                                    {t("Book Now")}
                                </button>
                            </div>
                        </div>
                    </div>
                ))}
                {services?.length === 0 && (
                    <div className="col-12">
                        <p>{t("No services available at this time.")}</p>
                    </div>
                )}
            </div>
            {/* Render the modal and pass the selected service */}
            {selectedService && <ServiceProvidersModal service={selectedService} />}
        </div>
    );
}

export default OtherHospitalServices;

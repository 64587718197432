import DashboardLayout from '../../../layout/DashboardLayout'
import MEDEOR_IMAGE from '../../../assets/images/medeor_logo.png';
import DoctorsAppointments from './components/DoctorsAppointments';
import { useContext, useEffect, useState } from 'react'
import { RootContext } from '../../../contextApi';
import HospitalApi from '../../../api/Hospital';
import AllDetails from './components/AllDetails';
import CalculateAppointments from './components/CalculateAppointments';
import { BeatLoader } from "react-spinners"
import REFRESH_ICON from '../../../assets/images/reload.png'
import { getAppointments } from '../../../store/actions/appointmentActions'
import { connect } from 'react-redux';

function Dashboard({ getAppointments, appointments }) {

    const { user } = useContext(RootContext);
    const [hospital, setHospital] = useState();
    const [loading, setLoading] = useState(true);
    const [appointmentLoading, setAppointmentLoading] = useState(true);

    useEffect(() => {
        getHospital();
        getAppointmentsList();
    }, []);

    const getAppointmentsList = async () => {
        if (user?.referenceId) {
            try {
                setAppointmentLoading(true);
                await getAppointments(user.referenceId);
                setAppointmentLoading(false);
            } catch (err) {
                setAppointmentLoading(false);
            }
        }
    }

    const getHospital = () => {
        HospitalApi.getSingleHospital(user.referenceId)
        .then(res => {
            setHospital(res.data.data.hospital);
            setLoading(false);
        })
        .catch(() => setLoading(false));
    }

    return (
        <DashboardLayout>
            <div class="row">
                <div class="col-md-12">
                    <div class="card welcome-block px-lg-3 py-2">
                        <div class="card-body">
                            <div className="row align-items-center">
                                <div className="col-sm-6">
                                    <h4 style={{ color: "#D3D3D3" }}>Welcome to MedicApp,</h4>
                                    <h2>{ loading ? <BeatLoader color="#417EBF" size={12} /> : hospital?.name }</h2>
                                    
                                </div>
                                <div className="col-sm-6 text-sm-right">
                                    <img
                                    style={{ width: '125px', height: '125px', borderRadius: '10px', objectFit: 'cover', border: '1px solid #D3D3D3' }}
                                    src={hospital?.image || MEDEOR_IMAGE}
                                    alt="medeor-logo"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12 col-lg-12 col-xl-6">
                    <div class="card">
                        <div class="card-body">
                            <h4>Today's Appointments</h4>
                            <img className='refresh-icon' onClick={getAppointmentsList} src={REFRESH_ICON} alt='Refresh Icon' />
                            <DoctorsAppointments appointments={appointments} appointmentLoading={appointmentLoading} />
                        </div>
                    </div>
                </div>
                <div class="col-md-12 col-lg-12 col-xl-6">
                    <div class="card top-doctor">
                        <div class="card-body">
                            <div style={{ height: '320px' }}>
                                <AllDetails />
                            </div>
                        </div>
                    </div>
                    <div className="card">
                    <div class="card-body">
                        <div style={{ height: '190px' }}>
                            <div className="row">
                                <div className="col-md-12">
                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
                                        <h4>Total Appointments Booked and Confirmed</h4>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <CalculateAppointments />
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </DashboardLayout>
    )
}

const mapStateToProps = state => ({
    appointments: state.appointments.appointments
})

const mapDispatchToProps = {
    getAppointments
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
import React, { useEffect, useContext, useState } from 'react';
import { href } from '../../../constants/extra';
import DashboardLayout from '../../../layout/DashboardLayout';
import DOCTOR_IMAGE from '../../../assets/images/doctor.png';
import PATIENT_IMAGE from '../../../assets/images/patient.png';
import SendLabResults from './components/SendLabResults';
import { connect } from 'react-redux';
import { getPagesArray } from '../../../Utills/functions';
import { RootContext } from '../../../contextApi';
import classNames from 'classnames';
import { getPendingLabRequests, deletePendingRequest, setPageNumber } from '../../../store/actions/pendingLabRequestActions';
import moment from 'moment';
import { FadeLoading } from '../../../loaders';

function LabRequests({ getPendingLabRequests, deletePendingRequest, pendingRequests, setPageNumber }) {
   const { pageNumber, numberOfPages, pendinglabRequests } = pendingRequests || {};
   const { user } = useContext(RootContext);
   const [selectedRequest, setSelectedRequest] = useState({});
   const [loading, setLoading] = useState(true);

   useEffect(() => {
      getLabRequestsData();
   }, [getPendingLabRequests, pageNumber, user.referenceId]);

   const getLabRequestsData = async () => {
      setLoading(true);
      try {
         await getPendingLabRequests(user.referenceId, pageNumber || 0);
      } catch (err) {
         console.error(err);
      } finally {
         setLoading(false);
      }
   };

   const pages = getPagesArray(numberOfPages);

   const selectRequest = (req) => {
      setSelectedRequest(req);
   };

   return (
      <DashboardLayout>
         <div className="row align-items-center add-list">
            <div className="col-12">
               <h4>Lab Request</h4>
            </div>
         </div>

         <div className="row">
            <div className="col-md-12">
               {loading && pendinglabRequests?.length === 0 && (
                  <div style={{ width: '100%', marginTop: '10%' }}>
                     <FadeLoading />
                  </div>
               )}
               {!loading && pendinglabRequests?.map(req => (
                  <div className="card lab-result mb-4" key={req._id}>
                     <div className="card-body" style={{ padding: "0px 20px" }}>
                        <div className="row align-items-center">
                           <div className="col-md-12 col-lg-9">
                              <ul>
                                 <li>
                                    <small className="d-block">Date</small>
                                    {moment(req.date).format('LL')}
                                 </li>
                                 <li>
                                    <small className="d-block">Tests</small>
                                    {req.tests.map((test, index) => (
                                       <span key={index}>{test.test}{index < req.tests.length - 1 && ', '}</span>
                                    ))}
                                 </li>
                                 <li className="media">
                                    <img className="avatar-sm mr-3" src={DOCTOR_IMAGE} alt="doctor" />
                                    <div className="media-body">
                                       <h5 className="mt-0 mb-1">Dr. {req?.doctorId?.firstName + " " + req?.doctorId?.lastName}</h5>
                                       <p>{req?.doctorId?.specialityId?.map((item, index) => index === req?.doctorId?.specialityId.length - 1 ? item['name_en'] : item['name_en'] + ", ")}</p>
                                    </div>
                                 </li>
                                 <li className="media">
                                    <img className="avatar-sm mr-3" src={PATIENT_IMAGE} alt="patient" />
                                    <div className="media-body">
                                       <h5 className="mt-0 mb-1">{req?.patientId?.firstName + " " + req?.patientId?.lastName}</h5>
                                       <p>Patient</p>
                                    </div>
                                 </li>
                              </ul>
                           </div>
                           <div className="col-md-12 col-lg-3 text-center text-md-right mt-3 mt-md-0">
                              <a href={href} data-toggle="modal" data-target="#sendResults" className="btn btn-primary px-3 py-2" onClick={(e) => { selectRequest(req); }}>
                                 Send Results
                              </a>
                           </div>
                        </div>
                     </div>
                  </div>
               ))}
            </div>
         </div>

         {!loading && (
            <div className="row">
               <div className="col-md-12">
                  {pendinglabRequests?.length > 0 ? (
                     <nav>
                        <ul className="pagination justify-content-center align-items-center my-md-2">
                           <li className="page-item" style={{ pointerEvents: +pageNumber <= 0 && "none" }}>
                              <a href={href} onClick={(e) => { e.preventDefault(); setPageNumber(pageNumber - 1); }}>Prev</a>
                           </li>
                           {pages.map((pageIndex) => (
                              <li className={classNames("page-item", { "active": +pageIndex === pageNumber })} key={pageIndex} onClick={() => setPageNumber(pageIndex)}>
                                 <a className="page-link" href={href} onClick={(e) => e.preventDefault()}>{pageIndex + 1}</a>
                              </li>
                           ))}
                           <li className="page-item" style={{ pointerEvents: +pageNumber === +numberOfPages - 1 && "none" }}>
                              <a href={href} onClick={(e) => { e.preventDefault(); setPageNumber(pageNumber + 1); }}>Next</a>
                           </li>
                        </ul>
                     </nav>
                  ) : (
                     <p>No Lab Requests Found</p>
                  )}
               </div>
            </div>
         )}
         
         <SendLabResults selectedRequest={selectedRequest} />
      </DashboardLayout>
   );
}

const mapStateToProps = (state) => ({
   pendingRequests: state.pendingLabRequests,
});

const mapDispatchToProps = {
   getPendingLabRequests,
   deletePendingRequest,
   setPageNumber,
};

export default connect(mapStateToProps, mapDispatchToProps)(LabRequests);

import React, { useEffect, useRef, useState } from 'react'
import * as Yup from 'yup'
import { Formik, Form } from 'formik'
import { connect } from 'react-redux'
import SelectInput from '../../../../components/forms/SelectInput'
import DoctorApi from '../../../../api/Doctors'
import TextInput from '../../../../components/forms/TextInput'
import LookupApi from '../../../../api/lookups'
import NumberFormatInput from '../../../../components/forms/NumberFormat'
import MultipleSelect from '../../../../components/forms/MultipleSelect'
import NurseApi from '../../../../api/Nurse'
import { toast } from 'react-toastify'

function UpdatedNurseInfo({ selectedNurse, setNurseDetails }) {

    const closeBtnRef = useRef();

    return (
        <Formik
            initialValues={{
                firstName: selectedNurse?.firstName || '',
                lastName: selectedNurse?.lastName || '',
                email: selectedNurse?.email || '',
                mobile: selectedNurse?.mobile || '',
                experience: selectedNurse?.experience || '',
                speciality: selectedNurse?.speciality || '',
                description: selectedNurse?.description || '',
                type: selectedNurse?.type,
            }}
            validationSchema={Yup.object({
                firstName: Yup.string().required('Required'),
                lastName: Yup.string().required('Required'),
                email: Yup.string().required('Required').email(),
                mobile: Yup.string().required('Required'),
                type: Yup.string().required(),
                experience: Yup.string(),
                speciality: Yup.string(),
                description: Yup.string(),
            })}
            onSubmit={async (values, { resetForm }) => {
              const response = await NurseApi.updateNurse(selectedNurse._id, values);
              toast.success("Successfully updated");
              setNurseDetails(response.data.data.nurse);
              closeBtnRef.current.click();
            }}
            enableReinitialize={true}
        >
            <div className="modal fade" id="editNurseBasicInfo" tabIndex="-1" aria-labelledby="editNurseBasicInfoLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-lg">
                    <div className="modal-content">
                        <div className="modal-body">
                            <button ref={closeBtnRef} type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span className="icon-close"></span>
                            </button>
                            <h4 className="text-center">Update Nurse/Technician</h4>
                            <Form>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <TextInput type="text" name="firstName" placeholder="First Name" />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <TextInput type="text" name="lastName" placeholder="Last Name" />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <TextInput type="text" disabled name="email" placeholder="Email" />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <NumberFormatInput
                                                format={"+971-## ### ####"}
                                                mask={"-"}
                                                name="mobile" placeholder="Mobile"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <TextInput type="text" name="experience" placeholder="Experience" />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                      <div className="form-group">
                                          <TextInput type="text" name="speciality" placeholder="Speciality" />
                                      </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                      <div className="form-group">
                                        <TextInput type="text" name="description" placeholder="Description" />
                                      </div>
                                    </div>
                                    <div className="col-md-6">
                                      <div className="form-group">
                                        <SelectInput name="type" style={{ height: "50px" }}>
                                            <option value="">Select Type</option>
                                            {[{ value: "Nurse", label: "NURSE" }, { value: "Technician", label: "TECHNICIAN" }]?.map(typeOfNurse => (
                                                <option key={typeOfNurse.value} value={typeOfNurse.value}>{typeOfNurse.label}</option>
                                            ))}
                                        </SelectInput>
                                      </div>
                                    </div>
                                </div>
                                <div className="form-group text-center mb-0">
                                    <button type="submit" className="btn btn-primary">Confirm</button>
                                </div>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
        </Formik>
    )
}

export default UpdatedNurseInfo;
import React, { useContext, useEffect, useState } from 'react';
import { href } from '../../../constants/extra';
import DashboardLayout from '../../../layout/DashboardLayout';
import { getHospitalServices, deleteHospitalService } from '../../../store/actions/hospitalServiceActions';
import { connect } from 'react-redux';
import AddHospitalService from './components/AddHospitalService';
import { RootContext } from '../../../contextApi';
import { FadeLoading } from '../../../loaders';
import { useConfirmationDialog } from '../../../contextApi/ConfirmationDialogContext';

function HospitalServices({ getHospitalServices, hospitalServices, deleteHospitalService }) {
    const { hospitalServices: allHospitalServices } = hospitalServices && hospitalServices;
    const [selectedService, setSelectedService] = useState(null);
    const { user } = useContext(RootContext);
    const [loading, setLoading] = useState(true);
    const { showConfirmation } = useConfirmationDialog();

    useEffect(() => {
        getServiceData();
    }, [getHospitalServices]);

    const getServiceData = async () => {
        try {
            if (allHospitalServices.length === 0) {
                await getHospitalServices(user.referenceId);
            }
        } catch (err) {
            setLoading(false);
        } finally {
            setLoading(false);
        }
    };

    const deleteServiceHandler = (service) => {
        showConfirmation('Are you sure you want to delete this service?', () => {
            deleteHospitalService(service._id);
        });
    };

    return (
        <div>
            <DashboardLayout>
                <div className="row align-items-center add-list">
                    <div className="col-6">
                        <h4>Hospital Services</h4>
                    </div>
                    <div className="col-6 text-right">
                        <a href={href} onClick={() => { setSelectedService(null); }} data-toggle="modal" data-target="#addHospitalService" className="btn btn-primary px-3">+ ADD SERVICE</a>
                    </div>
                </div>
                <div className="row list-block">
                    {loading && (
                        <div style={{ width: '100%', marginTop: '10%' }}>
                            <FadeLoading />
                        </div>
                    )}
                    {!loading && allHospitalServices?.map((service, key) => (
                        <div key={key} className="col-sm-6 col-md-4 col-lg-4 col-xl-2 custom-col">
                            <div className="card">
                                <img src={service.image} style={{ height: "200px", borderTopLeftRadius: "5px", borderTopRightRadius: "5px", objectFit: "cover", padding: "0px" }} alt="service_image" />
                                <div className="card-body" style={{ padding: "40px 20px 20px" }}>
                                    <div className="media">
                                        <div className="media-body">
                                            <h5 className="mt-0">{service?.name}</h5>
                                            <p className="mt-0">Price: {service?.price || "Free"}</p>
                                            <p className="mt-0">Covered By Insurance: {service?.coveredByInsurance ? "Yes" : "No"}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="dropdown">
                                    <a href={href} id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <span className="icon-dots"></span>
                                    </a>
                                    <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                        <a className="dropdown-item delete-item" href={href} onClick={(e) => { e.preventDefault(); deleteServiceHandler(service); }}>Delete</a>
                                        <a className="dropdown-item" style={{ backgroundColor: "#417EBF" }} href={href} onClick={(e) => { e.preventDefault(); setSelectedService(service); }} data-toggle="modal" data-target="#addHospitalService">Update</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                    {!loading && allHospitalServices?.length === 0 && (
                        <div className="row align-items-center add-list">
                            <div className="col-12 ml-3">
                                <p>No Services Yet.</p>
                            </div>
                        </div>
                    )}
                </div>
                {/* Add Service Modal */}
                <AddHospitalService selectedService={selectedService} />
            </DashboardLayout>
        </div>
    );
}

const mapStateToProps = (state) => ({
    hospitalServices: state.hospitalServices
});

const mapDispatchToProps = {
    getHospitalServices,
    deleteHospitalService,
};

export default connect(mapStateToProps, mapDispatchToProps)(HospitalServices);

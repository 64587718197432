import React, { useContext, useEffect, useState, useRef } from 'react';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import { createHospitalService, updateHospitalService } from '../../../../store/actions/hospitalServiceActions';
import { getDoctors } from '../../../../store/actions/doctorActions';
import { getNurses } from '../../../../store/actions/nurseActions';
import { connect } from 'react-redux';
import TextInput from '../../../../components/forms/TextInput';
import TextArea from '../../../../components/forms/TextArea';
import SelectInput from '../../../../components/forms/SelectInput';
import { RootContext } from '../../../../contextApi';
import { toast } from 'react-toastify';
import HOSPITAL_IMAGE from '../../../../assets/images/medeor_logo.png';
import { BeatLoader } from 'react-spinners';
import GalleryImgePicker from '../../../../components/GalleryImagePicker';
import MultipleSelect from '../../../../components/forms/MultipleSelect';

function AddHospitalService({
  selectedService,
  createHospitalService,
  updateHospitalService,
  doctors,
  nurses,
  getDoctors,
  getNurses
}) {
  const [imageSrc, setImageSrc] = useState(null);
  const [image, setImage] = useState(null);
  const { user } = useContext(RootContext);
  const [loading, setLoading] = useState(false);
  const [loadingDoctors, setLoadingDoctors] = useState(true);
  const [loadingNurses, setLoadingNurses] = useState(true);
  const [selectedDoctors, setSelectedDoctors] = useState([]);
  const [selectedNurses, setSelectedNurses] = useState([]);
  const [selectedTechnicians, setSelectedTechnicians] = useState([]);
  const [key, setKey] = useState(Math.random());
  const closeBtnRef = useRef();

  useEffect(() => {
    const fetchDoctorsAndNurses = async () => {
      try {
        if (doctors.length === 0) {
          await getDoctors(0, user.referenceId, true);
        }
        if (nurses.length === 0) {
          await getNurses(0, true);
        }
      } catch (err) {
        toast.error("Error fetching doctors and nurses");
      } finally {
        setLoadingDoctors(false);
        setLoadingNurses(false);
      }
    };

    fetchDoctorsAndNurses();
  }, [getDoctors, getNurses, doctors.length, nurses.length, user.referenceId]);

  useEffect(() => {
    if (selectedService) {
      setSelectedDoctors(
        selectedService.doctors.map(doc => ({
          label: `Dr. ${doc.firstName} ${doc.lastName}`,
          value: doc._id
        }))
      );
      setSelectedNurses(
        selectedService.nurses.map(nurse => ({
          label: `${nurse.firstName} ${nurse.lastName}`,
          value: nurse._id
        }))
      );
      setSelectedTechnicians(
        selectedService.technicians.map(tech => ({
          label: `${tech.firstName} ${tech.lastName}`,
          value: tech._id
        }))
      );
    }
  }, [selectedService]);

  const profilePictureUpdateHandler = (file) => {
    setImage(file);
    setImageSrc(URL.createObjectURL(file));
  };

  return (
    <Formik
      initialValues={{
        name: selectedService?.name || "",
        description: selectedService?.description || "",
        price: selectedService?.price || "",
        isFree: selectedService?.isFree || false,
        isCoveredByInsurance: selectedService?.coveredByInsurance || false,
      }}
      validationSchema={Yup.object({
        name: Yup.string().required('Required'),
        description: Yup.string().required('Required'),
        price: Yup.number()
          .when('isFree', {
            is: false,
            then: Yup.number()
              .required('Required')
              .positive('Price must be greater than 0')
              .test(
                'is-positive',
                'Price must be greater than 0',
                (value) => value > 0
              ),
          }),
      })}
      onSubmit={async (values, { resetForm }) => {
        let formData = new FormData();
        if (imageSrc && image) {
          formData.append('image', image);
        }

        formData.append('name', values.name);
        formData.append('description', values.description);
        formData.append('price', values.price);
        formData.append('isCoveredByInsurance', values.isCoveredByInsurance);
        formData.append('hospitalId', user.referenceId);
        formData.append('isFree', values.isFree);
        selectedDoctors.forEach((doc) => {
            formData.append('doctors[]', doc.value);
          });
          selectedNurses.forEach((nurse) => {
            formData.append('nurses[]', nurse.value);
          });
          selectedTechnicians.forEach((tech) => {
            formData.append('technicians[]', tech.value);
          });

        try {
          setLoading(true);
          if (selectedService === null) {
            await createHospitalService(formData);
          } else {
            await updateHospitalService(selectedService._id, formData);
          }
          closeBtnRef.current.click();
          resetForm();
          setImageSrc(null);
          setImage(null);
          setSelectedDoctors([]);
          setSelectedNurses([]);
          setSelectedTechnicians([]);
          setKey(Math.random());
        } catch (e) {
          toast.error("Something went wrong");
        } finally {
          setLoading(false);
        }
      }}
      enableReinitialize={true}
    >
      {({ values, setFieldValue }) => (
        <div className="modal fade" id="addHospitalService" tabIndex="-1" aria-labelledby="addServiceLabel" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered modal-lg">
            <div className="modal-content">
              <div className="modal-body">
                <button type="button" ref={closeBtnRef} className="close" data-dismiss="modal" aria-label="Close">
                  <span className="icon-close"></span>
                </button>
                <h4 className="text-center">{selectedService ? "Update" : "Add"} Service</h4>
                <Form>
                  <div className="row">
                    <div className="col-md-12">
                      <div style={{ display: 'flex', justifyContent: "space-between" }}>
                      <div className="form-group" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <div className="new-custom-checkbox-container">
                          <label className="new-custom-checkbox">
                            <input
                              type="radio"
                              name="isFree"
                              value="true"
                              checked={values.isFree === true}
                              onChange={() => setFieldValue("isFree", true)}
                            />
                            <span className="new-custom-checkbox-mark"></span>
                            Free Service
                          </label>
                          <label className="new-custom-checkbox">
                            <input
                              type="radio"
                              name="isFree"
                              value="false"
                              checked={values.isFree === false}
                              onChange={() => setFieldValue("isFree", false)}
                            />
                            <span className="new-custom-checkbox-mark"></span>
                            Paid Service
                          </label>
                        </div>
                      </div>
                      <div className="form-group" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <div className="new-custom-checkbox-container">
                          <label className="new-custom-checkbox">
                            <input
                              type="radio"
                              name="isCoveredByInsurance"
                              value="true"
                              checked={values.isCoveredByInsurance === true}
                              onChange={() => setFieldValue("isCoveredByInsurance", true)}
                            />
                            <span className="new-custom-checkbox-mark"></span>
                            Covered By Insurance
                          </label>
                          <label className="new-custom-checkbox">
                            <input
                              type="radio"
                              name="isCoveredByInsurance"
                              value="false"
                              checked={values.isCoveredByInsurance === false}
                              onChange={() => setFieldValue("isCoveredByInsurance", false)}
                            />
                            <span className="new-custom-checkbox-mark"></span>
                            Not Covered By Insurance
                          </label>
                        </div>
                      </div>
                      </div>
                      <div className="form-group">
                        <TextInput
                          type="text"
                          name="name"
                          placeholder="Service Name"
                        />
                      </div>
                      {!values.isFree && (
                        <div className="form-group">
                          <TextInput
                            type="number"
                            name="price"
                            placeholder="Service Price"
                          />
                        </div>
                      )}
                      <div className="form-group">
                        <label>Select Doctors</label>
                        {loadingDoctors ? (
                          <BeatLoader size={8} color="#417EBF" />
                        ) : (
                          <MultipleSelect
                            options={doctors.map(doc => ({ label: `Dr. ${doc.firstName} ${doc.lastName}`, value: doc._id }))}
                            value={selectedDoctors}
                            changeHandler={setSelectedDoctors}
                          />
                        )}
                      </div>
                      <div className="form-group">
                        <label>Select Nurses</label>
                        {loadingNurses ? (
                          <BeatLoader size={8} color="#417EBF" />
                        ) : (
                          <MultipleSelect
                            options={nurses.filter(nurse => nurse.type === "Nurse").map(nurse => ({ label: `${nurse.firstName} ${nurse.lastName}`, value: nurse._id }))}
                            value={selectedNurses}
                            changeHandler={setSelectedNurses}
                          />
                        )}
                      </div>
                      <div className="form-group">
                        <label>Select Technicians</label>
                        {loadingNurses ? (
                          <BeatLoader size={8} color="#417EBF" />
                        ) : (
                          <MultipleSelect
                            options={nurses.filter(nurse => nurse.type === "Technician").map(nurse => ({ label: `${nurse.firstName} ${nurse.lastName}`, value: nurse._id }))}
                            value={selectedTechnicians}
                            changeHandler={setSelectedTechnicians}
                          />
                        )}
                      </div>
                      <div className="form-group">
                        <TextArea
                          name="description"
                          rows="5"
                          placeholder="Service Description"
                        />
                      </div>
                      <div className="form-group" key={key}>
                        <GalleryImgePicker
                          image={selectedService?.image}
                          updatePicture={profilePictureUpdateHandler}
                          DEFAULTIMAGE={HOSPITAL_IMAGE}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-group text-center mb-0 mt-3">
                    <button type="submit" disabled={loading} className="btn btn-primary">
                      {loading ? (
                        <BeatLoader size={8} />
                      ) : (
                        <>
                          {selectedService ? "Update" : "Save"}
                        </>
                      )}
                    </button>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      )}
    </Formik>
  );
}

const mapStateToProps = (state) => ({
  doctors: state.doctors.doctors,
  nurses: state.nurses.nurses,
});

const mapDispatchToProps = {
  createHospitalService,
  updateHospitalService,
  getDoctors,
  getNurses,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddHospitalService);

export const NurseRoutes = [
    {
        name: "Appointments",
        icon: "icon-patient",
        route: "/nurse"
    },
    {
        name: "My Schedule",
        icon: "icon-patient",
        route: "/nurse/schedule"
    },
    {
        name: "Doctor's Schedule",
        icon: "icon-patient",
        route: "/nurse/doctor-schedule"
    }
]
import React, { useEffect, useRef, useState } from 'react'
import * as Yup from 'yup'
import { Formik, Form } from 'formik'
import { connect } from 'react-redux'
import SelectInput from '../../../../components/forms/SelectInput'
import DoctorApi from '../../../../api/Doctors'
import TextInput from '../../../../components/forms/TextInput'
import LookupApi from '../../../../api/lookups'
import NumberFormatInput from '../../../../components/forms/NumberFormat'
import MultipleSelect from '../../../../components/forms/MultipleSelect'
import ReceptionistApi from '../../../../api/Receptionist'
import { toast } from 'react-toastify'

function UpdatedReceptionistInfo({ selectedReceptionist, setReceptionistDetails }) {

    const closeBtnRef = useRef();

    return (
        <Formik
            initialValues={{
                firstName: selectedReceptionist?.firstName || '',
                lastName: selectedReceptionist?.lastName || '',
                email: selectedReceptionist?.email || '',
                mobile: selectedReceptionist?.mobile || '',
            }}
            validationSchema={Yup.object({
                firstName: Yup.string().required('Required'),
                lastName: Yup.string().required('Required'),
                email: Yup.string().required('Required').email(),
                mobile: Yup.string().required('Required'),
            })}
            onSubmit={async (values, { resetForm }) => {
              const response = await ReceptionistApi.updateReceptionist(selectedReceptionist._id, values);
              toast.success("Successfully updated");
              setReceptionistDetails(response.data.data.receptionist);
              closeBtnRef.current.click();
            }}
            enableReinitialize={true}
        >
            <div className="modal fade" id="editReceptionistBasicInfo" tabIndex="-1" aria-labelledby="editReceptionistBasicInfoLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-lg">
                    <div className="modal-content">
                        <div className="modal-body">
                            <button ref={closeBtnRef} type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span className="icon-close"></span>
                            </button>
                            <h4 className="text-center">Update Receptionist/Technician</h4>
                            <Form>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <TextInput type="text" name="firstName" placeholder="First Name" />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <TextInput type="text" name="lastName" placeholder="Last Name" />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <TextInput type="text" disabled name="email" placeholder="Email" />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <NumberFormatInput
                                                format={"+971-## ### ####"}
                                                mask={"-"}
                                                name="mobile" placeholder="Mobile"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group text-center mb-0">
                                    <button type="submit" className="btn btn-primary">Confirm</button>
                                </div>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
        </Formik>
    )
}

export default UpdatedReceptionistInfo;
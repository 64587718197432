import React from 'react';
import './index.css';

function Table({ headers, children }) {
  return (
    <table className='new-table'>
      <thead className='new-table-head'>
        <tr className='new-table-row'>
          {headers.map((header, index) => (
            <td key={index} className='new-table-cell new-table-header'>
              {header}
            </td>
          ))}
        </tr>
      </thead>
      {children}
    </table>
  );
}

export default Table;

import React, { useEffect, useContext, useState, useRef } from 'react';
import DashboardLayout from '../../../layout/DashboardLayout';
import DOCTOR_IMAGE from '../../../assets/images/doctor.png';
import { href } from '../../../constants/extra';
import { RootContext } from '../../../contextApi';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import Table from '../../../components/Table';
import NurseApi from '../../../api/Nurse';
import AppointmentApi from '../../../api/Appointment';
import { FadeLoading } from '../../../loaders';
import DateFilter from '../../../components/forms/DateFilter';
import { SlotStatus } from '../../../constants/Slots';
import { toast } from 'react-toastify';
import { useConfirmationDialog } from '../../../contextApi/ConfirmationDialogContext';
import { BeatLoader } from 'react-spinners';

function NurseAppointments() {
  const { user } = useContext(RootContext);
  const [appointments, setAppointments] = useState([]);
  const [date, setDate] = useState(moment().format('YYYY-MM-DD'));
  const [emiratesId, setEmiratesId] = useState('');
  const [loading, setLoading] = useState(true);
  const { showConfirmation } = useConfirmationDialog();
  const [endTreatmentLoading, setEndTreatmentLoading] = useState(false);
  const [endTreatmentId, setEndTreatmentId] = useState(null);
  const [selectedService, setSelectedService] = useState(null);
  const serviceDetailRef = useRef()

  const history = useHistory();

  useEffect(() => {
    getNurseAppointments();
  }, [user.referenceId, date, emiratesId]);

  const getNurseAppointments = async () => {
    try {
      setLoading(true);
      const nurse = await NurseApi.getSingleNurse(user.referenceId);
      const hospitalId = nurse?.data?.data?.hospitalId?._id;
      const response = await AppointmentApi.getHospitalAppointment(hospitalId, date, emiratesId);
  
      // Filter out appointments based on the logic provided
      const filteredAppointments = response.data.data.filter((appointment) => {
        if (![SlotStatus.BOOKED, SlotStatus.APPROVED].includes(appointment.status)) return false;
  
        if (appointment.serviceId) {
          // For service type appointments, check if the nurseId matches the logged-in user and the type is "NURSE"
          return appointment.nurseId?._id === user.referenceId && appointment.type === "NURSE";
        }
  
        // If the appointment is not a service type, include it
        return !appointment.serviceId;
      });
  
      setAppointments(filteredAppointments);
    } catch (error) {
      setAppointments([]);
    } finally {
      setLoading(false);
    }
  };
  
  const handleEmiratesIdChange = (e) => {
    setEmiratesId(e.target.value);
  };

  const endTreatmentHandler = (appointmentId) => {
    showConfirmation('Are you sure you want to end the treatment?', async () => {
      try {
        setEndTreatmentLoading(true);
        setEndTreatmentId(appointmentId);
        await AppointmentApi.updateAppointment(appointmentId, { status: SlotStatus.TAKEN });
        toast.success("Treatment ended successfully!");
        getNurseAppointments();
      } catch (error) {
        toast.error("Failed to end treatment. Please try again.");
      } finally {
        setEndTreatmentLoading(false);
        setEndTreatmentId(null);
      }
    });
  };

  return (
    <DashboardLayout>
      <div className="row">
        <div className="col-md-12">
          <div className="card welcome-block px-lg-3 py-2">
            <div className="card-body">
              <div className="row align-items-center">
                <div className="col-sm-6">
                  <h4 style={{ color: "#D3D3D3" }}>Welcome to MedicApp,</h4>
                  <h2>{user?.firstName + " " + user?.lastName}</h2>
                </div>
                <div className="col-sm-6 text-sm-right">
                  <img
                    style={{ width: '125px', height: '125px', borderRadius: '10px', objectFit: 'cover', border: '1px solid #D3D3D3' }}
                    src={user?.image ? user?.image : DOCTOR_IMAGE}
                    alt="doctor-logo"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <div className="card">
            <div className="card-body">
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <h4>Appointments</h4>
                <div style={{ display: "flex", gap: "10px" }}>
                  <DateFilter date={date} onDateChange={setDate} />
                  <input
                    type="text"
                    name="emiratesIdFilter"
                    className={"form-control"}
                    style={{ width: "12rem" }}
                    placeholder="Emirate ID"
                    value={emiratesId}
                    onChange={handleEmiratesIdChange}
                  />
                </div>
              </div>
              <div className="scrollbar" style={{ height: "300px", marginTop: '2rem' }}>
                <div className="table-responsive">
                  <Table headers={["Provider", "PATIENT NAME", "EMIRATES ID", "SERVICE", "DATE", "TIME", ""]}>
                    <tbody className='new-table-body'>
                      {!loading && appointments?.filter((app) => app.patientId !== null)?.map(appointment => (
                        <tr key={appointment._id} className='new-table-row'>
                          <td className='new-table-cell'>
                            {appointment.doctorId ? (
                              `Dr. ${appointment?.doctorId?.firstName} ${appointment?.doctorId?.lastName}`
                            ) : (
                              `${appointment?.nurseId?.firstName} ${appointment?.nurseId?.lastName}`
                            )}
                          </td>
                          <td className='new-table-cell'>{appointment?.patientId?.firstName + " " + appointment?.patientId?.lastName}</td>
                          <td className='new-table-cell'>{appointment?.patientId?.emiratesId}</td>
                          <td className='new-table-cell'>
                            {appointment.serviceId ? (
                              <a
                                href={href}
                                onClick={(e) => {
                                  e.preventDefault();
                                  setSelectedService(appointment.serviceId);
                                  serviceDetailRef.current.click();
                                }}
                                style={{ textDecoration: 'underline', cursor: 'pointer', color: 'blue' }}
                              >
                                {appointment.serviceId.name}
                              </a>
                            ) : "N/A"}
                          </td>
                          <td className='new-table-cell'>{`${moment(appointment.from).format("DD-MM-YY")}`}</td>
                          <td className='new-table-cell'>{moment(appointment.from).format('hh:mm a')} - {moment(appointment.to).format('hh:mm a')}</td>
                          <td className='new-table-cell'>
                            {appointment.serviceId && appointment.nurseId?._id === user.referenceId && appointment.type === "NURSE" ? (
                              <a
                                href={href}
                                onClick={(e) => {
                                  e.preventDefault();
                                  endTreatmentHandler(appointment._id);
                                }}
                                className="btn btn-danger px-3"
                              >
                                {
                                  endTreatmentLoading && endTreatmentId === appointment._id ? (
                                    <BeatLoader size={8} />
                                  ) : (
                                    <>End Treatment</>
                                  )
                                } 
                              </a>
                            ) : (
                              <a
                                href={href}
                                onClick={() => {
                                  history.push(`/nurse/patient-info/${appointment.patientId._id}/${appointment._id}`);
                                }}
                                className="btn btn-primary px-3"
                              >
                                Start Diagnosis
                              </a>
                            )}
                          </td>
                        </tr>
                      ))}
                      {!loading && appointments?.length === 0 && (
                        <tr className='new-table-row'>
                          No appointment found
                        </tr>
                      )}
                    </tbody>
                  </Table>
                  {loading && (
                    <div style={{ width: '100%', marginTop: '7%' }}>
                      <FadeLoading />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Trigger Modal */}
      <a
        href={href}
        id="serviceDetailsModal"
        data-toggle="modal"
        data-target="#serviceModal"
        style={{ display: 'none' }}
        ref={serviceDetailRef}
      >
        Open Modal
      </a>
      
        <div className="modal fade" id="serviceModal" tabIndex="-1" aria-labelledby="serviceModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered modal-lg">
            <div className="modal-content">
              <div className="modal-body">
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span className="icon-close"></span>
                </button>
                <h4 className="text-center">Service Details</h4>
                <div style={{ textAlign: 'center' }}>
                  <table style={{ border: '1px solid gray', padding: '7px', width: '100%' }}>
                    <thead style={{ border: '1px solid gray', padding: '7px' }}>
                      <tr style={{ border: '1px solid gray', padding: '7px' }}>
                        <td style={{ border: '1px solid gray', padding: '7px', fontWeight: 'bold' }}>Service Name</td>
                        <td style={{ border: '1px solid gray', padding: '7px', fontWeight: 'bold' }}>Price</td>
                        <td style={{ border: '1px solid gray', padding: '7px', fontWeight: 'bold' }}>Covered by Insurance</td>
                      </tr>
                    </thead>
                    <tbody style={{ border: '1px solid gray', padding: '7px' }}>
                      <tr style={{ border: '1px solid gray', padding: '7px' }}>
                        <td style={{ border: '1px solid gray', padding: '7px' }}>{selectedService?.name}</td>
                        <td style={{ border: '1px solid gray', padding: '7px' }}>{selectedService?.isFree ? "Free" : selectedService?.price + " AED"} </td>
                        <td style={{ border: '1px solid gray', padding: '7px' }}>
                          {selectedService?.coveredByInsurance ? 'Yes' : 'No'}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div className="mt-4">
                    <h5>Description</h5>
                    <p>{selectedService?.description}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    </DashboardLayout>
  );
}

export default NurseAppointments;

import React, { useEffect, useContext, useState } from 'react';
import DashboardLayout from '../../../layout/DashboardLayout';
import DOCTOR_IMAGE from '../../../assets/images/doctor.png';
import { href } from '../../../constants/extra';
import { RootContext } from '../../../contextApi';
import { getDoctorAppointments, deleteDoctorAppointment, setPageNumber } from '../../../store/actions/doctorAppointmentActions';
import { connect } from 'react-redux';
import { getPagesArray } from '../../../Utills/functions';
import classNames from 'classnames';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import DoctorApi from '../../../api/Doctors';
import AppointmentApi from '../../../api/Appointment';
import Table from '../../../components/Table';
import { FadeLoading } from '../../../loaders';
import { BeatLoader } from 'react-spinners';
import { SlotStatus } from '../../../constants/Slots';
import { toast } from 'react-toastify';
import { useConfirmationDialog } from '../../../contextApi/ConfirmationDialogContext';

function Appointments({ doctorAppointments, getDoctorAppointments, setPageNumber, deleteDoctorAppointment }) {
  const { user } = useContext(RootContext);
  const [QRCodeValue, setQRCodeValue] = useState(null);
  const [selectedAppointment, setSelectedAppointment] = useState({});
  const [loading, setLoading] = useState(true);
  const [doctor, setDoctor] = useState();
  const [selectedService, setSelectedService] = useState(null);
  const [endTreatmentLoading, setEndTreatmentLoading] = useState(false);
  const { showConfirmation } = useConfirmationDialog();
  const { pageNumber, numberOfPages, appointments } = doctorAppointments;
  const history = useHistory();

  useEffect(() => {
    getDoctorAppointmentsData();
  }, [getDoctorAppointments, pageNumber, user.referenceId]);

  const getDoctorAppointmentsData = async () => {
    try {
      setLoading(true);
      await getDoctorAppointments(user.referenceId, pageNumber || 0);
    } catch (err) {} finally {
      setLoading(false);
    }
  };

  const onStartTreatment = (appointment) => {
    setQRCodeValue(null);
    setSelectedAppointment(appointment);
  };

  const endTreatmentHandler = async (appointmentId) => {
    showConfirmation('Are you sure you want to end the treatment?', async () => {
      setEndTreatmentLoading(true);
      try {
        await AppointmentApi.updateAppointment(appointmentId, { status: SlotStatus.TAKEN });
        toast.success("Treatment ended successfully!");
        getDoctorAppointmentsData();
      } catch (error) {
        toast.error("Failed to end treatment. Please try again.");
      } finally {
        setEndTreatmentLoading(false);
      }
    });
  };

  useEffect(() => {
    getDoctor();
  }, []);

  const getDoctor = () => {
    DoctorApi.getSingleDoctor(user.referenceId).then(res => {
      setDoctor(res.data.data);
    });
  };

  const pages = getPagesArray(numberOfPages);

  return (
    <DashboardLayout>
      <div className="row">
        <div className="col-md-12">
          <div className="card welcome-block px-lg-3 py-2">
            <div className="card-body">
              <div className="row align-items-center">
                <div className="col-sm-6">
                  <h4 style={{ color: "#D3D3D3" }}>Welcome to MedicApp,</h4>
                  <h2>
                    Dr. {loading ? <BeatLoader color="#417EBF" size={8} /> : `${doctor?.firstName + " " + doctor?.lastName}`}
                  </h2>
                </div>
                <div className="col-sm-6 text-sm-right">
                  <img
                    style={{ width: '125px', height: '125px', borderRadius: '10px', objectFit: 'cover', border: '1px solid #D3D3D3' }}
                    src={doctor?.image ? doctor?.image : DOCTOR_IMAGE}
                    alt="doctor-logo"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <div className="card">
            <div className="card-body">
              <div className="scrollbar" style={{ height: "360px" }}>
                <div className="table-responsive">
                  <Table headers={["Patient Name", "Emirates ID Number", "Date", "Time", "Service", ""]}>
                    <tbody className="new-table-body">
                      {!loading && appointments?.map(appointment => (
                        <tr key={appointment._id} className="new-table-row">
                          <td className="new-table-cell">{appointment?.patientId?.firstName + " " + appointment?.patientId?.lastName}</td>
                          <td className="new-table-cell">{appointment?.patientId?.emiratesId}</td>
                          <td className="new-table-cell">{moment(appointment.from).format("DD-MM-YY")}</td>
                          <td className="new-table-cell">{moment(appointment.from).format('hh:mm a')} - {moment(appointment.to).format('hh:mm a')}</td>
                          <td className="new-table-cell">
                            {appointment.serviceId ? (
                              <a
                                href={href}
                                onClick={(e) => {
                                  e.preventDefault();
                                  setSelectedService(appointment.serviceId);
                                  document.getElementById('serviceDetailsModal').click();
                                }}
                                style={{ textDecoration: 'underline', cursor: 'pointer', color: 'blue' }}
                              >
                                {appointment.serviceId.name}
                              </a>
                            ) : "N/A"}
                          </td>
                          <td className="new-table-cell">
                            {appointment.serviceId && appointment.doctorId?._id === user.referenceId ? (
                              <a
                                href={href}
                                onClick={(e) => {
                                  e.preventDefault();
                                  endTreatmentHandler(appointment._id);
                                }}
                                className="btn btn-danger px-3"
                              >
                                {endTreatmentLoading && selectedAppointment._id === appointment._id ? (
                                  <BeatLoader size={8} />
                                ) : (
                                  <>End Treatment</>
                                )}
                              </a>
                            ) : (
                              appointment.status === SlotStatus.BOOKED && (
                                <a
                                  href={href}
                                  onClick={() => {
                                    history.push(`/doctors/patient-info/${appointment.patientId._id}/${appointment._id}`);
                                  }}
                                  className="btn btn-primary px-3"
                                >
                                  Start Treatment
                                </a>
                              )
                            )}
                          </td>
                        </tr>
                      ))}
                      {!loading && appointments?.length === 0 && (
                        <tr className="new-table-row">
                          No appointment found
                        </tr>
                      )}
                    </tbody>
                  </Table>
                  {loading && appointments?.length === 0 && (
                    <div style={{ width: '100%', marginTop: '10%' }}>
                      <FadeLoading />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          {appointments?.length > 0 ? (
            <nav>
              <ul className="pagination justify-content-center align-items-center my-md-2">
                <li className="page-item" style={{ pointerEvents: +pageNumber <= 0 && "none" }}>
                  <a href={href} onClick={(e) => { e.preventDefault(); setPageNumber(pageNumber - 1) }}>Prev</a>
                </li>
                {pages.map((pageIndex) => (
                  <li className={classNames("page-item", { "active": +pageIndex === pageNumber })} key={pageIndex} onClick={() => setPageNumber(pageIndex)}>
                    <a className="page-link" href={href} onClick={(e) => e.preventDefault()}>{pageIndex + 1}</a>
                  </li>
                ))}
                <li className="page-item" style={{ pointerEvents: +pageNumber === +numberOfPages - 1 && "none" }}>
                  <a href={href} onClick={(e) => { e.preventDefault(); setPageNumber(pageNumber + 1) }}>Next</a>
                </li>
              </ul>
            </nav>
          ) : (
            <p>No appointments yet</p>
          )}
        </div>
      </div>

      {/* Trigger Modal */}
      <a
        href={href}
        id="serviceDetailsModal"
        data-toggle="modal"
        data-target="#serviceModal"
        style={{ display: 'none' }}
      >
        Open Modal
      </a>

      <div className="modal fade" id="serviceModal" tabIndex="-1" aria-labelledby="serviceModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered modal-lg">
            <div className="modal-content">
              <div className="modal-body">
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span className="icon-close"></span>
                </button>
                <h4 className="text-center">Service Details</h4>
                <div style={{ textAlign: 'center' }}>
                  <table style={{ border: '1px solid gray', padding: '7px', width: '100%' }}>
                    <thead style={{ border: '1px solid gray', padding: '7px' }}>
                      <tr style={{ border: '1px solid gray', padding: '7px' }}>
                        <td style={{ border: '1px solid gray', padding: '7px', fontWeight: 'bold' }}>Service Name</td>
                        <td style={{ border: '1px solid gray', padding: '7px', fontWeight: 'bold' }}>Price</td>
                        <td style={{ border: '1px solid gray', padding: '7px', fontWeight: 'bold' }}>Covered by Insurance</td>
                      </tr>
                    </thead>
                    <tbody style={{ border: '1px solid gray', padding: '7px' }}>
                      <tr style={{ border: '1px solid gray', padding: '7px' }}>
                        <td style={{ border: '1px solid gray', padding: '7px' }}>{selectedService?.name}</td>
                        <td style={{ border: '1px solid gray', padding: '7px' }}>{selectedService?.isFree ? "Free" : selectedService?.price + " AED"} </td>
                        <td style={{ border: '1px solid gray', padding: '7px' }}>
                          {selectedService?.coveredByInsurance ? 'Yes' : 'No'}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div className="mt-4">
                    <h5>Description</h5>
                    <p>{selectedService?.description}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    </DashboardLayout>
  );
}

const mapStateToProps = (state) => ({
  doctorAppointments: state.doctorAppointments
});

const mapDispatchToProps = {
  getDoctorAppointments,
  deleteDoctorAppointment,
  setPageNumber,
};

export default connect(mapStateToProps, mapDispatchToProps)(Appointments);

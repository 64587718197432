import React, { useEffect, useState } from "react";
import DashboardLayout from "../../../../layout/DashboardLayout";
import NurseApi from "../../../../api/Nurse";
import { useParams } from "react-router-dom";
import { href } from "../../../../constants/extra";
import PLACEHOLDER_NURSE_IMAGE from "../../../../assets/images/doctor_placeholder.png"; // Replace with a nurse image if available
import UpdatedNurseInfo from "./UpdatedNurseInfo";
import moment from "moment";
import NurseReportApi from "../../../../api/NurseReport";
import Table from "../../../../components/Table";
import { BeatLoader } from "react-spinners";
import { FadeLoading } from "../../../../loaders";
import SetNurseSchedule from "./SetNurseSchedule"; // Import the new SetNurseSchedule component
import classNames from "classnames";

function NurseInfo() {
  const { id } = useParams();
  const [nurseDetails, setNurseDetails] = useState(null);
  const [date, setDate] = useState(moment().format("YYYY-MM-DD"));
  const [nurseReports, setNurseReports] = useState([]);
  const [infoLoading, setInfoLoading] = useState(true);
  const [reportLoading, setReportLoading] = useState(true);
  const [tabSelected, setTabSelected] = useState("Profile"); // State to handle tabs

  useEffect(() => {
    getNurseInfo();
  }, [id]);

  useEffect(() => {
    setReportLoading(true);
    NurseReportApi.getAllNursesReports(date, id)
      .then((res) => {
        setNurseReports(res.data.data.reports);
      })
      .catch((error) => {
        console.error("Error fetching nurse reports:", error);
      })
      .finally(() => {
        setReportLoading(false);
      });
  }, [date, id]);

  const getNurseInfo = async () => {
    NurseApi.getSingleNurse(id)
      .then((res) => {
        setNurseDetails(res.data.data);
      })
      .finally(() => {
        setInfoLoading(false);
      });
  };

  const handleDateChange = (e) => {
    setDate(e.target.value);
  };

  return (
    <DashboardLayout>
      <div className="row nav-tab-link">
        <div className="col-md-12">
          <ul className="nav justify-content-center">
            <li className="nav-item">
              <a
                className={classNames("nav-link", { active: tabSelected === "Profile" })}
                href={href}
                onClick={(e) => {
                  e.preventDefault();
                  setTabSelected("Profile");
                }}
              >
                Nurse Profile
              </a>
            </li>
            <li className="nav-item">
              <a
                className={classNames("nav-link", { active: tabSelected === "Schedule" })}
                href={href}
                onClick={(e) => {
                  e.preventDefault();
                  setTabSelected("Schedule");
                }}
              >
                Schedule
              </a>
            </li>
          </ul>
        </div>
      </div>

      {tabSelected === "Schedule" ? (
        <SetNurseSchedule />
      ) : (
        <>
          <div className="row align-items-center add-list">
            <div className="col-6">
              <h4 style={{ textTransform: "capitalize" }}>{nurseDetails?.type} Profile</h4>
            </div>
          </div>
          <div className="row doctor-profile">
            <div className="col-md-4">
              <div className="card profile-detail py-3">
                <div className="card-body">
                  <div className="media">
                    <a
                      href={href}
                      data-toggle="modal"
                      className="edit-doctor-link"
                      data-target="#editNurseBasicInfo"
                    >
                      EDIT
                    </a>
                    <img
                      className="avatar-lg mr-0"
                      src={PLACEHOLDER_NURSE_IMAGE}
                      alt="nurse"
                    />
                    <div className="media-body">
                      <h5 className="mt-3 mb-2">
                        {infoLoading ? (
                          <BeatLoader color="#417EBF" size={10} />
                        ) : (
                          `${nurseDetails?.firstName} ${nurseDetails?.lastName}`
                        )}
                      </h5>
                      <div className="contact-info">
                        <a href={`mailto:${nurseDetails?.email}`}>
                          <span className="icon-email"></span>
                        </a>
                        <a href={`tel:${nurseDetails?.mobile}`}>
                          <span className="icon-phone"></span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card patient-detail">
                <div className="card-body">
                  <h5>Information: </h5>
                  <ul>
                    <li>
                      <span>Speciality: </span>
                      <p>{nurseDetails?.speciality}</p>
                    </li>
                    <li>
                      <span>Type: </span>
                      <p>{nurseDetails?.type}</p>
                    </li>
                    <li>
                      <span>Description: </span>
                      <p>{nurseDetails?.description}</p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-8">
              <div className="card">
                <div className="card-body">
                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <h4>{nurseDetails?.type}'s Reports</h4>
                    <input
                      type="date"
                      name="appointmentFilter"
                      className="form-control"
                      style={{ width: "12rem" }}
                      value={date}
                      onChange={handleDateChange}
                    />
                  </div>
                  <div className="scrollbar" style={{ height: "300px" }}>
                    <div className="table-responsive" style={{ marginTop: "1rem" }}>
                      <Table headers={["Patient Name", "Appointment (Date)", "Appointment (Time)", "Patient BP", "Patient BT"]}>
                        <tbody className="new-table-body">
                          {!reportLoading &&
                            nurseReports?.map((report) => (
                              <tr key={report._id} className="new-table-row">
                                <td className="new-table-cell">
                                  {report?.patientId?.firstName + " " + report?.patientId?.lastName}
                                </td>
                                <td className="new-table-cell">
                                  {moment(report?.appointmentId?.from).format("DD-MM-YY")}
                                </td>
                                <td className="new-table-cell">
                                  {moment(report?.appointmentId?.from).format("hh:mm a")} -{" "}
                                  {moment(report?.appointmentId?.to).format("hh:mm a")}
                                </td>
                                <td className="new-table-cell">{report?.bp}</td>
                                <td className="new-table-cell">{report?.bt}</td>
                              </tr>
                            ))}
                          {!reportLoading && nurseReports.length === 0 && (
                            <td className="new-table-cell">No Nurse Reports Found</td>
                          )}
                        </tbody>
                      </Table>
                      {reportLoading && (
                        <div style={{ width: "100%", marginTop: "7%" }}>
                          <FadeLoading />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <UpdatedNurseInfo
            selectedNurse={nurseDetails}
            setNurseDetails={setNurseDetails}
          />
        </>
      )}
    </DashboardLayout>
  );
}

export default NurseInfo;

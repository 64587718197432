import React, { useContext, useEffect, useState } from 'react';
import DashboardLayout from '../../../layout/DashboardLayout';
import CodesApi from '../../../api/Codes';
import { RootContext } from '../../../contextApi';
import moment from 'moment';
import 'moment-timezone';
import { FadeLoading } from '../../../loaders';
import { href } from '../../../constants/extra';

function PatientPromoCode() {
	const [hospitalCodes, setHospitalCodes] = useState([]);
	const [search, setSearch] = useState('');
	const { user } = useContext(RootContext);
	const [searchedHospitalCodes, setSearchedHospitalCodes] = useState([]);
	const [loading, setLoading] = useState(true);
	const [selectedService, setSelectedService] = useState(null);

	const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

	useEffect(() => {
		getPromoCodes();
	}, []);

	const getPromoCodes = async () => {
		CodesApi.getAllHospitalCodes()
			.then(res => {
				setHospitalCodes(res.data.data);
				setSearchedHospitalCodes(res.data.data);
				setLoading(false);
			})
			.catch(err => {
				setLoading(false);
			});
	};

	const handleSearch = (e) => {
		const searchText = e.target.value;
		setSearch(searchText);
		if (searchText === '') {
			setSearchedHospitalCodes(hospitalCodes);
		} else {
			setSearchedHospitalCodes(
				hospitalCodes.filter(code =>
					code?.patientId?.emiratesId.includes(searchText)
				)
			);
		}
	};

	return (
		<DashboardLayout>
			<div className="row align-items-center add-list">
				<div className="col-12">
					<h4>Patient Promo Code</h4>
					<br />
					<div style={{ textAlign: 'center' }}>
						<div className="col-md-12">
							<div className="form-group">
								<input
									type="text"
									className="form-control"
									name="search"
									value={search}
									onChange={handleSearch}
									placeholder="Search Patient with Emirates ID"
								/>
							</div>
						</div>
						<table
							style={{ border: '1px solid gray', padding: '7px', width: '100%' }}
						>
							<thead style={{ border: '1px solid gray', padding: '7px' }}>
								<tr style={{ border: '1px solid gray', padding: '7px' }}>
									<td
										style={{
											fontSize: '1.2rem',
											border: '1px solid gray',
											padding: '7px',
											fontWeight: 'bold'
										}}
									>
										Patient Name
									</td>
									<td
										style={{
											fontSize: '1.2rem',
											border: '1px solid gray',
											padding: '7px',
											fontWeight: 'bold'
										}}
									>
										Appointment Date
									</td>
									<td
										style={{
											fontSize: '1.2rem',
											border: '1px solid gray',
											padding: '7px',
											fontWeight: 'bold'
										}}
									>
										Patient Emirates ID
									</td>
									<td
										style={{
											fontSize: '1.2rem',
											border: '1px solid gray',
											padding: '7px',
											fontWeight: 'bold'
										}}
									>
										Provider
									</td>
									<td
										style={{
											fontSize: '1.2rem',
											border: '1px solid gray',
											padding: '7px',
											fontWeight: 'bold'
										}}
									>
										Service
									</td>
									<td
										style={{
											fontSize: '1.2rem',
											border: '1px solid gray',
											padding: '7px',
											fontWeight: 'bold'
										}}
									>
										Code
									</td>
									<td
										style={{
											fontSize: '1.2rem',
											border: '1px solid gray',
											padding: '7px',
											fontWeight: 'bold'
										}}
									>
										Status
									</td>
								</tr>
							</thead>
							<tbody style={{ border: '1px solid gray', padding: '7px' }}>
								{searchedHospitalCodes?.map(code => (
									<tr key={code._id} style={{ border: '1px solid gray', padding: '7px' }}>
										<td style={{ border: '1px solid gray', padding: '7px' }}>
											{code?.patientId?.firstName + ' ' + code?.patientId?.lastName}
										</td>
										<td style={{ border: '1px solid gray', padding: '7px' }}>
											{`${moment.tz(code?.slotId?.from, timezone).format(
												'DD/MM/YYYY'
											)} - ( ${moment
												.tz(code?.slotId?.from, timezone)
												.format('hh:mm a')} - ${moment
												.tz(code?.slotId?.to, timezone)
												.format('hh:mm a')} )`}
										</td>
										<td style={{ border: '1px solid gray', padding: '7px' }}>
											{code?.patientId?.emiratesId}
										</td>
										<td style={{ border: '1px solid gray', padding: '7px' }}>
											{code.slotId?.nurseId
												? `${code.slotId?.nurseId?.firstName} ${code.slotId?.nurseId?.lastName}`
												: `Dr. ${code.slotId?.doctorId?.firstName} ${code.slotId?.doctorId?.lastName}`}
										</td>
										<td style={{ border: '1px solid gray', padding: '7px' }}>
											{code?.slotId?.serviceId ? (
												<a
													href={href}
													onClick={e => {
														e.preventDefault();
														setSelectedService(code?.slotId?.serviceId);
														document.getElementById('serviceDetailsModal').click();
													}}
													style={{ textDecoration: 'underline', cursor: 'pointer', color: 'blue' }}
												>
													{code?.slotId?.serviceId.name}
												</a>
											) : (
												'Regular Appointment'
											)}
										</td>
										<td style={{ border: '1px solid gray', padding: '7px' }}>
											{code?.code}
										</td>
										<td
											style={{
												border: '1px solid gray',
												padding: '7px',
												color: 'white'
											}}
											className={
												code?.status === 'TAKEN' ? 'bg-success' : 'bg-danger'
											}
										>
											{code?.status}
										</td>
									</tr>
								))}
							</tbody>
						</table>

						<br />

						{!loading && search === '' && hospitalCodes.length === 0 && (
							<p>No Promo Codes</p>
						)}

						{!loading && search !== '' && searchedHospitalCodes.length === 0 && (
							<p>Search Not Found</p>
						)}

						{loading && (
							<div style={{ width: '100%', marginTop: '10%' }}>
								<FadeLoading />
							</div>
						)}
					</div>
				</div>
			</div>

			{/* Trigger Modal */}
			<a
				href={href}
				id="serviceDetailsModal"
				data-toggle="modal"
				data-target="#serviceModal"
				style={{ display: 'none' }}
			>
				Open Modal
			</a>

			{/* Service Details Modal */}
			<div
				className="modal fade"
				id="serviceModal"
				tabIndex="-1"
				aria-labelledby="serviceModalLabel"
				aria-hidden="true"
			>
				<div className="modal-dialog modal-dialog-centered modal-lg">
					<div className="modal-content">
						<div className="modal-body">
							<button
								type="button"
								className="close"
								data-dismiss="modal"
								aria-label="Close"
							>
								<span className="icon-close"></span>
							</button>
							<h4 className="text-center">Service Details</h4>
							<div style={{ textAlign: 'center' }}>
								<table
									style={{
										border: '1px solid gray',
										padding: '7px',
										width: '100%'
									}}
								>
									<thead style={{ border: '1px solid gray', padding: '7px' }}>
										<tr style={{ border: '1px solid gray', padding: '7px' }}>
											<td
												style={{
													border: '1px solid gray',
													padding: '7px',
													fontWeight: 'bold'
												}}
											>
												Service Name
											</td>
											<td
												style={{
													border: '1px solid gray',
													padding: '7px',
													fontWeight: 'bold'
												}}
											>
												Price
											</td>
											<td
												style={{
													border: '1px solid gray',
													padding: '7px',
													fontWeight: 'bold'
												}}
											>
												Covered by Insurance
											</td>
										</tr>
									</thead>
									<tbody style={{ border: '1px solid gray', padding: '7px' }}>
										<tr style={{ border: '1px solid gray', padding: '7px' }}>
											<td
												style={{
													border: '1px solid gray',
													padding: '7px'
												}}
											>
												{selectedService?.name}
											</td>
											<td
												style={{
													border: '1px solid gray',
													padding: '7px'
												}}
											>
												{selectedService?.isFree
													? 'Free'
													: `${selectedService?.price} AED`}
											</td>
											<td
												style={{
													border: '1px solid gray',
													padding: '7px'
												}}
											>
												{selectedService?.coveredByInsurance ? 'Yes' : 'No'}
											</td>
										</tr>
									</tbody>
								</table>
								<div className="mt-4">
									<h5>Description</h5>
									<p>{selectedService?.description}</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</DashboardLayout>
	);
}

export default PatientPromoCode;

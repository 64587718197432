import React, { useEffect, useState } from 'react'
import DashboardLayout from '../../../layout/DashboardLayout'
import { getPromos, deletePromo, setPageNumber } from '../../../store/actions/promosActions'
import { getPagesArray } from '../../../Utills/functions';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { href } from '../../../constants/extra';
import AddPromo from './components/AddPromo';
import ShowVideo from './components/ShowVideo';
import ReactPlayer from 'react-player';
import LIKE_IMAGE from '../../../assets/images/love.png';
import { FadeLoading } from '../../../loaders';
import { useConfirmationDialog } from '../../../contextApi/ConfirmationDialogContext';

function Promos({ promos, getPromos, deletePromo, setPageNumber }) {

   const [selectedPromo, setSelectedPromo] = useState({});
   const { pageNumber, numberOfPages, promos: allPromos } = promos && promos;
   const [loading, setLoading] = useState(true);
	const { showConfirmation } = useConfirmationDialog()

   useEffect(() => {
      getPromoList();
   }, [getPromos, pageNumber]);

   const getPromoList = async () => {
      try {
         await getPromos(pageNumber || 0);
         setLoading(false);
      } catch (err) {
         setLoading(false);
      }
   }

   const deletePromosHandler = (id, url) => {
      showConfirmation('Are you sure you want to delete this item?', () => {
         const videoPublicId = url.split('\\').pop().split('/').pop().split('.')[0];
         deletePromo(id, videoPublicId);
      });
   }

   const pages = getPagesArray(numberOfPages);

   return (
      <DashboardLayout>
         <div class="row align-items-center add-list">
            <div class="col-6">
               <h4>Promo Videos</h4>
            </div>
            <div class="col-6 text-right">
               <button data-toggle="modal" data-target="#addPromo" class="btn btn-primary px-3">+ ADD PROMO VIDEO</button>
            </div>
         </div>
         <div className="row list-block patient-list">
            {loading && allPromos?.length === 0 && (
               <div style={{ width: '100%', marginTop: '10%' }}>
                  <FadeLoading />
               </div>
            )}
            {allPromos?.map(promo => {
               console.log(promo.url);
               return (
                  <div key={promo?._id} className="col-sm-6 col-md-4 col-lg-4 col-xl-2 custom-col">
                     <div className="card">
                        <ReactPlayer
                           url={promo.url}
                           width="239px"
                           height="250px"
                        />
                        <div className="card-body">
                           <div className="media">
                              <div className="media-body">
                                 <ul>
                                    <li>
                                       <h6>Name</h6>
                                       <p>{promo.name}</p>
                                       <p><span style={{ fontSize: "0.9rem", fontWeight: 'bolder' }}>Likes: </span> {promo?.likes} </p>
                                       <p><span style={{ fontSize: "0.9rem", fontWeight: 'bolder' }}>Views:  </span> {promo?.views} </p>
                                       <p><span style={{ fontSize: "0.9rem", fontWeight: 'bolder' }}>Engagements: </span> {promo?.engagements} </p>
                                    </li>
                                 </ul>
                              </div>
                           </div>
                        </div>
                        <div className="dropdown">
                           <a href={href} id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                              <span className="icon-dots"></span>
                           </a>
                           <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                              <a className="dropdown-item" href={href} onClick={() => setSelectedPromo(promo)} data-toggle="modal" data-target="#video-wrapper">Play</a>
                              <a className="dropdown-item delete-item" href={href} onClick={(e) => { e.preventDefault(); deletePromosHandler(promo?._id, promo?.url); }}>Delete</a>
                           </div>
                        </div>
                     </div>
                  </div>
               );
            })}
         </div>
         {!loading && (
                     <div className="row">
                     <div className="col-md-12">
                        {allPromos?.length > 0 ? (
                           <nav>
                              <ul className="pagination justify-content-center align-items-center my-md-2">
                                 <li className="page-item" style={{ pointerEvents: +pageNumber <= 0 && "none" }}><a href={href} onClick={(e) => { e.preventDefault(); setPageNumber(pageNumber - 1) }}>Prev</a></li>
                                 {pages.map((pageIndex) => (
                                    <li className={classNames("page-item", { "active": +pageIndex === pageNumber })} key={pageIndex} onClick={() => setPageNumber(pageIndex)}><a className="page-link" href={href} onClick={(e) => e.preventDefault()}>{pageIndex + 1}</a></li>
                                 ))}
                                 <li className="page-item" style={{ pointerEvents: +pageNumber === +numberOfPages - 1 && "none" }}><a href={href} onClick={(e) => { e.preventDefault(); setPageNumber(pageNumber + 1) }}>Next</a></li>
                              </ul>
                           </nav>
                        ) : (
                           <p>No promo videos found</p>
                        )}
         
                     </div>
                  </div>
         )}
         <AddPromo />
         <ShowVideo selectedPromo={selectedPromo} setSelectedPromo={setSelectedPromo} />
      </DashboardLayout>
   )
}

const mapStateToProps = state => ({
   promos: state.promos
})

const mapDispatchToProps = {
   getPromos,
   deletePromo,
   setPageNumber
}

export default connect(mapStateToProps, mapDispatchToProps)(Promos);
import React, { useContext, useEffect } from 'react';
import { Route, Redirect, useHistory, useLocation } from 'react-router-dom';
import { RootContext } from '../contextApi';
import { redirectByRole } from '../Utills/redirectionRule';

const ProtectedRoute = ({ component: Component, ...rest }) => {
  const { user } = useContext(RootContext); // Access the user from context
  const history = useHistory();
  const location = useLocation(); // Get current location to access the current path

  useEffect(() => {
    if (user) {
      // Pass the current path (location.pathname) to the redirectByRole function
      redirectByRole(user, history, location.pathname); 
    }
  }, [user, history, location.pathname]);

  return (
    <Route
      {...rest}
      render={props =>
        user ? (
          <Component {...props} />
        ) : (
          <Component {...props} />
        )
      }
    />
  );
};

export default ProtectedRoute;

import { toast } from "react-toastify";
import ReceptionistApi from "../../api/Receptionist"
import { ADD_RECEPTIONIST, CLEAR_RECEPTIONIST_SEARCH_RESULTS, DELETE_RECEPTIONIST, GET_RECEPTIONISTS, GET_ALL_RECEPTIONISTS, SEARCH_RECEPTIONIST, SET_RECEPTIONIST_PAGE_NUMBER } from "../types/receptionistTypes";

export const getAllReceptionists = (pageNo, getAll = false) => async (dispatch, getState) => {
    try {
        const response = await ReceptionistApi.getAllReceptionists(pageNo, getAll);

        dispatch({
            type: GET_ALL_RECEPTIONISTS,
            payload: response.data.data
        })
        return response;
    }catch(err) {
        toast.error("Problem while getting receptionists");
    }
}

export const getReceptionists = (pageNo, getAll = false) => async (dispatch, getState) => {
    try {
        const response = await ReceptionistApi.getAllReceptionists(pageNo, getAll);

        dispatch({
            type: GET_RECEPTIONISTS,
            payload: response.data.data
        })
        return response;
    }catch(err) {
        toast.error("Problem while getting receptionists");
    }
}

export const addReceptionist = (data) => async (dispatch, getState) => {
    try {
        const response = await ReceptionistApi.createReceptionist(data);

        dispatch({
            type: ADD_RECEPTIONIST,
            payload: response.data.data
        });
        toast.success("Receptionist Created Successfully");
    }catch(err) {
        toast.error(err.response.data.message);
    }
}

export const deleteReceptionist = (id) => async (dispatch, getState) => {
    try {
        await ReceptionistApi.deleteReceptionist(id);

        dispatch({
            type: DELETE_RECEPTIONIST,
            payload: id
        });
        toast.success("Receptionist deleted successfully");
    }catch(err) {
        toast.error(err.response.data.message);
    }
}

export const searchReceptionist = (pageNo, searchedText) => async (dispatch, getState) => {
    try {
        const response = await ReceptionistApi.searchReceptionists(pageNo, searchedText);
        if(response?.data?.data?.receptionists?.length === 0){
            toast.error("No receptionists found against this search");
        }
        dispatch({
            type: SEARCH_RECEPTIONIST,
            payload: response.data.data
        });
        
        return response;
    }catch(err) {
        toast.error(err.response.data.message);
    }
}

export const setPageNumber = (pageNo) => ({
    type: SET_RECEPTIONIST_PAGE_NUMBER,
    payload: pageNo
})

export const clearSearchResults = () => ({
    type: CLEAR_RECEPTIONIST_SEARCH_RESULTS
})
import React, { useEffect, useState } from "react";
import DashboardLayout from "../../../../layout/DashboardLayout";
import ReceptionistApi from "../../../../api/Receptionist";
import { useParams } from "react-router-dom";
import { href } from "../../../../constants/extra";
import PLACEHOLDER_RECEPTIONIST_IMAGE from "../../../../assets/images/doctor_placeholder.png"; // Replace with a receptionist image if available
import UpdatedReceptionistInfo from "./UpdatedReceptionistInfo";
import { BeatLoader } from "react-spinners";

function ReceptionistInfo() {
  const { id } = useParams();
  const [receptionistDetails, setReceptionistDetails] = useState(null);
  const [infoLoading, setInfoLoading] = useState(true);

  useEffect(() => {
    getReceptionistInfo();
  }, [id]);

  const getReceptionistInfo = async () => {
    ReceptionistApi.getSingleReceptionist(id)
      .then((res) => {
        setReceptionistDetails(res.data.data);
      })
      .finally(() => {
        setInfoLoading(false);
      });
  };

  return (
    <DashboardLayout>
      
          <div className="row align-items-center add-list">
            <div className="col-6">
              <h4 style={{ textTransform: "capitalize" }}>{receptionistDetails?.type} Profile</h4>
            </div>
          </div>
          <div className="row doctor-profile">
            <div className="col-md-12">
              <div className="card profile-detail py-3">
                <div className="card-body">
                  <div className="media">
                    <a
                      href={href}
                      data-toggle="modal"
                      className="edit-doctor-link"
                      data-target="#editReceptionistBasicInfo"
                    >
                      EDIT
                    </a>
                    <img
                      className="avatar-lg mr-0"
                      src={PLACEHOLDER_RECEPTIONIST_IMAGE}
                      alt="receptionist"
                    />
                    <div className="media-body">
                      <h5 className="mt-3 mb-2">
                        {infoLoading ? (
                          <BeatLoader color="#417EBF" size={10} />
                        ) : (
                          `${receptionistDetails?.firstName} ${receptionistDetails?.lastName}`
                        )}
                      </h5>
                      <div className="contact-info">
                        <a href={`mailto:${receptionistDetails?.email}`}>
                          <span className="icon-email"></span>
                        </a>
                        <a href={`tel:${receptionistDetails?.mobile}`}>
                          <span className="icon-phone"></span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <UpdatedReceptionistInfo
            selectedReceptionist={receptionistDetails}
            setReceptionistDetails={setReceptionistDetails}
          />
    </DashboardLayout>
  );
}

export default ReceptionistInfo;

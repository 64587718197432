import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import moment from 'moment';
import DashboardLayout from "../../../../layout/DashboardLayout";
import Table from "../../../../components/Table";
import { FadeLoading } from "../../../../loaders";
import LaboratoryApi from "../../../../api/Laboratory";
import DateFilter from "../../../../components/forms/DateFilter";
import SendResults from "../../../Doctors/DoctorLabResults/components/SendResults";
import { href } from "../../../../constants/extra";

function LabInfo() {
  const { id } = useParams();
  const [labReports, setLabReports] = useState(null);
  const [date, setDate] = useState(moment().format('YYYY-MM-DD'));
  const [loading, setLoading] = useState(true);
  const [selectedLabResult, setSelectedLabResult] = useState({})

  useEffect(() => {
    getLabReports();
  }, [id, date]);

  const getLabReports = async () => {
    try {
      setLoading(true);
      const response = await LaboratoryApi.getLabRequests(id, date);
      setLabReports(response.data.data);
      setLoading(false);
    } catch (e) {
      setLabReports([]);
      setLoading(false);
    }
  };

  const handleDateChange = (e) => {
    setDate(e.target.value);
  };

  return (
    <DashboardLayout>
      <div className="row doctor-profile">
        <div className="col-md-12">
          <div className="card">
            <div className="card-body">
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <h4>Laboratory Reports</h4>
                <DateFilter date={date} onDateChange={setDate} />
              </div>
              <div className="scrollbar" style={{ height: "80vh", marginTop: '2rem' }}>
                <div className="table-responsive">
                  <Table headers={["DOCTOR", "PATIENT NAME", "LABORATORY", "TEST", "DATE", "STATUS", "RESULT"]}>
                    <tbody className='new-table-body'>
                      {!loading && labReports?.map(report => (
                        <tr key={report._id} className='new-table-row'>
                          <td className='new-table-cell'>Dr. {report?.doctorId?.firstName + " " + report?.doctorId?.lastName}</td>
                          <td className='new-table-cell'>{report?.patientId?.firstName + " " + report?.patientId?.lastName}</td>
                          <td className='new-table-cell'>{report?.laboratoryId?.firstName + " " + report?.laboratoryId?.lastName}</td>
                          <td className='new-table-cell'>{report?.tests.map(test => test.test).join(', ')}</td>
                          <td className='new-table-cell'>{`${moment(report.date).format("DD-MM-YY")}`}</td>
                          <td className={report?.status === 'completed' ? 'new-table-cell completed-status' : 'new-table-cell pending-status'}>{report.status}</td>
                          <td className='new-table-cell'>
                            {report.status === 'completed' ? (
                              <a href={href} data-toggle="modal" data-target="#labResults" class="btn btn-primary px-3 py-2" onClick={(e) => { setSelectedLabResult(report) }}>View Results</a>
                            ) : (
                              'N/A'
                            )}
                          </td>
                        </tr>
                      ))}
                      {!loading && labReports?.length === 0 && (
                        <tr className='new-table-row'>
                          No lab reports found
                        </tr>
                      )}
                    </tbody>
                  </Table>
                  {loading && (
                    <div style={{ width: '100%', marginTop: '7%' }}>
                      <FadeLoading />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <SendResults selectedLabResult={selectedLabResult} />
      </div>
    </DashboardLayout>
  );
}

export default LabInfo;

import React, { useContext, useEffect, useState } from 'react';
import DashboardLayout from '../../../layout/DashboardLayout';
import CodesApi from '../../../api/Codes';
import { RootContext } from '../../../contextApi';
import moment from 'moment';
import 'moment-timezone';
import { FadeLoading } from '../../../loaders';
import { href } from '../../../constants/extra';
import RewardsApi from '../../../api/Rewards';
import ReceptionistApi from '../../../api/Receptionist';

function ClaimedOffers() {
	const [hospitalClaimedOffers, setHospitalClaimedOffers] = useState([]);
	const [search, setSearch] = useState('');
	const { user } = useContext(RootContext);
	const [loading, setLoading] = useState(true);
  const [hospitalId, setHospitalId] = useState('');

	const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  useEffect(() => {
    getHospitalId();
  }, [user.referenceId]);

	const getHospitalId = async () => {
    if(user?.referenceId) {
      const res = await ReceptionistApi.getSingleReceptionist(user.referenceId);
      setHospitalId(res.data.data.hospitalId._id);
    }
  }

	useEffect(() => {
		if(hospitalId) {
			getClaimedOffers();
		}
	}, [hospitalId]);

	const getClaimedOffers = async () => {
    RewardsApi.getHospitalRewards(hospitalId).then(res => {
      setHospitalClaimedOffers(res.data.data);
      setLoading(false);
    })
    .catch(err => {
      setLoading(false);
    });
	};

	return (
		<DashboardLayout>
			<div className="row align-items-center add-list">
				<div className="col-12">
					<h4>Claimed Offers</h4>
					<br />
					<div style={{ textAlign: 'center' }}>
						<table
							style={{ border: '1px solid gray', padding: '7px', width: '100%' }}
						>
							<thead style={{ border: '1px solid gray', padding: '7px' }}>
								<tr style={{ border: '1px solid gray', padding: '7px' }}>
									<td
										style={{
											fontSize: '1.2rem',
											border: '1px solid gray',
											padding: '7px',
											fontWeight: 'bold'
										}}
									>
										Patient Name
									</td>
									<td
										style={{
											fontSize: '1.2rem',
											border: '1px solid gray',
											padding: '7px',
											fontWeight: 'bold'
										}}
									>
										Taken Date
									</td>
									<td
										style={{
											fontSize: '1.2rem',
											border: '1px solid gray',
											padding: '7px',
											fontWeight: 'bold'
										}}
									>
										Patient Emirates ID
									</td>
									<td
										style={{
											fontSize: '1.2rem',
											border: '1px solid gray',
											padding: '7px',
											fontWeight: 'bold'
										}}
									>
										Offer Name
									</td>
									<td
										style={{
											fontSize: '1.2rem',
											border: '1px solid gray',
											padding: '7px',
											fontWeight: 'bold'
										}}
									>
										Points
									</td>
									<td
										style={{
											fontSize: '1.2rem',
											border: '1px solid gray',
											padding: '7px',
											fontWeight: 'bold'
										}}
									>
										Promo Code
									</td>
								</tr>
							</thead>
							<tbody style={{ border: '1px solid gray', padding: '7px' }}>
								{hospitalClaimedOffers?.map(code => (
									<tr key={code._id} style={{ border: '1px solid gray', padding: '7px' }}>
										<td style={{ border: '1px solid gray', padding: '7px' }}>
											{code?.patientId?.firstName + ' ' + code?.patientId?.lastName}
										</td>
										<td style={{ border: '1px solid gray', padding: '7px' }}>
											{`${moment.tz(code?.createdAt, timezone).format(
												'DD/MM/YYYY'
											)} - ( ${moment
												.tz(code?.createdAt, timezone)
												.format('hh:mm a')} )`}
										</td>
										<td style={{ border: '1px solid gray', padding: '7px' }}>
											{code?.patientId?.emiratesId}
										</td>
										<td style={{ border: '1px solid gray', padding: '7px' }}>
											{code?.packageId?.title}
										</td>
										<td style={{ border: '1px solid gray', padding: '7px' }}>
											{code?.packageId?.points}
										</td>
										<td
											style={{
												border: '1px solid gray',
												padding: '7px',
											}}
										>
											{code?.packageId?.voucherCode}
										</td>
									</tr>
								))}
							</tbody>
						</table>

						<br />

						{!loading && search === '' && hospitalClaimedOffers.length === 0 && (
							<p>No Promo Codes</p>
						)}

						{loading && (
							<div style={{ width: '100%', marginTop: '10%' }}>
								<FadeLoading />
							</div>
						)}
					</div>
				</div>
			</div>

			{/* Trigger Modal */}
			{/* <a
				href={href}
				id="serviceDetailsModal"
				data-toggle="modal"
				data-target="#serviceModal"
				style={{ display: 'none' }}
			>
				Open Modal
			</a> */}

			{/* Service Details Modal */}
			{/* <div
				className="modal fade"
				id="serviceModal"
				tabIndex="-1"
				aria-labelledby="serviceModalLabel"
				aria-hidden="true"
			>
				<div className="modal-dialog modal-dialog-centered modal-lg">
					<div className="modal-content">
						<div className="modal-body">
							<button
								type="button"
								className="close"
								data-dismiss="modal"
								aria-label="Close"
							>
								<span className="icon-close"></span>
							</button>
							<h4 className="text-center">Service Details</h4>
							<div style={{ textAlign: 'center' }}>
								<table
									style={{
										border: '1px solid gray',
										padding: '7px',
										width: '100%'
									}}
								>
									<thead style={{ border: '1px solid gray', padding: '7px' }}>
										<tr style={{ border: '1px solid gray', padding: '7px' }}>
											<td
												style={{
													border: '1px solid gray',
													padding: '7px',
													fontWeight: 'bold'
												}}
											>
												Service Name
											</td>
											<td
												style={{
													border: '1px solid gray',
													padding: '7px',
													fontWeight: 'bold'
												}}
											>
												Price
											</td>
											<td
												style={{
													border: '1px solid gray',
													padding: '7px',
													fontWeight: 'bold'
												}}
											>
												Covered by Insurance
											</td>
										</tr>
									</thead>
									<tbody style={{ border: '1px solid gray', padding: '7px' }}>
										<tr style={{ border: '1px solid gray', padding: '7px' }}>
											<td
												style={{
													border: '1px solid gray',
													padding: '7px'
												}}
											>
												{selectedService?.name}
											</td>
											<td
												style={{
													border: '1px solid gray',
													padding: '7px'
												}}
											>
												{selectedService?.isFree
													? 'Free'
													: `${selectedService?.price} AED`}
											</td>
											<td
												style={{
													border: '1px solid gray',
													padding: '7px'
												}}
											>
												{selectedService?.coveredByInsurance ? 'Yes' : 'No'}
											</td>
										</tr>
									</tbody>
								</table>
								<div className="mt-4">
									<h5>Description</h5>
									<p>{selectedService?.description}</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div> */}
		</DashboardLayout>
	);
}

export default ClaimedOffers;

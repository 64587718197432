import React, { useState } from 'react'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import { connect } from 'react-redux'
import { useTranslation } from "react-i18next"
import { BeatLoader } from 'react-spinners'
import { updatePatientVitals } from '../../../../store/actions/patientActions'
import PatientApi from '../../../../api/Patients'
import TextInput from '../../../../components/forms/TextInput'
import SelectInput from '../../../../components/forms/SelectInput'

function UpdateProfile({ patient }) {
    const { t } = useTranslation()
    const [loading, setLoading] = useState(false)

    // List of all possible blood groups
    const bloodGroups = [
        "A+",
        "A-",
        "B+",
        "B-",
        "AB+",
        "AB-",
        "O+",
        "O-",
        "Other"
    ];

    return (
        <Formik
            initialValues={{
                bloodType: patient?.bloodType || "",
                allergies: patient?.allergies?.join(","),
                diseases: patient?.diseases?.join(","),
                height: patient?.height || "",
                weight: patient?.weight || ""
            }}
            validationSchema={Yup.object({
                bloodType: Yup.string().required(t('required')),
                allergies: Yup.string().required(t('required')),
                diseases: Yup.string().required(t('required')),
                height: Yup.string().required(t('required')),
                weight: Yup.string().required(t('required'))
            })}
            onSubmit={async (values, { setSubmitting }) => {
                setLoading(true)
                const newPatient = { ...values }
                newPatient.allergies = values.allergies.split(',')
                await PatientApi.updatePatient(patient._id, newPatient)
                    .then(res => {
                        setTimeout(() => {
                            window.location.reload()
                        }, 500)
                    })
            }}
            enableReinitialize={true}
        >
            <div class="modal fade" id="updateVitals" tabindex="-1" aria-labelledby="addPatientLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-body">
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span class="icon-close"></span>
                            </button>
                            <h4 class="text-center">{t("update_vitals")}</h4>
                            <Form>
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group">
																						<SelectInput name="bloodType" style={{ height: "50px" }}>
																							<option value="">{t("select_blood_type")}</option>
																							{bloodGroups.map((group, index) => (
                                                    <option key={index} value={group}>
                                                        {group}
                                                    </option>
                                                ))}
																						</SelectInput>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <TextInput type="text" name="allergies" placeholder={t("allergies")} />
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <TextInput type="text" name="height" placeholder={t("height")} />
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <TextInput type="text" name="weight" placeholder={t("weight")} />
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <TextInput type="text" name="diseases" placeholder={t("diseases")} />
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group text-center mb-0">
                                    <button type="submit" class="btn btn-primary">
                                        {loading ? <BeatLoader size={8} /> : t("confirm")}
                                    </button>
                                </div>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
        </Formik>
    )
}

const mapStateToProps = state => ({})

const mapDispatchToProps = {
    updatePatientVitals
}

export default connect(mapStateToProps, mapDispatchToProps)(UpdateProfile)

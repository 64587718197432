import React, { useRef, useState } from "react";
import { Formik, Form } from "formik";
import TextInput from "../../../../components/forms/TextInput";
import SlotApi from "../../../../api/Slots";
import { FadeLoader } from "react-spinners";

function CalculateAppointments() {
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(''); // Use state to store the total count
  const submitButtonRef = useRef(null); // Create a ref for the submit button

  return (
    <Formik
      initialValues={{
        to: '',
        from: '',
        total: ''
      }}
      onSubmit={async (values) => {
        setLoading(true);
        try {
          const appointments = await SlotApi.getBookedAndApprovedSlots({ to: values.to, from: values.from });
          setTotal(appointments.data.data.totalCount); // Update the total using state
        } catch (error) {
          console.error('Failed to fetch appointments:', error);
          setTotal('Error fetching data');
        }
        setLoading(false);
      }}
      enableReinitialize={true}
    >
      {({ values, handleChange }) => (
        <Form>
          <div className="row">
            <div className="col-md-4">
              <div className="form-group text-center">
                <label style={{ fontWeight: 'bolder' }}>From</label>
                <TextInput
                  type="date"
                  name="from"
                  onChange={(e) => {
                    handleChange(e);
                    if (e.target.value && values.to) {
                      submitButtonRef.current.click(); // Trigger form submission if both dates are set
                    }
                  }}
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group text-center">
                <label style={{ fontWeight: 'bolder' }}>To</label>
                <TextInput
                  type="date"
                  name="to"
                  onChange={(e) => {
                    handleChange(e);
                    if (e.target.value && values.from) {
                      submitButtonRef.current.click(); // Trigger form submission if both dates are set
                    }
                  }}
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group text-center">
                <label style={{ fontWeight: 'bolder' }}>Total</label>
                <input type="text" className="form-control" value={total} readOnly /> {/* Display total from state */}
              </div>
            </div>
          </div>
          <button type="submit" ref={submitButtonRef} style={{ display: 'none' }}>Submit</button>
          {loading && (
            <div className="row" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <FadeLoader color="#417EBF" size={5} />
            </div>
          )}
        </Form>
      )}
    </Formik>
  );
}

export default CalculateAppointments;

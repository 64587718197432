import React, { useEffect, useState, useRef, useContext } from "react";
import { useParams } from "react-router-dom";
import moment from "moment";
import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "react-datepicker/dist/react-datepicker.css";
import { RootContext } from "../../../../contextApi/index";
import SlotApi from "../../../../api/Slots";

const localizer = momentLocalizer(moment);

function SetNurseSchedule() {
  const { id } = useParams();
  const [slots, setSlots] = useState([]);

  useEffect(() => {
    SlotApi.getAllNursesSlots(id).then((res) => { // Use nurse-specific slot API
      if (res.data.data && res.data.data.length > 0) {
        const events = [];
        res.data.data.forEach((slot) => {
          events.push({
            _id: slot._id,
            title: moment(slot.from).format("hh:mm a") + " - " + moment(slot.to).format("hh:mm a"),
            start: slot.from,
            end: slot.to,
            status: slot.status,
          });
        });
        setSlots(events);
      }
    });
  }, [id]);

  const eventStyleGetter = (event, start, end, status) => {
    var backgroundColor = "#" + event.hexColor;
    var style = {
      backgroundColor: backgroundColor,
      borderRadius: "0px",
      fontSize: "14px",
      textAlign: "center",
      opacity: 0.8,
      color: "white",
      border: "0px",
      display: "block",
    };

    if(event.status === "TAKEN") {
      style.backgroundColor = "#339cff";
      style.pointerEvents = 'none';
    }

    if ((event.status === "BOOKED") && moment(event.end).isSameOrBefore()) {
      style.backgroundColor = "#D22B2B";
      style.pointerEvents = "none";
    }

    if (moment(event.end).isAfter() && event.status === "BOOKED") {
      style.backgroundColor = "green";
    }

    return {
      style: style,
    };
  };

  return (
    <>
      <div className="col-md-12 mb-3">
        <div className="col-6 mb-4" style={{ marginLeft: "-15px", fontWeight: "600", fontSize: "22px" }}>
          <h4>Nurse Schedule</h4>
        </div>
        <Calendar
          popup
          localizer={localizer}
          events={slots}
          startAccessor="start"
          views={["month"]}
          endAccessor="end"
          selectable={true}
          style={{ height: 900 }}
          eventPropGetter={(eventStyleGetter)}
        />
      </div>
    </>
  );
}

export default SetNurseSchedule;

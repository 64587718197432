import React, { useContext, useEffect, useState } from 'react';
import DashboardLayout from '../../../layout/DashboardLayout';
import CodesApi from '../../../api/Codes';
import { RootContext } from '../../../contextApi';
import moment from 'moment';
import 'moment-timezone';
import { FadeLoading } from '../../../loaders';
import RewardsApi from '../../../api/Rewards';

function ClaimedOffers() {
	const [hospitalClaimedOffers, setHospitalClaimedOffers] = useState([]);
	const [search, setSearch] = useState('');
	const { user } = useContext(RootContext);
	const [loading, setLoading] = useState(true);

	const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

	useEffect(() => {
		getClaimedOffers();
	}, []);

	const getClaimedOffers = async () => {
    RewardsApi.getAllRewards().then(res => {
      setHospitalClaimedOffers(res.data.data);
      setLoading(false);
    })
    .catch(err => {
      setLoading(false);
    });
	};

	return (
		<DashboardLayout>
			<div className="row align-items-center add-list">
				<div className="col-12">
					<h4>Claimed Offers</h4>
					<br />
					<div style={{ textAlign: 'center' }}>
						<table
							style={{ border: '1px solid gray', padding: '7px', width: '100%' }}
						>
							<thead style={{ border: '1px solid gray', padding: '7px' }}>
								<tr style={{ border: '1px solid gray', padding: '7px' }}>
									<td
										style={{
											fontSize: '1.2rem',
											border: '1px solid gray',
											padding: '7px',
											fontWeight: 'bold'
										}}
									>
										Provider
									</td>
									<td
										style={{
											fontSize: '1.2rem',
											border: '1px solid gray',
											padding: '7px',
											fontWeight: 'bold'
										}}
									>
										Provider Type
									</td>
									<td
										style={{
											fontSize: '1.2rem',
											border: '1px solid gray',
											padding: '7px',
											fontWeight: 'bold'
										}}
									>
										Patient Name
									</td>
									<td
										style={{
											fontSize: '1.2rem',
											border: '1px solid gray',
											padding: '7px',
											fontWeight: 'bold'
										}}
									>
										Taken Date
									</td>
									<td
										style={{
											fontSize: '1.2rem',
											border: '1px solid gray',
											padding: '7px',
											fontWeight: 'bold'
										}}
									>
										Patient Emirates ID
									</td>
									<td
										style={{
											fontSize: '1.2rem',
											border: '1px solid gray',
											padding: '7px',
											fontWeight: 'bold'
										}}
									>
										Offer Name
									</td>
									<td
										style={{
											fontSize: '1.2rem',
											border: '1px solid gray',
											padding: '7px',
											fontWeight: 'bold'
										}}
									>
										Points
									</td>
									<td
										style={{
											fontSize: '1.2rem',
											border: '1px solid gray',
											padding: '7px',
											fontWeight: 'bold'
										}}
									>
										Promo Code
									</td>
								</tr>
							</thead>
							<tbody style={{ border: '1px solid gray', padding: '7px' }}>
								{hospitalClaimedOffers?.map(code => (
									<tr key={code._id} style={{ border: '1px solid gray', padding: '7px' }}>
										<td style={{ border: '1px solid gray', padding: '7px' }}>
											{code?.vendorId ? code?.vendorId?.firstName + " " + code?.vendorId?.lastName : `${code?.hospitalId?.name}`}
										</td>
										<td style={{ border: '1px solid gray', padding: '7px' }}>
											{code?.vendorId ? "Vendor Reward" : "Hospital Reward"}
										</td>
										<td style={{ border: '1px solid gray', padding: '7px' }}>
											{code?.patientId?.firstName + ' ' + code?.patientId?.lastName}
										</td>
										<td style={{ border: '1px solid gray', padding: '7px' }}>
											{`${moment.tz(code?.createdAt, timezone).format(
												'DD/MM/YYYY'
											)} - ( ${moment
												.tz(code?.createdAt, timezone)
												.format('hh:mm a')} )`}
										</td>
										<td style={{ border: '1px solid gray', padding: '7px' }}>
											{code?.patientId?.emiratesId}
										</td>
										<td style={{ border: '1px solid gray', padding: '7px' }}>
											{code?.packageId?.title}
										</td>
										<td style={{ border: '1px solid gray', padding: '7px' }}>
											{code?.packageId?.points}
										</td>
										<td
											style={{
												border: '1px solid gray',
												padding: '7px',
											}}
										>
											{code?.packageId?.voucherCode}
										</td>
									</tr>
								))}
							</tbody>
						</table>

						<br />

						{!loading && search === '' && hospitalClaimedOffers.length === 0 && (
							<p>No Promo Codes</p>
						)}

						{loading && (
							<div style={{ width: '100%', marginTop: '10%' }}>
								<FadeLoading />
							</div>
						)}
					</div>
				</div>
			</div>
		</DashboardLayout>
	);
}

export default ClaimedOffers;

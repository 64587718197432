import React, { useEffect, useState } from "react";
import TABLET_IMG from "../../../assets/images/tablet.png";
import { useParams } from "react-router-dom";
import "./index.css";
import DoctorReportApi from "../../../api/Doctors/DoctorReports";
import { FadeLoading } from "../../../loaders";
import moment from "moment";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

const DoctorReportPDF = () => {
  const [reportData, setReportData] = useState(null);
  const [loading, setLoading] = useState(false);
  const { appointmentId } = useParams();

  useEffect(() => {
    getReportData();
  }, [appointmentId]);

  const getReportData = async () => {
    try {
      setLoading(true);
      const reportData = await DoctorReportApi.getDoctorReportPDF(
        appointmentId
      );
      setReportData(reportData.data.data);
    } catch (err) {
    } finally {
      setLoading(false);
    }
  };

  const downloadPDF = () => {
    const report = document.querySelector(".report-container");

    html2canvas(report, { scale: 2 }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "pt", "a4");
      const imgWidth = 595.28;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      pdf.addImage(imgData, "PNG", 0, 0, imgWidth, imgHeight);
      pdf.save("DoctorReport.pdf");
    });
  };
  
  return (
    <>
      {loading && (
        <div style={{ width: "100%", marginTop: "10%" }}>
          <FadeLoading />
        </div>
      )}
      {!loading && (
        <div className="download-btn-container" onClick={downloadPDF}>
          <span className="fa fa-download add-field"></span>
          <span> Download Now</span>
        </div>
      )}
      {!loading && (
        <div className="container report-container">
          <div className="doctor-report-header">
            <img
              src={TABLET_IMG}
              alt="Hospital Logo"
              className="doctor-report-logo"
            />
            <div className="hospital-details">
              <h1>{reportData?.patientDetail?.hospitalId?.name}</h1>
              <h2>Patient’s Report</h2>
            </div>
          </div>
          <div className="section-content">
            <h2 className="section-title">Patient Detail</h2>
            <table className="doctor-report-table">
              <tbody>
                <tr>
                  <th>Patient's Name:</th>
                  <td>
                    {reportData?.patientDetail?.patientId?.firstName +
                      " " +
                      reportData?.patientDetail?.patientId?.lastName}
                  </td>
                  <th>DOB:</th>
                  <td>{reportData?.patientDetail?.patientId?.birthday}</td>
                </tr>
                <tr>
                  <th>Complaining From:</th>
                  <td colSpan="3">{reportData?.patientDetail?.description}</td>
                </tr>
                <tr>
                  <th>Appointment Date:</th>
                  <td colSpan="3">{moment(reportData?.patientDetail?.from).format("YYYY-MM-DD")}</td>
                </tr>
              </tbody>
            </table>
          </div>

          {reportData?.nurseReports && (
            <div className="section-content">
              <h2 className="section-title">Nurse Diagnosis</h2>
              <table className="doctor-report-table">
                <tbody>
                  <tr>
                    <th>Height:</th>
                    <td>{reportData?.nurseReports?.height}</td>
                    <th>Weight:</th>
                    <td>{reportData?.nurseReports?.weight}kg</td>
                    <th>BP:</th>
                    <td>{reportData?.nurseReports?.bp}</td>
                  </tr>
                  <tr>
                    <th>Heart Rate:</th>
                    <td>{reportData?.nurseReports?.heartRate}</td>
                    <th>Glucose:</th>
                    <td colSpan="3">{reportData?.nurseReports?.glucose}</td>
                  </tr>
                  <tr>
                    <th>Initial Diagnosis:</th>
                    <td colSpan="5">{reportData?.nurseReports?.complain}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          )}

          {reportData?.doctorReports && (
            <div className="section-content">
              <h2 className="section-title">Doctor Diagnosis</h2>
              <table className="doctor-report-table">
                <tbody>
                  <tr>
                    <th>Treatment Type:</th>
                    <td>{reportData?.doctorReports?.treatmentType}</td>
                    <th>Requires Surgery:</th>
                    <td>
                      {reportData?.doctorReports?.requiresSurgery
                        ? "Yes"
                        : "No"}
                    </td>
                    <th>Date of Surgery:</th>
                    <td>{moment(reportData?.doctorReports?.dateOfSurgery).format("YYYY-MM-DD")}</td>
                  </tr>
                  <tr>
                    <th>Medicines:</th>
                    <td colSpan="5">
                      <table className="inner-table">
                        <thead>
                          <tr>
                            <th>Medicine</th>
                            <th>Dosage</th>
                            <th>Days</th>
                          </tr>
                        </thead>
                        <tbody>
                          {reportData?.doctorReports?.medicines?.map(
                            (med, index) => (
                              <tr key={index}>
                                <td>{med.medicine}</td>
                                <td>{med.dosage}</td>
                                <td>{med.days}</td>
                              </tr>
                            )
                          )}
                        </tbody>
                      </table>
                    </td>
                  </tr>
                  <tr>
                    <th>Description:</th>
                    <td colSpan="5">
                      {reportData?.doctorReports?.description}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          )}

          <div className="footer-note">
            <p>This is a computer generated report, No signature required.</p>
          </div>
        </div>
      )}
    </>
  );
};

export default DoctorReportPDF;

import { toast } from "react-toastify";
import DoctorApi from "../../api/Doctors"
import { ADD_DOCTOR, CLEAR_SEARCH_RESULTS, DELETE_DOCTOR, GET_DOCTORS, GET_ALL_DOCTORS, SEARCH_DOCTOR, SELECT_DOCTOR, SET_PAGE_NUMBER, UPDATE_DOCTOR } from "../types/doctorTypes"

export const getAllDoctors = (pageNo, referenceId) => async (dispatch, getState) => {
    try {
        const response = await DoctorApi.getAllDoctors(pageNo, referenceId, true);

        dispatch({
            type: GET_ALL_DOCTORS,
            payload: response.data.data
        })
        return response;
    }catch(err) {
        toast.error("Problem while getting doctors");
    }
}

export const getDoctors = (pageNo, referenceId, getAll = false) => async (dispatch, getState) => {
    try {
        const response = await DoctorApi.getAllDoctors(pageNo, referenceId, getAll);

        dispatch({
            type: GET_DOCTORS,
            payload: response.data.data
        })
        return response;
    }catch(err) {
        toast.error("Problem while getting doctors");
    }
}

export const updateDoctor = (id, data) => async (dispatch, getState) => {
    try {
        const response = await DoctorApi.updateDoctor(id, data);

        dispatch({
            type: UPDATE_DOCTOR,
            payload: response.data.data
        });

        toast.success("Doctor Updated Successfully");
        return response

    } catch (err) {
        toast.error(err.response.data.message);
    }
}

export const addDoctor = (data) => async (dispatch, getState) => {
    try {
        const response = await DoctorApi.createDoctor(data);

        dispatch({
            type: ADD_DOCTOR,
            payload: response.data.data
        });
        toast.success("Doctor Created Successfully");
        return response;
    }catch(err) {
        toast.error(err.response.data.message);
    }
}

export const deleteDoctor = (id) => async (dispatch, getState) => {
    try {
        await DoctorApi.deleteDoctor(id);

        dispatch({
            type: DELETE_DOCTOR,
            payload: id
        });
        toast.success("Doctor deleted successfully");
    }catch(err) {
        toast.error(err.response.data.message);
    }
}

export const searchDoctor = (pageNo, searchedText) => async (dispatch, getState) => {
    try {
        const response = await DoctorApi.searchDoctors(pageNo, searchedText);
        if(response?.data?.data?.doctors?.length === 0){
            toast.error("No doctors found against this search");
        }
        dispatch({
            type: SEARCH_DOCTOR,
            payload: response.data.data
        });
        
        return response;
    }catch(err) {
        toast.error(err.response.data.message);
    }
}

export const setPageNumber = (pageNo) => ({
    type: SET_PAGE_NUMBER,
    payload: pageNo
})

export const clearSearchResults = () => ({
    type: CLEAR_SEARCH_RESULTS
})

export const selectDoctor = (id, newDoctor = null) => async (dispatch, getState) => {
    try {
        if(newDoctor) {
            dispatch({
                type: SELECT_DOCTOR,
                payload: newDoctor
            });
        } else {
            const response = await DoctorApi.getSingleDoctor(id);

            dispatch({
                type: SELECT_DOCTOR,
                payload: response.data.data
            });
        }
    }catch(err) {
        toast.error(err.response.data.message);
    }
}
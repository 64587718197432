import React from 'react'
import DashboardLayout from '../../../layout/DashboardLayout'

function ScanQRPrescription() {
    return (
        <DashboardLayout>
            Scan QR prescription
        </DashboardLayout>
    )
}

export default ScanQRPrescription

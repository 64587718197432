import React from 'react';

const Card = ({ picture, heading, text, onClick }) => {
    
    return (
      <div className='list-card' onClick={onClick}>
        <img src={picture} alt="doctor" />
        <strong><p>{heading}</p></strong>
        <p>{text}</p>
      </div>
    );
  };

  export default Card;
import React, { useEffect, useState } from 'react'
import DashboardLayout from '../../../../layout/DashboardLayout'
import PLACEHOLDER_DOCTOR_IMAGE from '../../../../assets/images/doctor_placeholder.png'
import { href } from '../../../../constants/extra'
import PATIENT_IMAGE from '../../../../assets/images/patient.png'
import SetDoctorSchedule from './SetDoctorSchedule'
import { connect } from 'react-redux'
import AppointmentApi from '../../../../api/Appointment'
import { selectDoctor } from '../../../../store/actions/doctorActions'
import { useParams } from 'react-router-dom'
import classNames from 'classnames'
import moment from 'moment'
import UpdateBasicDoctorInfo from './UpdateBasicDoctorInfo'
import Table from '../../../../components/Table'
import { BeatLoader } from 'react-spinners'
import { FadeLoading } from '../../../../loaders'
import DateFilter from '../../../../components/forms/DateFilter'

function DoctorInfo({ selectedDoctor, selectDoctor }) {

   const { id } = useParams()
   const [appointments, setAppointments] = useState([])
   const [patients, setPatients] = useState([])
   const [tabSelected, setTabSelected] = useState("Profile")
   const [date, setDate] = useState(moment().format('YYYY-MM-DD'));
   const [infoLoading, setInfoLoading] = useState(true);
   const [appointmentLoading, setAppointmentsLoading] = useState(true);
   const [patientsLoading, setPatientsLoading] = useState(true);

   useEffect(() => {
      getDoctorInfo();
      fetchAppointments(date);
  }, [id, selectDoctor, date]);

  useEffect(() => {
   fetchPatients();
  }, [id, selectDoctor]);

  const getDoctorInfo = async () => {
      try {
         await selectDoctor(id);
         setInfoLoading(false);
      } catch (err) {
         setInfoLoading(false);
      }
  }

  const fetchPatients = () => {
   setPatientsLoading(true);
   AppointmentApi.getDoctorAppointments(id).then(res => {
      const uniquePatients = res?.data?.data?.appointments
        ?.filter(app => app.patientId !== null)
        .map(app => app.patientId)
        .filter((patient, index, self) =>
          index === self.findIndex((p) => (
            p.email === patient.email
          ))
        );
  
      setPatients(uniquePatients);
    }).finally(() => {
      setPatientsLoading(false);
    });
  }

  const fetchAppointments = (selectedDate) => {
      setAppointmentsLoading(true);
      AppointmentApi.getDoctorAppointmentsByDate(id, selectedDate).then(res => {
          setAppointments(res.data.data.appointments?.filter(app => app.patientId !== null));
      }).finally(() => {
         setAppointmentsLoading(false);
      });
  };

  const handleDateChange = (e) => {
      setDate(e.target.value);
  };

   return (
      <DashboardLayout>
         <div className="row nav-tab-link">
            <div className="col-md-12">
               <ul className="nav justify-content-center">
                  <li className="nav-item">
                     <a className={classNames('nav-link', { 'active': tabSelected === "Profile" })} href={href} onClick={(e) => { e.preventDefault(); setTabSelected("Profile") }}>Doctor Profile</a>
                  </li>
                  <li className="nav-item">
                     <a className={classNames('nav-link', { 'active': tabSelected === "Schedule" })} href={href} onClick={(e) => { e.preventDefault(); setTabSelected("Schedule") }}>Schedule</a>
                  </li>
               </ul>
            </div>
         </div>

         {tabSelected === "Schedule" ? (
            <SetDoctorSchedule />
         ) : (
            <>
               <div class="row align-items-center add-list">
                  <div class="col-6">
                     <h4>Doctor Profile</h4>
                  </div>
               </div>
               <div class="row doctor-profile">
                  <div class="col-md-4">
                     <div class="card profile-detail py-3">
                        <div class="card-body">
                           <div class="media">
                              <a href={href} data-toggle="modal" className='edit-doctor-link' data-target="#editDoctorBasicInfo" >EDIT</a>
                              <img class="avatar-lg mr-0" src={selectedDoctor?.image ? selectedDoctor?.image : PLACEHOLDER_DOCTOR_IMAGE} alt="doctor" />
                              <div class="media-body">
                                 <h5 class="mt-3 mb-2"> {infoLoading ? <BeatLoader color="#417EBF" size={10} /> : `Dr. ${selectedDoctor?.firstName + " " + selectedDoctor?.lastName}` }</h5>
                                 <p>{selectedDoctor?.specialityId?.map((item, index) => index === selectedDoctor?.specialityId.length - 1 ? item['name_en'] : item['name_en'] + ", ")}</p>
                                 <div class="contact-info">
                                    <a href={`mailto:${selectedDoctor?.email}`}><span className="icon-email"></span></a>
                                    <a href={`tel:${selectedDoctor?.mobile}`}><span className="icon-phone"></span></a>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div class="card patient-detail">
                        <div class="card-body">
                           <h5>Information: </h5>
                           <ul>
                              <li>
                                 <span>Speciality: </span>
                                 <p> {selectedDoctor?.specialityId?.map((item, index) => index === selectedDoctor?.specialityId.length - 1 ? item['name_en'] : item['name_en'] + ", ")}</p>
                              </li>
                              <li>
                                 <span>Experience: </span>
                                 <p> {selectedDoctor?.experience || "-"}</p>
                              </li>
                              <li>
                                 <span>Description: </span>
                                 <p> {selectedDoctor?.about}</p>
                              </li>
                           </ul>
                        </div>
                     </div>
                  </div>
                  <div class="col-md-8">
                     <div class="card">
                        <div class="card-body">
                           <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                              <h4>Doctors Appointments</h4>
                              <DateFilter date={date} onDateChange={setDate} />
                           </div>
                           <div class="scrollbar" style={{ height: "300px", marginTop: '1rem' }}>
                              <div class="table-responsive">
                                 <Table headers={[]}>
                                    <tbody className='new-table-body'>
                                       {!appointmentLoading && appointments.map(appointment => (
                                          <tr key={appointment._id} className='new-table-row'>
                                             <td className='new-table-cell'>{appointment?.patientId?.firstName + " " + appointment?.patientId?.lastName}</td>
                                             <td className='new-table-cell'>{`${moment(appointment.from).format("DD-MM-YY")}`}</td>
                                             <td className='new-table-cell'>{moment(appointment.from).format('hh:mm a')} - {moment(appointment.to).format('hh:mm a')}</td>
                                          </tr>
                                       ))}
                                       {appointments?.length === 0 && (
                                          <p>No appointment found</p>
                                       )}
                                    </tbody>
                                 </Table>
                                 {appointmentLoading && (
                                    <div style={{ width: '100%', marginTop: '7%' }}>
                                       <FadeLoading />
                                    </div>
                                 )}
                              </div>
                           </div>
                        </div>
                     </div>
                     <div class="card">
                        <div class="card-body">
                           <h4>Patient List</h4>
                           <div class="scrollbar" style={{ height: "360px" }}>
                              <div class="table-responsive">
                                 <Table headers={["Name", "Email", "Blood Type", "Birthday", "Gender"]}>
                                    <tbody className='new-table-body'>
                                       {!patientsLoading && patients.filter(patient => patient !== null)?.map(patient => (
                                          <tr key={patient._id} className='new-table-row'>
                                             <td className='new-table-cell'>{patient?.firstName + " " + patient?.lastName}</td>
                                             <td className='new-table-cell'>{patient?.email}</td>
                                             <td className='new-table-cell'>{patient?.bloodType || "-"}</td>
                                             <td className='new-table-cell'>{patient?.birthday}</td>
                                             <td className='new-table-cell'>{patient?.gender}</td>
                                          </tr>
                                       ))}
                                       {patients.length === 0 && (
                                          <p>No appointment found</p>
                                       )}
                                    </tbody>
                                 </Table>
                                 {patientsLoading && (
                                    <div style={{ width: '100%', marginTop: '7%' }}>
                                       <FadeLoading />
                                    </div>
                                 )}
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               <UpdateBasicDoctorInfo selectedDoctor={selectedDoctor} />
            </>
         )}
      </DashboardLayout>
   )
}

const mapStateToProps = state => ({
   selectedDoctor: state.doctors.selectedDoctor
})

const mapDispatchToProps = {
   selectDoctor
}

export default connect(mapStateToProps, mapDispatchToProps)(DoctorInfo)

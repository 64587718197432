import axios from '../../axios';
import { HOSPITAL_SERVICE_NAMESPACE } from '../../constants/namespaces';
import { trackPromise } from 'react-promise-tracker';

const HospitalServiceApi = {
    getHospitalServices(id) {
        return trackPromise(axios.get(`/${HOSPITAL_SERVICE_NAMESPACE}/hospital/${id}`));
    },
    deleteHospitalService(id) {
        return trackPromise(axios.delete(`${HOSPITAL_SERVICE_NAMESPACE}/${id}`));
    },
    getSingleHospitalService(id) {
        return trackPromise(axios.get(`/${HOSPITAL_SERVICE_NAMESPACE}/${id}`));
    },
    createHospitalService(data) {
        return trackPromise(axios.post(`/${HOSPITAL_SERVICE_NAMESPACE}`, data));
    },
    updateHospitalService(id, data) {
        return trackPromise(axios.put(`/${HOSPITAL_SERVICE_NAMESPACE}/${id}`, data));
    },
};

export default HospitalServiceApi;

import React, { useContext, useEffect } from 'react'
import { href } from '../../constants/extra'
import AppLayout from '../../layout/AppLayout'
import { isMobileOnly } from "react-device-detect";
import { RootContext } from '../../contextApi';

function PrivacyPolicy() {
    const { user } = useContext(RootContext);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    
    return (
        <>
            {isMobileOnly || !user ? (
                <>
                    <section class="user-dashboard pb-5" style={{ marginTop: "3rem" }}>
                        <div class="container">
                            <h4 style={{ fontWeight: 'bold' }}>Privacy Policy</h4>
                            <p style={{ marginTop: '-5px' }}>Effective Date: 24/09/2024</p>
                            <hr />
                            <span style={{ color: 'grey' }}>INTRODUCTION</span>
                            <p style={{ marginTop: '3px', fontSize: '0.9rem' }}>
                                Welcome to MEDICAPP. MEDICAPP LLC ("we," "our," or "us") respects your privacy and is committed to protecting your personal data. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use our mobile application, MEDICAPP (the "App"), and any related services (collectively, the "Services"). Please read this policy carefully to understand our practices regarding your personal information.
                                By using the App, you agree to the collection, use, and disclosure of your personal information as outlined in this Privacy Policy. If you do not agree with any part of this policy, you should not use the App.
                            </p>

                            <span style={{ color: 'grey' }}>SCOPE OF THE PRIVACY POLICY</span>
                            <p style={{ marginTop: '3px', fontSize: '0.9rem' }}>
                                This Privacy Policy applies to all users of the App, including but not limited to:
                                <ul>
                                    <li>Patients: Individuals who use the App to book medical appointments, view medical records, and interact with healthcare providers.</li>
                                    <li>Healthcare Providers: Clinics, doctors, nurses, technicians, and other medical professionals who use the App to manage appointments, upload medical information, and communicate with patients.</li>
                                    <li>Other Third Parties: Individuals or entities that access the App for purposes related to the services offered by MEDICAPP LLC.</li>
                                </ul>
                            </p>

                            <span style={{ color: 'grey' }}>INFORMATION WE COLLECT</span>
                            <p style={{ marginTop: '3px', fontSize: '0.9rem' }}>
                                We collect a variety of information from and about users of our App. This information falls into several categories:
                            </p>
                            <ul style={{ fontSize: '0.9rem', marginTop: '3px' }}>
                                <li><strong>Personal Identifiable Information (PII):</strong> This includes, but is not limited to, full name, date of birth, email address, phone number, home or billing address, national identification number, and insurance policy data.</li>
                                <li><strong>Sensitive Personal Information (SPI):</strong> Medical records, appointment details, health conditions, and insurance information.</li>
                                <li><strong>Technical Information:</strong> IP address, device type, operating system, browser type, unique device identifiers, and App usage data.</li>
                                <li><strong>Location Data:</strong> With your consent, we may collect geolocation data to provide location-based services.</li>
                                <li><strong>Cookies and Tracking Technologies:</strong> We may use cookies, pixel tags, web beacons, and similar technologies to store preferences, track App performance, and enhance security.</li>
                            </ul>

                            <span style={{ color: 'grey' }}>HOW WE COLLECT INFORMATION</span>
                            <p style={{ marginTop: '3px', fontSize: '0.9rem' }}>
                                We collect information in several ways, including:
                            </p>
                            <ul style={{ fontSize: '0.9rem', marginTop: '3px' }}>
                                <li><strong>Directly from You:</strong> When you register, book appointments, provide medical information, upload documents, or contact customer support.</li>
                                <li><strong>Automatically:</strong> Technical data, usage patterns, and geolocation data may be collected when you use the App.</li>
                                <li><strong>From Third Parties:</strong> Information from healthcare providers, insurance providers, and third-party integrations you connect to the App.</li>
                            </ul>

                            <span style={{ color: 'grey' }}>HOW WE USE YOUR INFORMATION</span>
                            <p style={{ marginTop: '3px', fontSize: '0.9rem' }}>
                                The information we collect is used for various purposes, including:
                            </p>
                            <ul style={{ fontSize: '0.9rem', marginTop: '3px' }}>
                                <li>To facilitate your appointments with healthcare providers and store your medical records.</li>
                                <li>To communicate with you, such as sending appointment confirmations and reminders.</li>
                                <li>For analytics and research to improve the functionality and user experience of the App.</li>
                                <li>To comply with legal obligations such as data protection laws.</li>
                            </ul>

                            <span style={{ color: 'grey' }}>COOKIES AND TRACKING TECHNOLOGIES</span>
                            <p style={{ marginTop: '3px', fontSize: '0.9rem' }}>
                                We may use cookies, pixel tags, web beacons, and similar technologies to:
                            </p>
                            <ul style={{ fontSize: '0.9rem', marginTop: '3px' }}>
                                <li>Store your preferences, such as login details and language settings.</li>
                                <li>Track how users interact with the App and measure the effectiveness of our features.</li>
                                <li>Enhance security by detecting and preventing fraudulent activity.</li>
                            </ul>
                            <p style={{ marginTop: '3px', fontSize: '0.9rem' }}>
                                You can manage your cookie preferences through your device or browser settings, but disabling cookies may affect certain functionalities of the App.
                            </p>
                            <span style={{ color: 'grey' }}>HOW WE COLLECT INFORMATION</span>
                            <p style={{ marginTop: '3px', fontSize: '0.9rem' }}>
                                We collect information in several ways, including:
                            </p>
                            <ul style={{ fontSize: '0.9rem', marginTop: '3px' }}>
                                <li><strong>Directly from You:</strong> We collect personal information directly from you when:
                                    <ul style={{ marginLeft: '25px', fontSize: '0.9rem' }}>
                                        <li>You register for an account on the App.</li>
                                        <li>You book an appointment through the App.</li>
                                        <li>You voluntarily provide medical information, such as health history or appointment details.</li>
                                        <li>You upload documents or other content to your profile.</li>
                                        <li>You communicate with us through customer support, emails, or other channels.</li>
                                    </ul>
                                </li>
                                <li><strong>Automatically:</strong> We automatically collect certain information when you use the App, including technical data, usage patterns, and geolocation data (if enabled). This helps us provide a better user experience and optimize the performance of the App.</li>
                                <li><strong>From Third Parties:</strong> We may collect information from third parties, such as:
                                    <ul style={{ marginLeft: '25px', fontSize: '0.9rem' }}>
                                        <li>Healthcare Providers: Information related to your appointments, medical history, and prescriptions may be provided by clinics, doctors, or technicians with whom you interact via the App.</li>
                                        <li>Insurance Providers: We may collect information from your insurance provider to verify coverage and facilitate the payment of medical services.</li>
                                        <li>Third-Party Integrations: If you connect your MEDICAPP account to third-party services (e.g., insurance platforms, health monitoring devices), we may collect information from those services in accordance with their privacy policies.</li>
                                    </ul>
                                </li>
                            </ul>

                            <span style={{ color: 'grey' }}>HOW WE USE YOUR INFORMATION</span>
                            <p style={{ marginTop: '3px', fontSize: '0.9rem' }}>
                                We use the information we collect for various purposes, including:
                            </p>
                            <ul style={{ fontSize: '0.9rem', marginTop: '3px' }}>
                                <li><strong>Providing and Improving Our Services:</strong> To facilitate your appointments with healthcare providers, store, retrieve, and display your medical records, and to enable healthcare providers to deliver medical services to you. We also personalize the user experience by showing you relevant healthcare providers and services based on your location, preferences, and health conditions.</li>
                                <li><strong>Communication:</strong> To send you appointment confirmations, reminders, and follow-up communications; notify you of changes to our services or policies; respond to your inquiries and provide customer support; and send you promotional communications, special offers, and other marketing materials (only if you have opted in to receive them).</li>
                                <li><strong>Analytics and Research:</strong> To analyze how you use the App and monitor performance metrics, such as page load times and feature usage. We also use aggregated and anonymized data for statistical analysis and research purposes, ensuring no personally identifiable information is shared in such reports.</li>
                                <li><strong>Compliance with Legal Obligations:</strong> To comply with applicable laws and regulations, including healthcare and data protection laws (e.g., HIPAA, GDPR, CCPA). We also respond to legal requests or orders from government authorities, law enforcement agencies, or other third parties, and detect and prevent fraud, security breaches, and other potentially harmful or illegal activities.</li>
                            </ul>
                            <span style={{ color: 'grey' }}>LEGAL BASES FOR PROCESSING YOUR INFORMATION</span>
        <p style={{ marginTop: '3px', fontSize: '0.9rem' }}>
            We rely on the following legal bases for processing your personal data:
        </p>
        <ul style={{ fontSize: '0.9rem', marginTop: '3px' }}>
            <li><strong>Consent:</strong> In certain cases, we may process your data based on your explicit consent. For example, we will seek your consent before collecting sensitive personal information, such as medical records or geolocation data. You may withdraw your consent at any time, but doing so may limit your ability to use certain features of the App.</li>
            <li><strong>Contractual Necessity:</strong> We process your personal data when it is necessary to fulfill a contract between you and MEDICAPP LLC. This includes processing necessary for booking appointments and delivering healthcare services.</li>
            <li><strong>Legitimate Interests:</strong> We may process your personal information to pursue our legitimate business interests, such as improving the App, conducting analytics, or preventing fraud. In such cases, we carefully balance our interests with your privacy rights.</li>
            <li><strong>Legal Obligations:</strong> We process your personal data to comply with legal obligations, such as health data protection laws and tax regulations.</li>
        </ul>

        <span style={{ color: 'grey' }}>HOW WE SHARE YOUR INFORMATION</span>
        <p style={{ marginTop: '3px', fontSize: '0.9rem' }}>
            We do not sell or rent your personal information. However, we may share your information in the following circumstances:
        </p>
        <ul style={{ fontSize: '0.9rem', marginTop: '3px' }}>
            <li><strong>With Healthcare Providers:</strong> When you book an appointment or interact with healthcare providers via the App, we share relevant personal and medical information with them. This sharing is necessary for the provider to deliver healthcare services to you.</li>
            <li><strong>With Third-Party Service Providers:</strong> We share information with third-party service providers that perform services on our behalf, such as:
                <ul style={{ marginLeft: '25px', fontSize: '0.9rem' }}>
                    <li>Payment processors: To process payments for medical services or premium features.</li>
                    <li>Data storage and hosting providers: To store and safeguard your information securely.</li>
                    <li>Analytics providers: To analyze App usage and performance metrics.</li>
                </ul>
            </li>
            <li><strong>Legal and Regulatory Disclosures:</strong> We may disclose your information to comply with legal obligations, including:
                <ul style={{ marginLeft: '25px', fontSize: '0.9rem' }}>
                    <li>In response to a subpoena, court order, or other legal request.</li>
                    <li>To law enforcement or government authorities if required by law.</li>
                    <li>To protect our legal rights, property, or the safety of MEDICAPP LLC, its users, or others.</li>
                </ul>
            </li>
            <li><strong>Business Transfers:</strong> If MEDICAPP LLC is involved in a merger, acquisition, bankruptcy, or sale of assets, your information may be transferred as part of that transaction. In such an event, we will notify you of any changes to ownership or the control of your personal information.</li>
        </ul>
        <span style={{ color: 'grey' }}>DATA RETENTION AND DELETION</span>
        <p style={{ marginTop: '3px', fontSize: '0.9rem' }}>
            We retain your personal information for as long as it is necessary to provide our services and fulfill the purposes outlined in this Privacy Policy. This includes maintaining business records for audit and legal compliance.
        </p>
        <ul style={{ fontSize: '0.9rem', marginTop: '3px' }}>
            <li><strong>Medical records:</strong> Retained in accordance with healthcare laws such as HIPAA, GDPR, or local regulations, which may mandate retention for a specified period.</li>
            <li><strong>User account data:</strong> Retained for the duration of your use of the App and until your account is deactivated.</li>
            <li><strong>Aggregated and anonymized data:</strong> May be retained indefinitely for research, analytics, or statistical purposes.</li>
        </ul>
        <p style={{ marginTop: '3px', fontSize: '0.9rem' }}>
            When your personal data is no longer needed, we will securely delete or anonymize it. You may also request the deletion of your personal data, subject to certain legal or contractual obligations.
        </p>

        <span style={{ color: 'grey' }}>DATA SECURITY</span>
        <p style={{ marginTop: '3px', fontSize: '0.9rem' }}>
            We implement robust technical and organizational measures to protect your personal information from unauthorized access, misuse, loss, or disclosure. Our security practices include, but are not limited to:
        </p>
        <ul style={{ fontSize: '0.9rem', marginTop: '3px' }}>
            <li><strong>Encryption:</strong> All sensitive data, such as medical records and payment information, is encrypted during transmission and storage.</li>
            <li><strong>Access controls:</strong> Only authorized personnel have access to your data, and they are bound by confidentiality obligations.</li>
            <li><strong>Security audits:</strong> We regularly conduct security audits and vulnerability testing to identify and mitigate risks.</li>
        </ul>
        <p style={{ marginTop: '3px', fontSize: '0.9rem' }}>
            While we strive to protect your personal information, no system is entirely secure. We cannot guarantee the absolute security of your data, and you are responsible for safeguarding your account credentials.
        </p>

        <span style={{ color: 'grey' }}>INTERNATIONAL DATA TRANSFERS</span>
        <p style={{ marginTop: '3px', fontSize: '0.9rem' }}>
            Your information may be transferred to and stored on servers located outside your country, including countries that may not have the same data protection laws as your jurisdiction. When we transfer your data internationally, we ensure that appropriate safeguards are in place, such as:
        </p>
        <ul style={{ fontSize: '0.9rem', marginTop: '3px' }}>
            <li><strong>Standard contractual clauses:</strong> For transfers to countries outside the European Economic Area (EEA), we use EU-approved standard contractual clauses or rely on other approved mechanisms under GDPR.</li>
            <li><strong>Adequacy decisions:</strong> In some cases, transfers will be made to countries deemed to have adequate data protection standards by the European Commission or other relevant authorities.</li>
        </ul>
        <p style={{ marginTop: '3px', fontSize: '0.9rem' }}>
            By using the App, you consent to the transfer of your information to countries outside your country of residence.
        </p>
        <span style={{ color: 'grey' }}>YOUR RIGHTS AND CHOICES</span>
        <p style={{ marginTop: '3px', fontSize: '0.9rem' }}>
            Depending on your location, you may have certain rights regarding your personal data. These rights include:
        </p>
        <ul style={{ fontSize: '0.9rem', marginTop: '3px' }}>
            <li><strong>Right to Access:</strong> You have the right to request a copy of the personal information we hold about you.</li>
            <li><strong>Right to Rectification:</strong> You have the right to correct any inaccurate or incomplete personal information.</li>
            <li><strong>Right to Erasure (Right to be Forgotten):</strong> You may request that we delete your personal information, subject to certain legal exceptions.</li>
            <li><strong>Right to Restrict Processing:</strong> You may request that we restrict the processing of your personal data under certain circumstances.</li>
            <li><strong>Right to Data Portability:</strong> You have the right to receive your personal data in a structured, commonly used, and machine-readable format, and to transmit that data to another service provider where technically feasible.</li>
            <li><strong>Right to Object:</strong> You have the right to object to the processing of your personal data, particularly when the processing is based on legitimate interests or for direct marketing purposes.</li>
            <li><strong>Right to Withdraw Consent:</strong> If we rely on your consent to process your personal data, you may withdraw your consent at any time. Withdrawal of consent does not affect the lawfulness of the data processing that occurred prior to the withdrawal.</li>
        </ul>
        <p style={{ marginTop: '3px', fontSize: '0.9rem' }}>
            To exercise any of these rights, please contact us at <a href="mailto:support@medicappae.com">support@medicappae.com</a>. We may require verification of your identity before processing your request.
        </p>

        <span style={{ color: 'grey' }}>CHILDREN'S PRIVACY</span>
        <p style={{ marginTop: '3px', fontSize: '0.9rem' }}>
            The App is not intended for use by children under the age of 18. We do not knowingly collect personal data from children without the consent of their parents or legal guardians. If you believe that a child under 18 has provided us with personal data without parental consent, please contact us at <a href="mailto:support@medicappae.com">support@medicappae.com</a>, and we will take steps to delete the information.
        </p>

        <span style={{ color: 'grey' }}>THIRD-PARTY LINKS AND INTEGRATIONS</span>
        <p style={{ marginTop: '3px', fontSize: '0.9rem' }}>
            Our App may contain links to third-party websites, services, or applications that are not operated or controlled by MEDICAPP LLC. This Privacy Policy does not apply to those third-party services, and we encourage you to review their privacy policies.
        </p>
        <p style={{ marginTop: '3px', fontSize: '0.9rem' }}>
            Similarly, if you choose to connect your MEDICAPP account with third-party services (such as insurance providers or health tracking apps), the data shared with or received from those third-party services will be governed by their respective privacy policies.
        </p>

        <span style={{ color: 'grey' }}>CHANGES TO THIS PRIVACY POLICY</span>
        <p style={{ marginTop: '3px', fontSize: '0.9rem' }}>
            We may update this Privacy Policy from time to time to reflect changes in our practices or applicable laws. When we make changes, we will update the "Effective Date" at the top of this page and, in some cases, provide additional notice (such as an in-app notification or email).
        </p>
        <p style={{ marginTop: '3px', fontSize: '0.9rem' }}>
            We encourage you to review this Privacy Policy periodically to stay informed about how we protect your personal data.
        </p>

        <span style={{ color: 'grey' }}>CONTACT US</span>
        <p style={{ marginTop: '3px', fontSize: '0.9rem' }}>
            If you have any questions, concerns, or requests regarding this Privacy Policy or the way we handle your personal information, please contact us at:
        </p>
        <p style={{ marginTop: '3px', fontSize: '0.9rem' }}>
            MEDICAPP LLC<br/>
            Email: <a href="mailto:support@medicappae.com">support@medicappae.com</a>
        </p>
                        </div>
                    </section>

                </>
            ) : (
                <AppLayout>
                    <section class="search-block pt-4">
                        <div class="container">
                            <div class="row">
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <nav aria-label="breadcrumb">
                                        <ol class="breadcrumb">
                                            <li class="breadcrumb-item"><a href={href}>Home</a></li>
                                            <li class="breadcrumb-item active" aria-current="page"> Privacy Policy</li>
                                        </ol>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section class="user-dashboard pb-5">
                        <div class="container">
                            <h4 style={{ fontWeight: 'bold' }}>Privacy Policy</h4>
                            <p style={{ marginTop: '-5px' }}>Effective Date: 24/09/2024</p>
                            <hr />
                            <span style={{ color: 'grey' }}>INTRODUCTION</span>
                            <p style={{ marginTop: '3px', fontSize: '0.9rem' }}>
                                Welcome to MEDICAPP. MEDICAPP LLC ("we," "our," or "us") respects your privacy and is committed to protecting your personal data. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use our mobile application, MEDICAPP (the "App"), and any related services (collectively, the "Services"). Please read this policy carefully to understand our practices regarding your personal information.
                                By using the App, you agree to the collection, use, and disclosure of your personal information as outlined in this Privacy Policy. If you do not agree with any part of this policy, you should not use the App.
                            </p>

                            <span style={{ color: 'grey' }}>SCOPE OF THE PRIVACY POLICY</span>
                            <p style={{ marginTop: '3px', fontSize: '0.9rem' }}>
                                This Privacy Policy applies to all users of the App, including but not limited to:
                                <ul>
                                    <li>Patients: Individuals who use the App to book medical appointments, view medical records, and interact with healthcare providers.</li>
                                    <li>Healthcare Providers: Clinics, doctors, nurses, technicians, and other medical professionals who use the App to manage appointments, upload medical information, and communicate with patients.</li>
                                    <li>Other Third Parties: Individuals or entities that access the App for purposes related to the services offered by MEDICAPP LLC.</li>
                                </ul>
                            </p>

                            <span style={{ color: 'grey' }}>INFORMATION WE COLLECT</span>
                            <p style={{ marginTop: '3px', fontSize: '0.9rem' }}>
                                We collect a variety of information from and about users of our App. This information falls into several categories:
                            </p>
                            <ul style={{ fontSize: '0.9rem', marginTop: '3px' }}>
                                <li><strong>Personal Identifiable Information (PII):</strong> This includes, but is not limited to, full name, date of birth, email address, phone number, home or billing address, national identification number, and insurance policy data.</li>
                                <li><strong>Sensitive Personal Information (SPI):</strong> Medical records, appointment details, health conditions, and insurance information.</li>
                                <li><strong>Technical Information:</strong> IP address, device type, operating system, browser type, unique device identifiers, and App usage data.</li>
                                <li><strong>Location Data:</strong> With your consent, we may collect geolocation data to provide location-based services.</li>
                                <li><strong>Cookies and Tracking Technologies:</strong> We may use cookies, pixel tags, web beacons, and similar technologies to store preferences, track App performance, and enhance security.</li>
                            </ul>

                            <span style={{ color: 'grey' }}>HOW WE COLLECT INFORMATION</span>
                            <p style={{ marginTop: '3px', fontSize: '0.9rem' }}>
                                We collect information in several ways, including:
                            </p>
                            <ul style={{ fontSize: '0.9rem', marginTop: '3px' }}>
                                <li><strong>Directly from You:</strong> When you register, book appointments, provide medical information, upload documents, or contact customer support.</li>
                                <li><strong>Automatically:</strong> Technical data, usage patterns, and geolocation data may be collected when you use the App.</li>
                                <li><strong>From Third Parties:</strong> Information from healthcare providers, insurance providers, and third-party integrations you connect to the App.</li>
                            </ul>

                            <span style={{ color: 'grey' }}>HOW WE USE YOUR INFORMATION</span>
                            <p style={{ marginTop: '3px', fontSize: '0.9rem' }}>
                                The information we collect is used for various purposes, including:
                            </p>
                            <ul style={{ fontSize: '0.9rem', marginTop: '3px' }}>
                                <li>To facilitate your appointments with healthcare providers and store your medical records.</li>
                                <li>To communicate with you, such as sending appointment confirmations and reminders.</li>
                                <li>For analytics and research to improve the functionality and user experience of the App.</li>
                                <li>To comply with legal obligations such as data protection laws.</li>
                            </ul>

                            <span style={{ color: 'grey' }}>COOKIES AND TRACKING TECHNOLOGIES</span>
                            <p style={{ marginTop: '3px', fontSize: '0.9rem' }}>
                                We may use cookies, pixel tags, web beacons, and similar technologies to:
                            </p>
                            <ul style={{ fontSize: '0.9rem', marginTop: '3px' }}>
                                <li>Store your preferences, such as login details and language settings.</li>
                                <li>Track how users interact with the App and measure the effectiveness of our features.</li>
                                <li>Enhance security by detecting and preventing fraudulent activity.</li>
                            </ul>
                            <p style={{ marginTop: '3px', fontSize: '0.9rem' }}>
                                You can manage your cookie preferences through your device or browser settings, but disabling cookies may affect certain functionalities of the App.
                            </p>
                            <span style={{ color: 'grey' }}>HOW WE COLLECT INFORMATION</span>
                            <p style={{ marginTop: '3px', fontSize: '0.9rem' }}>
                                We collect information in several ways, including:
                            </p>
                            <ul style={{ fontSize: '0.9rem', marginTop: '3px' }}>
                                <li><strong>Directly from You:</strong> We collect personal information directly from you when:
                                    <ul style={{ marginLeft: '25px', fontSize: '0.9rem' }}>
                                        <li>You register for an account on the App.</li>
                                        <li>You book an appointment through the App.</li>
                                        <li>You voluntarily provide medical information, such as health history or appointment details.</li>
                                        <li>You upload documents or other content to your profile.</li>
                                        <li>You communicate with us through customer support, emails, or other channels.</li>
                                    </ul>
                                </li>
                                <li><strong>Automatically:</strong> We automatically collect certain information when you use the App, including technical data, usage patterns, and geolocation data (if enabled). This helps us provide a better user experience and optimize the performance of the App.</li>
                                <li><strong>From Third Parties:</strong> We may collect information from third parties, such as:
                                    <ul style={{ marginLeft: '25px', fontSize: '0.9rem' }}>
                                        <li>Healthcare Providers: Information related to your appointments, medical history, and prescriptions may be provided by clinics, doctors, or technicians with whom you interact via the App.</li>
                                        <li>Insurance Providers: We may collect information from your insurance provider to verify coverage and facilitate the payment of medical services.</li>
                                        <li>Third-Party Integrations: If you connect your MEDICAPP account to third-party services (e.g., insurance platforms, health monitoring devices), we may collect information from those services in accordance with their privacy policies.</li>
                                    </ul>
                                </li>
                            </ul>

                            <span style={{ color: 'grey' }}>HOW WE USE YOUR INFORMATION</span>
                            <p style={{ marginTop: '3px', fontSize: '0.9rem' }}>
                                We use the information we collect for various purposes, including:
                            </p>
                            <ul style={{ fontSize: '0.9rem', marginTop: '3px' }}>
                                <li><strong>Providing and Improving Our Services:</strong> To facilitate your appointments with healthcare providers, store, retrieve, and display your medical records, and to enable healthcare providers to deliver medical services to you. We also personalize the user experience by showing you relevant healthcare providers and services based on your location, preferences, and health conditions.</li>
                                <li><strong>Communication:</strong> To send you appointment confirmations, reminders, and follow-up communications; notify you of changes to our services or policies; respond to your inquiries and provide customer support; and send you promotional communications, special offers, and other marketing materials (only if you have opted in to receive them).</li>
                                <li><strong>Analytics and Research:</strong> To analyze how you use the App and monitor performance metrics, such as page load times and feature usage. We also use aggregated and anonymized data for statistical analysis and research purposes, ensuring no personally identifiable information is shared in such reports.</li>
                                <li><strong>Compliance with Legal Obligations:</strong> To comply with applicable laws and regulations, including healthcare and data protection laws (e.g., HIPAA, GDPR, CCPA). We also respond to legal requests or orders from government authorities, law enforcement agencies, or other third parties, and detect and prevent fraud, security breaches, and other potentially harmful or illegal activities.</li>
                            </ul>
                            <span style={{ color: 'grey' }}>LEGAL BASES FOR PROCESSING YOUR INFORMATION</span>
        <p style={{ marginTop: '3px', fontSize: '0.9rem' }}>
            We rely on the following legal bases for processing your personal data:
        </p>
        <ul style={{ fontSize: '0.9rem', marginTop: '3px' }}>
            <li><strong>Consent:</strong> In certain cases, we may process your data based on your explicit consent. For example, we will seek your consent before collecting sensitive personal information, such as medical records or geolocation data. You may withdraw your consent at any time, but doing so may limit your ability to use certain features of the App.</li>
            <li><strong>Contractual Necessity:</strong> We process your personal data when it is necessary to fulfill a contract between you and MEDICAPP LLC. This includes processing necessary for booking appointments and delivering healthcare services.</li>
            <li><strong>Legitimate Interests:</strong> We may process your personal information to pursue our legitimate business interests, such as improving the App, conducting analytics, or preventing fraud. In such cases, we carefully balance our interests with your privacy rights.</li>
            <li><strong>Legal Obligations:</strong> We process your personal data to comply with legal obligations, such as health data protection laws and tax regulations.</li>
        </ul>

        <span style={{ color: 'grey' }}>HOW WE SHARE YOUR INFORMATION</span>
        <p style={{ marginTop: '3px', fontSize: '0.9rem' }}>
            We do not sell or rent your personal information. However, we may share your information in the following circumstances:
        </p>
        <ul style={{ fontSize: '0.9rem', marginTop: '3px' }}>
            <li><strong>With Healthcare Providers:</strong> When you book an appointment or interact with healthcare providers via the App, we share relevant personal and medical information with them. This sharing is necessary for the provider to deliver healthcare services to you.</li>
            <li><strong>With Third-Party Service Providers:</strong> We share information with third-party service providers that perform services on our behalf, such as:
                <ul style={{ marginLeft: '25px', fontSize: '0.9rem' }}>
                    <li>Payment processors: To process payments for medical services or premium features.</li>
                    <li>Data storage and hosting providers: To store and safeguard your information securely.</li>
                    <li>Analytics providers: To analyze App usage and performance metrics.</li>
                </ul>
            </li>
            <li><strong>Legal and Regulatory Disclosures:</strong> We may disclose your information to comply with legal obligations, including:
                <ul style={{ marginLeft: '25px', fontSize: '0.9rem' }}>
                    <li>In response to a subpoena, court order, or other legal request.</li>
                    <li>To law enforcement or government authorities if required by law.</li>
                    <li>To protect our legal rights, property, or the safety of MEDICAPP LLC, its users, or others.</li>
                </ul>
            </li>
            <li><strong>Business Transfers:</strong> If MEDICAPP LLC is involved in a merger, acquisition, bankruptcy, or sale of assets, your information may be transferred as part of that transaction. In such an event, we will notify you of any changes to ownership or the control of your personal information.</li>
        </ul>
        <span style={{ color: 'grey' }}>DATA RETENTION AND DELETION</span>
        <p style={{ marginTop: '3px', fontSize: '0.9rem' }}>
            We retain your personal information for as long as it is necessary to provide our services and fulfill the purposes outlined in this Privacy Policy. This includes maintaining business records for audit and legal compliance.
        </p>
        <ul style={{ fontSize: '0.9rem', marginTop: '3px' }}>
            <li><strong>Medical records:</strong> Retained in accordance with healthcare laws such as HIPAA, GDPR, or local regulations, which may mandate retention for a specified period.</li>
            <li><strong>User account data:</strong> Retained for the duration of your use of the App and until your account is deactivated.</li>
            <li><strong>Aggregated and anonymized data:</strong> May be retained indefinitely for research, analytics, or statistical purposes.</li>
        </ul>
        <p style={{ marginTop: '3px', fontSize: '0.9rem' }}>
            When your personal data is no longer needed, we will securely delete or anonymize it. You may also request the deletion of your personal data, subject to certain legal or contractual obligations.
        </p>

        <span style={{ color: 'grey' }}>DATA SECURITY</span>
        <p style={{ marginTop: '3px', fontSize: '0.9rem' }}>
            We implement robust technical and organizational measures to protect your personal information from unauthorized access, misuse, loss, or disclosure. Our security practices include, but are not limited to:
        </p>
        <ul style={{ fontSize: '0.9rem', marginTop: '3px' }}>
            <li><strong>Encryption:</strong> All sensitive data, such as medical records and payment information, is encrypted during transmission and storage.</li>
            <li><strong>Access controls:</strong> Only authorized personnel have access to your data, and they are bound by confidentiality obligations.</li>
            <li><strong>Security audits:</strong> We regularly conduct security audits and vulnerability testing to identify and mitigate risks.</li>
        </ul>
        <p style={{ marginTop: '3px', fontSize: '0.9rem' }}>
            While we strive to protect your personal information, no system is entirely secure. We cannot guarantee the absolute security of your data, and you are responsible for safeguarding your account credentials.
        </p>

        <span style={{ color: 'grey' }}>INTERNATIONAL DATA TRANSFERS</span>
        <p style={{ marginTop: '3px', fontSize: '0.9rem' }}>
            Your information may be transferred to and stored on servers located outside your country, including countries that may not have the same data protection laws as your jurisdiction. When we transfer your data internationally, we ensure that appropriate safeguards are in place, such as:
        </p>
        <ul style={{ fontSize: '0.9rem', marginTop: '3px' }}>
            <li><strong>Standard contractual clauses:</strong> For transfers to countries outside the European Economic Area (EEA), we use EU-approved standard contractual clauses or rely on other approved mechanisms under GDPR.</li>
            <li><strong>Adequacy decisions:</strong> In some cases, transfers will be made to countries deemed to have adequate data protection standards by the European Commission or other relevant authorities.</li>
        </ul>
        <p style={{ marginTop: '3px', fontSize: '0.9rem' }}>
            By using the App, you consent to the transfer of your information to countries outside your country of residence.
        </p>
        <span style={{ color: 'grey' }}>YOUR RIGHTS AND CHOICES</span>
        <p style={{ marginTop: '3px', fontSize: '0.9rem' }}>
            Depending on your location, you may have certain rights regarding your personal data. These rights include:
        </p>
        <ul style={{ fontSize: '0.9rem', marginTop: '3px' }}>
            <li><strong>Right to Access:</strong> You have the right to request a copy of the personal information we hold about you.</li>
            <li><strong>Right to Rectification:</strong> You have the right to correct any inaccurate or incomplete personal information.</li>
            <li><strong>Right to Erasure (Right to be Forgotten):</strong> You may request that we delete your personal information, subject to certain legal exceptions.</li>
            <li><strong>Right to Restrict Processing:</strong> You may request that we restrict the processing of your personal data under certain circumstances.</li>
            <li><strong>Right to Data Portability:</strong> You have the right to receive your personal data in a structured, commonly used, and machine-readable format, and to transmit that data to another service provider where technically feasible.</li>
            <li><strong>Right to Object:</strong> You have the right to object to the processing of your personal data, particularly when the processing is based on legitimate interests or for direct marketing purposes.</li>
            <li><strong>Right to Withdraw Consent:</strong> If we rely on your consent to process your personal data, you may withdraw your consent at any time. Withdrawal of consent does not affect the lawfulness of the data processing that occurred prior to the withdrawal.</li>
        </ul>
        <p style={{ marginTop: '3px', fontSize: '0.9rem' }}>
            To exercise any of these rights, please contact us at <a href="mailto:support@medicappae.com">support@medicappae.com</a>. We may require verification of your identity before processing your request.
        </p>

        <span style={{ color: 'grey' }}>CHILDREN'S PRIVACY</span>
        <p style={{ marginTop: '3px', fontSize: '0.9rem' }}>
            The App is not intended for use by children under the age of 18. We do not knowingly collect personal data from children without the consent of their parents or legal guardians. If you believe that a child under 18 has provided us with personal data without parental consent, please contact us at <a href="mailto:support@medicappae.com">support@medicappae.com</a>, and we will take steps to delete the information.
        </p>

        <span style={{ color: 'grey' }}>THIRD-PARTY LINKS AND INTEGRATIONS</span>
        <p style={{ marginTop: '3px', fontSize: '0.9rem' }}>
            Our App may contain links to third-party websites, services, or applications that are not operated or controlled by MEDICAPP LLC. This Privacy Policy does not apply to those third-party services, and we encourage you to review their privacy policies.
        </p>
        <p style={{ marginTop: '3px', fontSize: '0.9rem' }}>
            Similarly, if you choose to connect your MEDICAPP account with third-party services (such as insurance providers or health tracking apps), the data shared with or received from those third-party services will be governed by their respective privacy policies.
        </p>

        <span style={{ color: 'grey' }}>CHANGES TO THIS PRIVACY POLICY</span>
        <p style={{ marginTop: '3px', fontSize: '0.9rem' }}>
            We may update this Privacy Policy from time to time to reflect changes in our practices or applicable laws. When we make changes, we will update the "Effective Date" at the top of this page and, in some cases, provide additional notice (such as an in-app notification or email).
        </p>
        <p style={{ marginTop: '3px', fontSize: '0.9rem' }}>
            We encourage you to review this Privacy Policy periodically to stay informed about how we protect your personal data.
        </p>

        <span style={{ color: 'grey' }}>CONTACT US</span>
        <p style={{ marginTop: '3px', fontSize: '0.9rem' }}>
            If you have any questions, concerns, or requests regarding this Privacy Policy or the way we handle your personal information, please contact us at:
        </p>
        <p style={{ marginTop: '3px', fontSize: '0.9rem' }}>
            MEDICAPP LLC<br/>
            Email: <a href="mailto:support@medicappae.com">support@medicappae.com</a>
        </p>
                        </div>
                    </section>
                </AppLayout>
            )}
        </>
    )
}

export default PrivacyPolicy

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import AppLayout from '../../../layout/AppLayout';
import GenderFilters from './components/filters/GenderFilters';
import HospitalTypeFilters from './components/filters/HospitalTypeFilters';
import LanguageFilters from './components/filters/LanguageFilters';
import NationalityFilters from './components/filters/NationalityFilters';
import SpecialityFilters from './components/filters/SpecialityFilters';
import SearchedDoctors from './components/SearchedDoctors';
import { searchDoctorsByText, clearDoctorSearch, filterDoctors, specialityFilter, hospitalTypesFilter, nationalityFilter, genderFilter, languageFilter } from '../../../store/actions/patient/searchedDoctorsActions';
import SearchDoctor from './components/filters/SearchDoctor';
import { useTranslation } from "react-i18next";
import { BeatLoader } from "react-spinners"; // Import loading spinner
import { FadeLoading } from '../../../loaders';

function Doctor({ searchDoctorsByText, clearDoctorSearch, searchedDoctors, filterDoctors, specialityFilter, hospitalTypesFilter, nationalityFilter, genderFilter, languageFilter }) {

    const { searchedDoctors: allSearchedDoctors, filters: { checkedSpecialities, hospitalTypes, checkedGenders, checkedLanguages, checkedNationalities } } = searchedDoctors && searchedDoctors;
    const [searchText, setSearchText] = useState("");
    const [loading, setLoading] = useState(true); // Loading state
    const { t } = useTranslation();

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true); // Start loading
            if (localStorage.getItem('doctorSearchText')) {
                // We have searched at the time of clicking the search button on the previous screen
                setSearchText(localStorage.getItem('doctorSearchText'));
                localStorage.removeItem('doctorSearchText');
            } else if (
                checkedSpecialities.length === 0 &&
                checkedGenders.length === 0 &&
                checkedLanguages.length === 0 &&
                checkedNationalities.length === 0 &&
                hospitalTypes.length === 0 &&
                allSearchedDoctors.length === 0
            ) {
                await clearDoctorSearch();
            } else {
                await filterOutDoctors({
                    checkedGenders,
                    checkedLanguages,
                    checkedNationalities,
                    checkedSpecialities,
                    hospitalTypes
                });
            }
            setLoading(false); // End loading
        };

        fetchData();
    }, []);

    const onSpecialityCheckboxChanged = async (spec) => {
        setLoading(true); // Start loading when a filter is changed
        if (checkedSpecialities.filter(item => item === spec._id).length > 0) {
            specialityFilter(checkedSpecialities.filter(item => item !== spec._id));
            await filterOutDoctors({
                checkedSpecialities: checkedSpecialities.filter(item => item !== spec._id),
                hospitalTypes,
                checkedLanguages,
                checkedNationalities,
                checkedGenders
            });
        } else {
            specialityFilter([...checkedSpecialities, spec._id]);
            await filterOutDoctors({
                checkedSpecialities: [...checkedSpecialities, spec._id],
                hospitalTypes,
                checkedLanguages,
                checkedNationalities,
                checkedGenders
            });
        }
        setLoading(false); // End loading
    };

    const onLanguageCheckboxChanged = async (spec) => {
        setLoading(true); // Start loading
        if (checkedLanguages.filter(item => item === spec).length > 0) {
            languageFilter(checkedLanguages.filter(item => item !== spec));
            await filterOutDoctors({
                checkedSpecialities,
                hospitalTypes,
                checkedLanguages: checkedLanguages.filter(item => item !== spec),
                checkedNationalities,
                checkedGenders
            });
        } else {
            languageFilter([...checkedLanguages, spec]);
            await filterOutDoctors({
                checkedSpecialities,
                hospitalTypes,
                checkedLanguages: [...checkedLanguages, spec],
                checkedNationalities,
                checkedGenders
            });
        }
        setLoading(false); // End loading
    };

    const onCountryCheckboxChanged = async (spec) => {
        setLoading(true); // Start loading
        if (checkedNationalities.filter(item => item === spec).length > 0) {
            nationalityFilter(checkedNationalities.filter(item => item !== spec));
            await filterOutDoctors({
                checkedSpecialities,
                hospitalTypes,
                checkedLanguages,
                checkedNationalities: checkedNationalities.filter(item => item !== spec),
                checkedGenders
            });
        } else {
            nationalityFilter([...checkedNationalities, spec]);
            await filterOutDoctors({
                checkedSpecialities,
                hospitalTypes,
                checkedLanguages,
                checkedNationalities: [...checkedNationalities, spec],
                checkedGenders
            });
        }
        setLoading(false); // End loading
    };

    const onGenderCheckboxChanged = async (spec) => {
        setLoading(true); // Start loading
        if (checkedGenders.filter(item => item === spec).length > 0) {
            genderFilter(checkedGenders.filter(item => item !== spec));
            await filterOutDoctors({
                checkedSpecialities,
                hospitalTypes,
                checkedLanguages,
                checkedNationalities,
                checkedGenders: checkedGenders.filter(item => item !== spec)
            });
        } else {
            genderFilter([...checkedGenders, spec]);
            await filterOutDoctors({
                checkedSpecialities,
                hospitalTypes,
                checkedLanguages,
                checkedNationalities,
                checkedGenders: [...checkedGenders, spec]
            });
        }
        setLoading(false); // End loading
    };

    const onHospitalCheckboxChange = async (spec) => {
        setLoading(true); // Start loading
        if (hospitalTypes.filter(item => item === spec).length > 0) {
            hospitalTypesFilter(hospitalTypes.filter(item => item !== spec));
            await filterOutDoctors({
                checkedSpecialities,
                hospitalTypes: hospitalTypes.filter(item => item !== spec),
                checkedLanguages,
                checkedNationalities,
                checkedGenders
            });
        } else {
            hospitalTypesFilter([...hospitalTypes, spec]);
            await filterOutDoctors({
                checkedSpecialities,
                hospitalTypes: [...hospitalTypes, spec],
                checkedLanguages,
                checkedNationalities,
                checkedGenders
            });
        }
        setLoading(false); // End loading
    };

    const filterOutDoctors = async (filters) => {
        setLoading(true); // Start loading before filtering
        await filterDoctors(filters);
        setSearchText("");
        setLoading(false); // End loading after filtering
    };

    const onSearchDoctor = async (e) => {
        e.preventDefault();
        setLoading(true); // Start loading on search
        if (searchText !== "") {
            await searchDoctorsByText(searchText);
        }
        setLoading(false); // End loading after search
    };

    return (
        <AppLayout>

            {/* Search Doctor */}
            <SearchDoctor searchText={searchText} setSearchText={setSearchText} onSearchDoctor={onSearchDoctor} />

            <section className="user-dashboard search-filter-section">
                <div className="container">
                    <div className="row pb-5">
                        <div className="col-md-3 search-filter">
                            <div className="d-flex justify-content-between">
                                <h5 className="mb-2">{t('filters')}</h5>
                                <p className="text-gray mb-2" style={{ cursor: "pointer" }} onClick={() => clearDoctorSearch()}>{t('reset_filters')}</p>
                            </div>
                            <hr className="mt-0" />
                            <form>
                                {/* Hospital Types Filters */}
                                <HospitalTypeFilters onHospitalCheckboxChange={onHospitalCheckboxChange} hospitalTypes={hospitalTypes} />

                                {/* Specialities Filters */}
                                <SpecialityFilters checkedSpecialities={checkedSpecialities} onSpecialityCheckboxChanged={onSpecialityCheckboxChanged} />

                                {/* Language Filters Here */}
                                <LanguageFilters checkedLanguages={checkedLanguages} onLanguageCheckboxChanged={onLanguageCheckboxChanged} />

                                {/* Nationality Filters */}
                                <NationalityFilters checkedNationalities={checkedNationalities} onCountryCheckboxChanged={onCountryCheckboxChanged} />

                                {/* Gender Filters */}
                                <GenderFilters checkedGenders={checkedGenders} onGenderCheckboxChanged={onGenderCheckboxChanged} />
                            </form>
                        </div>
                        <div className="col-md-9">
                            <div className="d-flex justify-content-between align-items-center mb-4 mt-4 mt-md-0">
                                <h5 className="text-primary mb-0">{allSearchedDoctors.length > 0 ? allSearchedDoctors.length : 0} {t('doctors')}</h5>
                            </div>
                            
                            {/* Display loader when loading is true */}
                            {loading ? (
                                <div className="d-flex justify-content-center">
                                    <FadeLoading />
                                </div>
                            ) : (
                                <SearchedDoctors allSearchedDoctors={allSearchedDoctors} />
                            )}
                        </div>
                    </div>
                </div>
            </section>
        </AppLayout>
    );
}

const mapStateToProps = (state) => ({
    searchedDoctors: state.searchedDoctors
});

const mapDispatchToProps = {
    filterDoctors,
    specialityFilter,
    genderFilter,
    hospitalTypesFilter,
    nationalityFilter,
    languageFilter,
    clearDoctorSearch,
    searchDoctorsByText
};

export default connect(mapStateToProps, mapDispatchToProps)(Doctor);

import React, { useEffect, useState } from 'react'
import { href } from '../../../constants/extra'
import DashboardLayout from '../../../layout/DashboardLayout'
import RECEPTIONIST_PLACEHOLDER from '../../../assets/images/nurse_placeholder.png'
import AddReceptionist from './components/AddReceptionist';
import { connect } from 'react-redux';
import { getReceptionists, deleteReceptionist, searchReceptionist, setPageNumber } from '../../../store/actions/receptionistActions';
import { getPagesArray } from '../../../Utills/functions';
import classNames from 'classnames';
import { RECEPTIONIST_INFO_ROUTE } from '../../../constants/Redirects';
import { useHistory } from 'react-router-dom'
import { FadeLoading } from '../../../loaders';
import { useConfirmationDialog } from '../../../contextApi/ConfirmationDialogContext';

function Receptionist({ getReceptionists, deleteReceptionist, receptionists, setPageNumber }) {
    const history = useHistory();
    const { pageNumber, numberOfPages, receptionists: allReceptionists, searchedReceptionists, searchedText } = receptionists && receptionists;
    const [loading, setLoading] = useState(true);
	const { showConfirmation } = useConfirmationDialog()

    useEffect(() => {
        getReceptionistsList();
    }, [getReceptionists, pageNumber, searchReceptionist, searchedText]);

    const getReceptionistsList = async () => {
        if(searchedText !== ""){
            await searchReceptionist(pageNumber, searchedText);
            setLoading(false)
        }else {
            await getReceptionists(pageNumber || 0);
            setLoading(false)
        }
    }

    const deleteReceptionistHandler = (receptionist) => {
        showConfirmation('Are you sure you want to delete this item?', () => {
            deleteReceptionist(receptionist._id);
        });
    }

    const onReceptionistSelect = (id) => {
        history.push(RECEPTIONIST_INFO_ROUTE + `/${id}`)
    }

    const pages = getPagesArray(numberOfPages);

    const receptionistsList = searchedReceptionists.length > 0 ? searchedReceptionists : allReceptionists;

    return (
        <DashboardLayout>
            <div className="row align-items-center add-list">
               <div className="col-6">
                  <h4>Receptionists List</h4>
               </div>
               <div className="col-6 text-right">
                  <a href={href} data-toggle="modal" data-target="#addReceptionist" className="btn btn-primary px-3">+ ADD RECEPTIONIST</a>
               </div>
            </div>
            <div className="row list-block">
                {loading && receptionistsList?.length === 0 && (
                    <div style={{ width: '100%', marginTop: '10%' }}>
                        <FadeLoading />
                    </div>
                )}
                {receptionistsList?.length > 0 && receptionistsList?.map(receptionist => (
                    <div className="col-sm-6 col-md-4 col-lg-4 col-xl-2 custom-col">
                        <div className="card">
                        <div className="card-body">
                            <div className="media">
                                <img style={{ cursor: 'pointer' }} src={receptionist?.image ? receptionist?.image : RECEPTIONIST_PLACEHOLDER} onClick={() => { onReceptionistSelect(receptionist._id) }} alt="doctor" />
                                <div className="media-body">
                                    <h5 className="mt-0">{receptionist.firstName + " " + receptionist.lastName}</h5>
                                    <p>Receptionist</p>
                                </div>
                            </div>
                            <div className="contact-info">
                                <a href={`mailto:${receptionist.email}`}><span className="icon-email"></span></a>
                                <a href={`tel:${receptionist.mobile}`}><span className="icon-phone"></span></a>
                            </div>
                        </div>
                        <div className="dropdown">
                            <a href={href} id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <span className="icon-dots"></span>
                            </a>
                            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                <a className="dropdown-item delete-item" href={href} onClick={(e) => { e.preventDefault(); deleteReceptionistHandler(receptionist) }}>Delete</a>
                            </div>
                        </div>
                        </div>
                    </div>
                ))}
            </div>
            {!loading && (
                        <div className="row">
                        <div className="col-md-12">
                            {receptionistsList?.length > 0 ? (
                                <nav>
                                     <ul class="pagination justify-content-center align-items-center my-md-2">
                                         <li class="page-item" style={{ pointerEvents: +pageNumber <= 0 && "none"  }}><a href={href} onClick={(e) => {e.preventDefault(); setPageNumber(pageNumber - 1)}}>Prev</a></li>
                                             {pages.map((pageIndex) => (
                                                 <li class={classNames("page-item", { "active": +pageIndex === pageNumber })} key={pageIndex} onClick={() => setPageNumber(pageIndex)}><a class="page-link" href={href} onClick={(e) => e.preventDefault()}>{pageIndex + 1}</a></li>
                                             ))}
                                         <li class="page-item" style={{ pointerEvents: +pageNumber === +numberOfPages - 1 && "none"  }}><a href={href} onClick={(e) => {e.preventDefault(); setPageNumber(pageNumber + 1)}}>Next</a></li>
                                     </ul>
                                 </nav>
                            ): (
                                <p>No receptionist found</p>
                            )}
                           
                        </div>
                     </div>
            )}
            {/* Add Receptionist */}
            <AddReceptionist />
        </DashboardLayout>
    )
}

const mapStateToProps = (state) => ({
    receptionists: state.receptionists
});

const mapDispatchToProps = {
    getReceptionists,
    deleteReceptionist,
    setPageNumber
}

export default connect(mapStateToProps, mapDispatchToProps)(Receptionist)

import React, { useContext, useEffect, useState } from 'react'
import DOCTOR_IMAGE from '../../../../assets/images/doctor.png'
import { connect } from 'react-redux'
import { getAllLabs } from '../../../../store/actions/labActions'
import { RootContext } from '../../../../contextApi'
import Card from '../../../../components/Card'
import { FadeLoading } from '../../../../loaders'
import { useHistory } from 'react-router-dom'
import { LAB_INFO_ROUTE } from '../../../../constants/Redirects'

function LabList({ labs, getAllLabs }) {
    const { totalLabs: allLabs } = labs && labs
    const { user } = useContext(RootContext);
    const [loading, setLoading] = useState(true);
    const history = useHistory();

    useEffect(() => {
        getLabsList();
    }, [getAllLabs, user.referenceId])
    
    const getLabsList = async () => {
        try {
            await getAllLabs(0, user.referenceId, true);
            setLoading(false);
        } catch (err) {
            setLoading(false);
        }
    }

    return (
        <div className="horizontal-scroll-container">
                {loading && allLabs?.length === 0 && <FadeLoading />}
                {allLabs?.map(doc => (
                    <Card
                        picture={doc?.image ? doc?.image : DOCTOR_IMAGE}
                        heading={doc.firstName + "  " + doc.lastName}
                        text={doc.mobile}
                        onClick={() => history.push(LAB_INFO_ROUTE + `/${doc._id}`)}
                    />
                ))}
                {!loading && allLabs?.length === 0 && (
                    <div class="col-md-6">
                        <p>No doctors found</p>
                    </div>
                )}
        </div>
    )
}

const mapStateToProps = (state) => ({
    labs: state.labs
})

const mapDispatchToProps = {
    getAllLabs
}

export default connect(mapStateToProps, mapDispatchToProps)(LabList)
import React, { useState, useRef, useEffect, useContext } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { RootContext } from '../../../contextApi/index';
import SlotApi from '../../../api/Slots';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import AppLayout from '../../../layout/AppLayout';
import CreateServiceAppointment from './CreateServiceAppointment';

const localizer = momentLocalizer(moment);

function ServiceBooking() {
    const [slots, setSlots] = useState([]);
    const [selectedSlot, setSelectedSlot] = useState({});
    const buttonRef = useRef();
    const { t } = useTranslation();
    const { user } = useContext(RootContext);

    const provider = JSON.parse(localStorage.getItem('SELECTED_SERVICE_AND_PROVIDER'));
    const isDoctor = provider.role === 'Doctor';

    useEffect(() => {
        const fetchSlots = async () => {
            try {
                let res;
                if (isDoctor) {
                    res = await SlotApi.getAllDoctorsSlots(provider._id);
                } else {
                    res = await SlotApi.getAllNursesSlots(provider._id);
                }

                if (res.data.data && res.data.data.length > 0) {
                    const events = []
                    res.data.data.forEach(slot => {
                        if ((user._id === slot?.patientId?._id && slot.status === "BOOKED") || slot.status === "AVAILABLE") {
                            events.push({
                                title: moment(slot.from).format("hh:mm a") + " - " + moment(slot.to).format("hh:mm a"),
                                start: slot.from,
                                end: slot.to,
                                status: slot.status,
                                _id: slot._id
                            })
                        }
                    })
                    setSlots(events)
                }
            } catch (err) {
                console.error('Error fetching slots', err);
            }
        };

        fetchSlots();
    }, []);

    const eventStyleGetter = (event, start, end, status) => {
        var backgroundColor = '#' + event.hexColor
        var style = {
            backgroundColor: backgroundColor,
            borderRadius: '0px',
            fontSize: '14px',
            textAlign: 'center',
            opacity: 0.8,
            color: 'white',
            border: '0px',
            display: 'block'
        }

        if(event.status === "TAKEN") {
            style.backgroundColor = "#339cff";
            style.pointerEvents = 'none';
        }

        if ((event.status === "BOOKED") && moment(event.end).isSameOrBefore()) {
            style.backgroundColor = "#D22B2B"
            style.pointerEvents = 'none'
        }

        if (moment(event.end).isAfter() && (event.status === "BOOKED")) {
            style.backgroundColor = "green"
        }

        return {
            style: style
        }
    }

    const onSelectEvent = (slot) => {
        if (moment(slot.start).isAfter()) {
            setSelectedSlot(slot);
            buttonRef.current.click();
        }
    };

    return (
        <AppLayout>
            <div className="container">
                <div className="col-6 text-right" style={{ visibility: 'hidden' }}>
                    <a ref={buttonRef} href="#!" data-toggle="modal" data-target="#setServiceAppointment" className="btn btn-primary px-3"></a>
                </div>
                <div className="col-md-12 mb-3">
                    <div className="col-6 mb-4" style={{ marginLeft: '-15px', fontWeight: '600', fontSize: '22px' }}>
                        <h4>
                            <span style={{ fontStyle: 'italic', textDecoration: 'underline' }}>{`${provider.firstName} ${provider.lastName}`}</span> {t('available_slots')}
                        </h4>
                    </div>
                    <Calendar
                        popup
                        localizer={localizer}
                        events={slots}
                        onSelectEvent={onSelectEvent}
                        startAccessor="start"
                        endAccessor="end"
                        views={['month']}
                        selectable={true}
                        style={{ height: 900 }}
                        eventPropGetter={eventStyleGetter}
                    />
                </div>
                <CreateServiceAppointment provider={provider} selectedSlot={selectedSlot} />
            </div>
        </AppLayout>
    );
}

export default ServiceBooking;

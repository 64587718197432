import { ADD_RECEPTIONIST, CLEAR_RECEPTIONIST_SEARCH_RESULTS, GET_ALL_RECEPTIONISTS, DELETE_RECEPTIONIST, GET_RECEPTIONISTS, SEARCH_RECEPTIONIST, SET_RECEPTIONIST_PAGE_NUMBER } from "../types/receptionistTypes";

const initialState = {
    receptionists: [],
    totalReceptionists: [],
    numberOfPages: 0,
    pageNumber: 0,
    searchedText: "",
    searchedReceptionists: []
}

export const receptionistReducer = (state = initialState, action) => {
    switch(action.type) {
        case GET_RECEPTIONISTS: 
            return { 
                ...state,
                receptionists: action.payload.receptionists, 
                numberOfPages: action.payload.totalPages 
            };
        case GET_ALL_RECEPTIONISTS:
            return { 
                ...state,
                totalReceptionists: action.payload.receptionists,
            };
        case ADD_RECEPTIONIST: 
            return {
                ...state, 
                receptionists: [...state.receptionists, action.payload]
            }
        case DELETE_RECEPTIONIST: {
            return {
                ...state, 
                receptionists: state.receptionists.filter(nur => nur._id !== action.payload)
            }
        }
        case SEARCH_RECEPTIONIST: {
            return {
                ...state,
                searchedReceptionists: action.payload.receptionists,
                receptionists: [],
                numberOfPages: action.payload.totalPages || state.numberOfPages,
                searchedText: action.payload.searchedText 
            }
        }
        case SET_RECEPTIONIST_PAGE_NUMBER: {
            return {
                ...state,
                pageNumber: action.payload
            }
        }
        case CLEAR_RECEPTIONIST_SEARCH_RESULTS: {
            return {
                ...state, 
                searchedReceptionists: [],
                searchedText: "",
                pageNumber: 0
            }
        }
        default: 
            return state;
    }
};
import { HOSPITAL, DOCTOR, NURSE, ADMIN, LABORTORY, RECEPTIONIST, PHARMACY, PATIENT, VENDOR } from '../constants/Roles';
import { HOSPITAL_ROUTE, DOCTORS_ROUTE, NURSE_ROUTE, LAB_ROUTE, RECEPTIONIST_ROUTE, PHARMACY_ROUTE, PATIENT_ROUTE, ADMIN_ROUTE, VENDOR_ROUTE } from '../constants/Redirects';

export const redirectByRole = (user, history, currentPath) => {
  if (!user) {
    history.push('/login');
    return;
  }

  switch (user.role) {
    case HOSPITAL:
      if (currentPath.startsWith(HOSPITAL_ROUTE)) {
        history.push(currentPath);  // If the current route is valid for hospital, stay on the same route
      } else {
        history.push(HOSPITAL_ROUTE);  // Else, redirect to default hospital route
      }
      break;

    case DOCTOR:
      if (currentPath.startsWith(DOCTORS_ROUTE)) {
        history.push(currentPath);  // If current route is a valid doctor route
      } else {
        history.push(DOCTORS_ROUTE);  // Redirect to default doctors route
      }
      break;

    case NURSE:
      if (currentPath.startsWith(NURSE_ROUTE)) {
        history.push(currentPath);  // If current route is a valid nurse route
      } else {
        history.push(NURSE_ROUTE);  // Redirect to default nurse route
      }
      break;

    case ADMIN:
      if (currentPath.startsWith(ADMIN_ROUTE)) {
        history.push(currentPath);  // If current route is a valid admin route
      } else {
        history.push(ADMIN_ROUTE);  // Redirect to default admin route
      }
      break;

    case LABORTORY:
      if (currentPath.startsWith(LAB_ROUTE)) {
        history.push(currentPath);  // If current route is a valid laboratory route
      } else {
        history.push(LAB_ROUTE);  // Redirect to default lab route
      }
      break;

    case RECEPTIONIST:
      if (currentPath.startsWith(RECEPTIONIST_ROUTE)) {
        history.push(currentPath);  // If current route is a valid receptionist route
      } else {
        history.push(RECEPTIONIST_ROUTE);  // Redirect to default receptionist route
      }
      break;

    case PHARMACY:
      if (currentPath.startsWith(PHARMACY_ROUTE)) {
        history.push(currentPath);  // If current route is a valid pharmacy route
      } else {
        history.push(PHARMACY_ROUTE);  // Redirect to default pharmacy route
      }
      break;

    case PATIENT:
      if (currentPath.startsWith(PATIENT_ROUTE)) {
        history.push(currentPath);  // If current route is a valid patient route
      } else {
        history.push(PATIENT_ROUTE);  // Redirect to default patient route
      }
      break;

    case VENDOR:
      if (currentPath.startsWith(VENDOR_ROUTE)) {
        history.push(currentPath);  // If current route is a valid vendor route
      } else {
        history.push(VENDOR_ROUTE);  // Redirect to default vendor route
      }
      break;

    default:
      history.push('/login');  // Redirect to login if no valid role is found
  }
};

import React, { useContext, useEffect, useState } from 'react'
import { href } from '../../../constants/extra'
import DashboardLayout from '../../../layout/DashboardLayout'
import PATIENT_IMAGE from '../../../assets/images/doctor_placeholder.png';
import { useHistory } from 'react-router-dom';
import { HOSPITAL_PATIENT_INFO_ROUTE, NURSE_PATIENT_INFO_ROUTE, DOCTOR_PATIENT_INFO_ROUTE } from '../../../constants/Redirects';
import { RootContext } from '../../../contextApi';
import { DOCTOR, HOSPITAL, NURSE } from '../../../constants/Roles';
import { getPatients, deletePatient, setPageNumber } from '../../../store/actions/patientActions';
import { connect } from 'react-redux';
import AddPatient from './components/AddPatient';
import { getPagesArray } from '../../../Utills/functions';
import classNames from 'classnames';
import { FadeLoading } from '../../../loaders';

function Patient({ patients, getPatients, deletePatient, setPageNumber }) {

   const history = useHistory();
   const { user } = useContext(RootContext);
   const [loading, setLoading] = useState(true);

   const { pageNumber, numberOfPages, patients: allPatients } = patients && patients;

   useEffect(() => {
      getPatientData();
   }, [getPatients, pageNumber]);

   const getPatientData = async () => {
      setLoading(true);
      try {
         await getPatients(pageNumber || 0);
         setLoading(false);
      } catch (err) {
         setLoading(false);
      }
   }

   const deletePatientHandler = (id) => {
      deletePatient(id);
   }

   let redirectTo = null;

   switch (user.role) {
      case NURSE: redirectTo = NURSE_PATIENT_INFO_ROUTE; break;
      case HOSPITAL: redirectTo = HOSPITAL_PATIENT_INFO_ROUTE; break;
      case DOCTOR: redirectTo = DOCTOR_PATIENT_INFO_ROUTE; break;
      default: redirectTo = HOSPITAL_PATIENT_INFO_ROUTE;
   }

   const pages = getPagesArray(numberOfPages);

   return (
      <DashboardLayout>
         <div class="row align-items-center add-list">
            <div class="col-6">
               <h4>Patient List</h4>
            </div>
            {/* {user.role === NURSE && (
               <div class="col-6 text-right">
                  <a href="javascript:void(0)" data-toggle="modal" data-target="#addPatient" class="btn btn-primary px-3">+ ADD PATIENT</a>
               </div>
            )} */}
         </div>
         <div className="row list-block">
            {loading && allPatients?.length === 0 && (
               <div style={{ width: '100%', marginTop: '10%' }}>
                  <FadeLoading />
               </div>
            )}
            {allPatients?.map(patient => (
               <div key={patient?._id} className="col-sm-6 col-md-4 col-lg-4 col-xl-2 custom-col">
                  <div className="card ">
                     <div className="card-body">
                        <div className="media">
                           <img src={patient?.image ? patient?.image : PATIENT_IMAGE} style={{ cursor: "pointer" }} className="patient-profile-large" alt="patient" onClick={(e) => { history.push(redirectTo + `/${patient._id}`) }} />
                              <div className="media-body">
                                <h5 className="mt-0">Mr/Mrs. {patient?.firstName + " " + patient?.lastName}</h5>
                                    <p>{patient?.gender}</p>
                                 </div>
                              </div>
                              <div className="contact-info">
                                 <a href={`mailto:${patient?.email}`}><span className="icon-email"></span></a>
                                 <a href={`tel:${patient?.mobile}`}><span className="icon-phone"></span></a>
                              </div>
                     </div>
                  </div>
               </div>
            ))}
         </div>
         <div className="row">
            {!loading && (
               <div className="col-md-12">
                  {allPatients?.length > 0 ? (
                     <nav>
                        <ul className="pagination justify-content-center align-items-center my-md-2">
                           <li className="page-item" style={{ pointerEvents: +pageNumber <= 0 && "none" }}><a href={href} onClick={(e) => { e.preventDefault(); setPageNumber(pageNumber - 1) }}>Prev</a></li>
                              {pages.map((pageIndex) => (
                                 <li className={classNames("page-item", { "active": +pageIndex === pageNumber })} key={pageIndex} onClick={() => setPageNumber(pageIndex)}><a className="page-link" href={href} onClick={(e) => e.preventDefault()}>{pageIndex + 1}</a></li>
                              ))}
                           <li className="page-item" style={{ pointerEvents: +pageNumber === +numberOfPages - 1 && "none" }}><a href={href} onClick={(e) => { e.preventDefault(); setPageNumber(pageNumber + 1) }}>Next</a></li>
                        </ul>
                     </nav>
                  ) : (
                     <p>No patients Found</p>
            )}
         </div>
            )}
         </div>
         {/* Add Patient By Nurse */}
         {user.role === NURSE && (
            <>
               <AddPatient />
            </>
         )}
      </DashboardLayout>
   )
}

const mapStateToProps = state => ({
   patients: state.patients
})

const mapDispatchToProps = {
   getPatients,
   deletePatient,
   setPageNumber
}

export default connect(mapStateToProps, mapDispatchToProps)(Patient);

import React, { useContext, useEffect, useState } from "react";
import "./index.css";
import DashboardLayout from "../../layout/DashboardLayout";
import { useLocation, useParams } from 'react-router-dom';
import PatientInfo from "./components/PatientInfo";
import NurseDiagnosis from "./components/NurseDiagnosis";
import DoctorDiagnosis from "./components/DoctorDiagnosis";
import AppointmentApi from "../../api/Appointment";
import { RootContext } from "../../contextApi";
import { FadeLoading } from "../../loaders";

function Diagnose() {
  const [appointment, setAppointment] = useState(null);
  const location = useLocation();
  const { id: patientId, appointmentId } = useParams();
  const { user } = useContext(RootContext);
  const [loading, setLoading] = useState(true);
  const [previousData, setPreviousData] = useState(null);
  const [previousDataLoading, setPreviousDataLoading] = useState(false);

  useEffect(() => {
    getAppointmentDetails();
  }, [appointmentId]);

  const getPatientPreviousData = async () => {
    if(!previousData) {
      setPreviousDataLoading(true);
      try {
        const res = await AppointmentApi.getPreviousPatientPrescriptions(patientId);      
        setPreviousData(res.data.data);
      } catch (e) {} finally {
        setPreviousDataLoading(false);
      }
    }
  }

  const getAppointmentDetails = async () => {
    setLoading(true);
    const response = await AppointmentApi.getSingleAppointment(appointmentId);
    setAppointment(response.data.data);
    setLoading(false);
  }

  const isDoctor = location.pathname.includes('/doctors/patient-info');
  const isNurse = location.pathname.includes('/nurse/patient-info');

  return (
    <DashboardLayout>
      <div className="diagnosis-flex">
        <PatientInfo 
          appointment={appointment} 
          loading={loading} 
          getPatientPreviousData={getPatientPreviousData} 
          previousData={previousData} 
          previousDataLoading={previousDataLoading}
        />
        <div className="diagnosis-flex-other diagnosis-card">
          <div className="diagnosis-section">
            <NurseDiagnosis isDoctor={isDoctor} appointment={appointment} nurseId={user?.referenceId} />
          </div>
          <DoctorDiagnosis isNurse={isNurse} appointment={appointment} />
        </div>
      </div>
    </DashboardLayout>
  );
}

export default Diagnose;
